import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { FileType, UrlType } from 'sierra-domain/flexible-content/types'

export const fileNames: Record<FileType, TranslationKey> = {
  'slate-card': 'author.slate-card.title',
  'bullet': 'author.bullet.title',
  'live-lobby': 'author.lobby.title',
  'reflections': 'dictionary.reflection',
  'poll': 'dictionary.poll',
  'sliding-scale': 'dictionary.sliding-scale',
  'video': 'dictionary.video',
  'image': 'author.image.title',
  'embed': 'dictionary.embed',
  'general': 'dictionary.page',
  'notepad': 'author.notepad.title',
  'external-notepad': 'author.notepad.title',
  'question-card': 'author.question-card.title',
  'breakout-room': 'author.breakout-rooms.title',
  'sticky-notes': 'author.sticky-notes.title',
  'assessment-card': 'assessment-card.assessment',
  'flip-cards': 'author.slate.flip-cards',
  'homework': 'dictionary.homework-singular',
  'project-card': 'author.project-card.title',
  'drop-a-word': 'dictionary.drop-a-word',
  'stupid-questions': 'question-answers.title',
  'scenario': 'dictionary.scenario',
  'roleplay': 'dictionary.roleplay',
}

export const embedFileNames: Record<UrlType, string> = {
  airtable: 'Airtable',
  figma: 'Figma',
  drive: 'Google Drive',
  loom: 'Loom',
  miro: 'Miro',
  pitch: 'Pitch',
  trello: 'Trello',
  youtube: 'Youtube',
  vimeo: 'Vimeo',
}

export const fileNamesAndDescriptions: Record<
  FileType,
  {
    title: TranslationKey
    description: TranslationKey
  }
> = {
  'slate-card': { title: 'author.slate-card.title', description: 'author.slate-card.description' },
  'bullet': { title: 'author.bullet.title', description: 'author.bullet.description' },
  'live-lobby': { title: 'author.lobby.title', description: 'author.live-lobby.description' },
  'reflections': { title: 'dictionary.reflection', description: 'author.reflections.description' },
  'poll': { title: 'dictionary.poll', description: 'author.poll.description' },
  'sliding-scale': { title: 'dictionary.sliding-scale', description: 'author.sliding-scale.description' },
  'video': { title: 'dictionary.video', description: 'author.video.description' },
  'image': { title: 'author.image.title', description: 'author.image.description' },
  'embed': { title: 'dictionary.embed', description: 'author.embed.description' },
  'general': { title: 'dictionary.page', description: 'author.general.description' },
  'notepad': { title: 'author.notepad.title', description: 'author.notepad.description' },
  'external-notepad': { title: 'author.notepad.title', description: 'author.external-notepad.description' },
  'question-card': { title: 'author.question-card.title', description: 'author.question-card.description' },
  'breakout-room': { title: 'author.breakout-rooms.title', description: 'author.breakout-room.description' },
  'sticky-notes': { title: 'author.sticky-notes.title', description: 'author.sticky-notes.description' },
  'assessment-card': {
    title: 'assessment-card.assessment',
    description: 'author.assessment-card.description',
  },
  'flip-cards': { title: 'author.slate.flip-cards', description: 'author.flip-cards.description' },
  'homework': { title: 'dictionary.homework-singular', description: 'author.homework.description' },
  'project-card': { title: 'author.project-card.title', description: 'author.project-card.description' },
  'drop-a-word': { title: 'dictionary.drop-a-word', description: 'author.drop-a-word.description' },
  'stupid-questions': { title: 'question-answers.title', description: 'author.stupid-questions.description' },
  'scenario': { title: 'dictionary.scenario', description: 'author.scenario.description' },
  'roleplay': { title: 'dictionary.roleplay', description: 'author.roleplay.description' },
}

export const embedFileNamesAndDescriptions: Record<UrlType, { title: string; description: TranslationKey }> =
  {
    airtable: { title: 'Airtable', description: 'author.airtable.description' },
    figma: { title: 'Figma', description: 'author.figma.description' },
    drive: { title: 'Google Drive', description: 'author.drive.description' },
    loom: { title: 'Loom', description: 'author.loom.description' },
    miro: { title: 'Miro', description: 'author.miro.description' },
    pitch: { title: 'Pitch', description: 'author.pitch.description' },
    trello: { title: 'Trello', description: 'author.trello.description' },
    youtube: { title: 'Youtube', description: 'author.youtube.description' },
    vimeo: { title: 'Vimeo', description: 'author.vimeo.description' },
  }
