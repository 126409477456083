import { motion } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDrag } from 'react-dnd'
import { LiveContentAssignmentType } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { getFlag } from 'sierra-client/config/global-config'
import {
  Dropzone,
  GridArea,
  ProgramDraggable,
  ProgramSectionDragOverlay,
  useOutlineReducer,
} from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseTitleAndImage } from 'sierra-client/lib/tabular/components/cells'
import { bareContentId } from 'sierra-client/views/manage/content/utils/content-utils'
import { useOutlineEditPanels } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit-panels'
import { useScrollHighlight } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-scroll-highlight'
import * as Common from 'sierra-client/views/manage/programs/staggered-assignments/renderer/common'
import { DropdownPopover } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/common'
import {
  ProgramStepDragItem,
  ProgramStepDragOverlay,
} from 'sierra-client/views/manage/programs/staggered-assignments/renderer/drag-n-drop'
import { StepRenderer } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import {
  RELATIVE_DUE_DATE_DEFAULT,
  SCHEDULE_OFFSET_DEFAULT,
  createDueDateAbsolute,
  createDueDateRelative,
  createScheduleOffset,
  createTimeScheduleAbsolute,
  createTimeScheduleNow,
  createTimeScheduleOnPreviousComplete,
  createTimeScheduleRelative,
  getCurrentDateString,
  getLiveContentAssignmentStateFromStep,
  getLiveContentAssignmentTranslation,
  getStepId,
  getStepTypeTranslation,
  isContentProgramStep,
  isStepLiveCourseOrPath,
  renderScheduleDescriptionWithPreviousCompletion,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ContentProgramStep, ProgramStep, Schedule as ScheduleType } from 'sierra-domain/api/manage'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { InputDatePicker, NumberInput } from 'sierra-ui/components'
import { PseudoOptionVertical } from 'sierra-ui/missions/workflows/panels/form-elements/pseudo-option-vertical'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const DraggableStepContainer = styled(motion.div)<{ $isDragging: boolean }>`
  position: relative;
  margin-bottom: 2px;

  ${p =>
    p.$isDragging &&
    css`
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        background: ${token('surface/soft')};
        border-radius: 16px;
      }
    `}
`

const StepGrid = styled(View).attrs({
  paddingTop: Common.stepSpacing.paddingTop,
  paddingBottom: Common.stepSpacing.paddingTop,
  paddingLeft: Common.stepSpacing.paddingLeft,
  paddingRight: Common.stepSpacing.paddingRight,
  gap: 'none',
})<{ $isHeader: boolean }>`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: min-content 250px 1fr 1fr min-content;
  grid-template-areas: 'number step assign due actions';
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  column-gap: 8px;

  ${p =>
    p.$isHeader &&
    css`
      margin-inline: 0px;
      padding-block: 0px;
      padding-inline: 16px;
      grid-template-columns: 300px 1fr 1fr;
      grid-template-areas: 'step assign due .';
    `}
`

const Header: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <GridArea area='step'>
        <Text bold>{t('dictionary.step')}</Text>
      </GridArea>

      <GridArea area='assign'>
        <Text bold>{t('manage.program.program-details.outline.available')}</Text>
      </GridArea>

      <GridArea area='due'>
        <Text bold>{t('dictionary.due')}</Text>
      </GridArea>
    </>
  )
}

const Schedule: React.FC<{
  step: ProgramStep
  isFirstStep: boolean
  isPreviousEmail: boolean
}> = ({ step, isFirstStep, isPreviousEmail }) => {
  const { index: stepIndex, schedule } = step
  const { outline, dispatch } = useOutlineReducer()
  const { t } = useTranslation()
  const { reason } = useScrollHighlight({ stepId: getStepId(step) })

  const previousPresentationalStepIndex = outline.steps.reduce((num, _, j) => {
    const previous = outline.steps[j - 1]
    if (previous?.type !== 'email' && j <= stepIndex) {
      return num + 1
    } else {
      return num
    }
  }, -1)

  const afterCompletionOptionEnabled = getFlag('program-after-previous-completion')

  const label = useMemo((): string => {
    if (afterCompletionOptionEnabled) {
      return renderScheduleDescriptionWithPreviousCompletion({ outline, index: stepIndex, t })
    } else {
      switch (schedule.type) {
        case 'now':
          if (isFirstStep) {
            return t('manage.program.program-details.steps.schedule-item-directly')
          }
          return t('manage.program.program-details.steps.schedule-item-with-previous')
        case 'relative':
          return t('manage.program.program-details.steps.schedule-item-delayed-x-days', {
            count: schedule.offset.value,
          })
        default:
          return 'TBD'
      }
    }
  }, [afterCompletionOptionEnabled, stepIndex, isFirstStep, schedule, t, outline])

  const onScheduleChange = useCallback(
    (scheduleType: ScheduleType['type']) => {
      const schedule = iife((): ScheduleType => {
        switch (scheduleType) {
          case 'now':
            return createTimeScheduleNow()
          case 'relative':
            return createTimeScheduleRelative()
          case 'absolute':
            return createTimeScheduleAbsolute()
          case 'on-previous-steps-completion':
            return createTimeScheduleOnPreviousComplete()
          default:
            assertNever(scheduleType)
        }
      })

      dispatch({ type: 'UPDATE_SCHEDULE', stepIndex, schedule })
    },
    [stepIndex, dispatch]
  )

  const onScheduleOffsetChange = (scheduleType: ScheduleType['type']) => (days: number | null) => {
    const offset = createScheduleOffset(days ?? 0)

    const schedule = iife(() => {
      switch (scheduleType) {
        case 'now':
          return createTimeScheduleNow()
        case 'relative':
          return createTimeScheduleRelative(offset)
        case 'on-previous-steps-completion':
          return createTimeScheduleOnPreviousComplete(offset)
        case 'absolute':
          return createTimeScheduleRelative(offset)
        case null:
          return null
        default: {
          assertNever(scheduleType)
        }
      }
    })

    if (schedule !== null) {
      dispatch({ type: 'UPDATE_SCHEDULE', stepIndex, schedule })
    }
  }

  const onScheduleAbsoluteChange = useCallback(
    (date: string) => {
      const schedule = createTimeScheduleAbsolute(date)
      dispatch({ type: 'UPDATE_SCHEDULE', stepIndex, schedule })
    },
    [stepIndex, dispatch]
  )

  if (afterCompletionOptionEnabled) {
    const showCompletionOption = stepIndex > 0

    const relativeDelay =
      schedule.type === 'relative'
        ? schedule.offset.value
        : schedule.type === 'now'
          ? null
          : SCHEDULE_OFFSET_DEFAULT
    const hasRelativeDelay = relativeDelay !== null
    const completionDelay =
      schedule.type === 'on-previous-steps-completion'
        ? schedule.offset?.value === undefined
          ? null
          : schedule.offset.value
        : SCHEDULE_OFFSET_DEFAULT
    const hasCompletionDelay = completionDelay !== null

    return (
      <Common.DropdownPopover initialOpen={reason === 'change-assignment'} label={label}>
        {showCompletionOption && (
          <PseudoOptionVertical
            id='assignment-previous-completion'
            checked={schedule.type === 'on-previous-steps-completion'}
            label={
              isPreviousEmail
                ? t('manage.programs.scheduling.option.after-completion-email')
                : t('manage.programs.scheduling.option.after-completion', {
                    step: previousPresentationalStepIndex,
                  })
            }
            onChange={selected => {
              if (selected) {
                onScheduleChange('on-previous-steps-completion')
              }
            }}
          >
            <NumberInput
              value={completionDelay}
              onChange={onScheduleOffsetChange('on-previous-steps-completion')}
              unit={hasCompletionDelay ? t('dictionary.n-days-only', { count: 2 }) : undefined}
              min={0}
              max={365}
              placeholder={t('dictionary.no-delay')}
              disabled={schedule.type !== 'on-previous-steps-completion' ? true : undefined}
            />
          </PseudoOptionVertical>
        )}

        <PseudoOptionVertical
          id='assignment-relative'
          checked={schedule.type === 'relative' || schedule.type === 'now'}
          label={
            isFirstStep
              ? t('manage.programs.scheduling.option.on-program-start')
              : isPreviousEmail
                ? t('manage.programs.scheduling.option.with-step-email')
                : t('manage.programs.scheduling.option.with-step', { step: previousPresentationalStepIndex })
          }
          onChange={selected => {
            if (selected) {
              onScheduleChange('relative')
            }
          }}
        >
          <NumberInput
            value={relativeDelay}
            onChange={onScheduleOffsetChange('relative')}
            unit={hasRelativeDelay ? t('dictionary.n-days-only', { count: 2 }) : undefined}
            min={0}
            max={365}
            placeholder={t('dictionary.no-delay')}
            disabled={schedule.type !== 'relative' && schedule.type !== 'now' ? true : undefined}
          />
        </PseudoOptionVertical>

        <PseudoOptionVertical
          id='assignment-absolute'
          checked={schedule.type === 'absolute'}
          label={t('manage.programs.scheduling.option.fixed-date')}
          onChange={selected => {
            if (selected) {
              onScheduleChange('absolute')
            }
          }}
        >
          <InputDatePicker
            autoFocus
            value={schedule.type === 'absolute' ? DateTime.fromISO(schedule.date) : undefined}
            disablePastDates
            onChange={date => {
              if (date !== undefined) {
                const formatted = date.toFormat('yyyy-MM-dd')
                onScheduleAbsoluteChange(formatted)
              }
            }}
          />
        </PseudoOptionVertical>
      </Common.DropdownPopover>
    )
  }

  return (
    <Common.DropdownPopover initialOpen={reason === 'change-assignment'} label={label}>
      <PseudoOptionVertical
        id='assignment-now'
        checked={schedule.type === 'now'}
        label={t('manage.program.program-details.steps.schedule-item-directly')}
        subline={
          stepIndex === 0
            ? t('manage.program.program-details.steps.schedule-item-directly-description-first-step')
            : t('manage.program.program-details.steps.schedule-item-directly-description-other-step')
        }
        onChange={selected => {
          if (selected) {
            onScheduleChange('now')
          }
        }}
      />
      <PseudoOptionVertical
        id='assignment-delayed'
        checked={schedule.type === 'relative'}
        label={t('manage.program.program-details.steps.schedule-item-delayed')}
        onChange={selected => {
          if (selected) {
            onScheduleChange('relative')
          }
        }}
        subline={
          stepIndex === 0
            ? t('manage.program.program-details.steps.schedule-item-delayed-description-first-step')
            : t('manage.program.program-details.steps.schedule-item-delayed-description-other-step')
        }
      >
        <NumberInput
          autoFocus
          value={schedule.type === 'relative' ? schedule.offset.value : SCHEDULE_OFFSET_DEFAULT}
          onChange={onScheduleOffsetChange('relative')}
          unit={'days'}
          min={0}
          max={365}
          disabled={schedule.type !== 'relative' ? true : undefined}
        />
      </PseudoOptionVertical>
    </Common.DropdownPopover>
  )
}

type LiveSessionAssignmentSelectorProps = {
  step: ContentProgramStep
}

const LiveSessionAssignmentSelector: React.FC<LiveSessionAssignmentSelectorProps> = ({ step }) => {
  const isHidden = !isStepLiveCourseOrPath(step)
  const { t } = useTranslation()
  const { dispatch } = useOutlineReducer()
  const { reason } = useScrollHighlight({ stepId: getStepId(step) })

  const assignmentState: LiveContentAssignmentType = getLiveContentAssignmentStateFromStep(step)

  const onOptionClick = useCallback(
    (assignment: LiveContentAssignmentType) => {
      return dispatch({ type: 'UPDATE_LIVE_SESSION_ASSIGNMENT', stepIndex: step.index, assignment })
    },
    [step.index, dispatch]
  )

  if (isHidden) {
    return <Common.Empty>{t('dictionary.empty')}</Common.Empty>
  }

  return (
    <View direction='row' alignItems='center' justifyContent='flex-start' gap='2'>
      <Common.LiveSessionAssignmentIcon />
      <DropdownPopover
        initialOpen={reason === 'live-session'}
        minWidth={110}
        label={getLiveContentAssignmentTranslation(assignmentState, t)}
      >
        <PseudoOptionVertical
          id='session-assignment-auto'
          label={t('manage.program.program-details.steps.live.auto-assign-title')}
          subline={t('manage.program.program-details.steps.live.auto-assign-description')}
          checked={assignmentState === 'auto-assign'}
          onChange={selected => {
            if (selected) {
              onOptionClick('auto-assign')
            }
          }}
        />
        <PseudoOptionVertical
          id='session-assignment-enroll'
          label={t('manage.program.program-details.steps.live.self-enroll-title')}
          subline={t('manage.program.program-details.steps.live.self-enroll-description')}
          checked={assignmentState === 'self-enroll'}
          onChange={selected => {
            if (selected) {
              onOptionClick('self-enroll')
            }
          }}
        />
        <PseudoOptionVertical
          id='session-assignment-manual'
          label={t('manage.program.program-details.steps.live.manual-title')}
          subline={t('manage.program.program-details.steps.live.manual-description')}
          checked={assignmentState === 'manual'}
          onChange={selected => {
            if (selected) {
              onOptionClick('manual')
            }
          }}
        />
      </DropdownPopover>
    </View>
  )
}

type DueDateRelativeProps = {
  days: number
  disabled: boolean
  onChange: (num: number | null) => void
}

const DueDateRelative: React.FC<DueDateRelativeProps> = ({ days, disabled, onChange }) => {
  const { t } = useTranslation()
  return (
    <NumberInput
      disabled={disabled}
      value={days}
      onChange={onChange}
      unit={t('dictionary.n-days-only', { count: days })}
      min={0}
      max={365}
    />
  )
}

const DueDate: React.FC<{ step: ContentProgramStep }> = ({ step }) => {
  const { dueDate, index: stepIndex } = step
  const { dispatch } = useOutlineReducer()
  const { t } = useTranslation()
  const { reason } = useScrollHighlight({ stepId: getStepId(step) })

  const label = useMemo(() => {
    if (dueDate === undefined) {
      return t('manage.program.program-details.steps.due-date.no-due-date')
    }

    switch (dueDate.type) {
      case 'relative':
        return `${dueDate.days} ${t('manage.program.program-details.steps.n-days-after-assignment', {
          count: dueDate.days,
        })}`
      case 'absolute':
        return dueDate.date
    }
  }, [dueDate, t])

  const onRelativeDueDateChange = useCallback(
    (days: number | null) => {
      if (days === null) {
        return
      }

      const relativeDueDate = createDueDateRelative(days)

      dispatch({ type: 'UPDATE_DUE_DATE', stepIndex, dueDate: relativeDueDate })
    },
    [stepIndex, dispatch]
  )

  const removeDueDate = useCallback(
    () => dispatch({ type: 'UPDATE_DUE_DATE', stepIndex, dueDate: undefined }),
    [dispatch, stepIndex]
  )

  return (
    <Common.TwoLineCell>
      <Common.DropdownPopover initialOpen={reason === 'due-date'} empty={dueDate === undefined} label={label}>
        <PseudoOptionVertical
          id='duedate-none'
          label={t('manage.program.program-details.steps.due-date.no-due-date')}
          checked={dueDate === undefined}
          onChange={selected => {
            if (selected) {
              removeDueDate()
            }
          }}
        />
        <PseudoOptionVertical
          id='duedate-relative'
          label={t('manage.program.program-details.steps.due-date.relative')}
          checked={dueDate?.type === 'relative'}
          onChange={selected => {
            if (selected) {
              dispatch({ type: 'UPDATE_DUE_DATE', stepIndex, dueDate: createDueDateRelative() })
            }
          }}
        >
          <DueDateRelative
            days={dueDate?.type === 'relative' ? dueDate.days : RELATIVE_DUE_DATE_DEFAULT}
            disabled={dueDate?.type !== 'relative'}
            onChange={onRelativeDueDateChange}
          />
        </PseudoOptionVertical>
        <PseudoOptionVertical
          id='duedate-aboslute'
          label={t('manage.program.program-details.steps.due-date.absolute')}
          checked={dueDate?.type === 'absolute'}
          onChange={selected => {
            if (selected) {
              dispatch({ type: 'UPDATE_DUE_DATE', stepIndex, dueDate: createDueDateAbsolute() })
            }
          }}
        >
          <InputDatePicker
            autoFocus
            value={DateTime.fromISO(dueDate?.type === 'absolute' ? dueDate.date : getCurrentDateString())}
            disablePastDates
            disabled={dueDate?.type !== 'absolute'}
            onChange={date => {
              if (date !== undefined) {
                const formatted = date.toFormat('yyyy-MM-dd')
                dispatch({
                  type: 'UPDATE_DUE_DATE',
                  stepIndex,
                  dueDate: { type: 'absolute', date: formatted },
                })
              }
            }}
          />
        </PseudoOptionVertical>
      </Common.DropdownPopover>
      <Common.Empty>{t('dictionary.empty')}</Common.Empty>
    </Common.TwoLineCell>
  )
}

const Actions: React.FC<{ step: ProgramStep }> = ({ step }) => {
  const { index: stepIndex } = step
  const { dispatch } = useOutlineReducer()
  const { t } = useTranslation()
  const { reason, updateReason } = useScrollHighlight({ stepId: getStepId(step) })

  const deleteStep = useCallback(() => dispatch({ type: 'REMOVE_STEP', stepIndex }), [dispatch, stepIndex])

  const removeDeleteReason = useCallback(() => {
    if (reason === 'delete') {
      updateReason(undefined)
    }
  }, [reason, updateReason])

  return (
    <GridArea area='actions'>
      <View direction='row' justifyContent='flex-end' gap='8' onMouseEnter={removeDeleteReason}>
        <Common.DeleteIconButton
          tooltip={t('manage.program.program-details.steps.delete-step-button-tooltip')}
          onClick={deleteStep}
        />
      </View>
    </GridArea>
  )
}

const StepContent: React.FC<{
  step: ProgramStep
  isFirstStep: boolean
  stepNumber?: React.ReactNode
  isPreviousEmail: boolean
  assetContext: AssetContext
}> = ({ step, isFirstStep, stepNumber, isPreviousEmail, assetContext }) => {
  const { t } = useTranslation()
  const {
    setPanels: {
      addEmailNotification: { on: addEmailOn },
    },
  } = useOutlineEditPanels()

  // Open and fetch the email template on click
  // We have to use emailTemplateBindingsId since email queries do not accept a resourceId
  const onTitleClick = step.type === 'email' ? () => addEmailOn(step.emailTemplateBindingsId) : undefined

  return (
    <>
      <Common.DragHandleAndNumber>
        {stepNumber}
        <Common.DragHandle />
      </Common.DragHandleAndNumber>

      <CourseTitleAndImage
        onTitleClick={onTitleClick}
        image={step.image}
        title={step.title}
        subline={getStepTypeTranslation(step, t)}
        stepType={step.type}
        assetContext={assetContext}
        imageSize='small'
      />

      <Common.TwoLineCell>
        <Schedule step={step} isFirstStep={isFirstStep} isPreviousEmail={isPreviousEmail} />

        {isContentProgramStep(step) ? (
          <LiveSessionAssignmentSelector step={step} />
        ) : (
          <Common.Empty>{t('dictionary.empty')}</Common.Empty>
        )}
      </Common.TwoLineCell>

      {isContentProgramStep(step) && <DueDate step={step} />}
      <Common.Empty />
      <Actions step={step} />
    </>
  )
}

const Step: StepRenderer = ({
  step,
  stepNumberPill,
  previousStep,
  StepGrid,
  isFirstStep,
  isLastStep,
  programId,
}) => {
  const stepContainerRef = useRef<HTMLDivElement | null>(null)
  const stepRef = useRef<HTMLDivElement | null>(null)
  const stepId = getStepId(step)

  const assetContext: AssetContext = useMemo(() => {
    switch (step.type) {
      case 'course':
        return { type: 'course', courseId: CourseId.parse(bareContentId(step.courseId)) }
      case 'path':
        return { type: 'path', pathId: PathId.parse(bareContentId(step.pathId)) }
      case 'email':
        return { type: 'program', programId }
      default:
        assertNever(step)
    }
  }, [step, programId])

  const [{ isDragging }, handleRef, dragRef] = useDrag<
    ProgramStepDragItem,
    { index: number },
    { isDragging: boolean }
  >(() => {
    return {
      type: 'program-step',
      canDrag: true,
      item: {
        type: 'program-step',
        id: getStepId(step),
        index: step.index,
        title: step.title,
        thumbnail: step.image,
        stepType: step.type,
        assetContext,
      } as ProgramStepDragItem,
      collect: monitor => ({ isDragging: monitor.isDragging() }),
    }
  }, [step, assetContext])

  const canDrop = useCallback(
    (draggedItem: ProgramDraggable): boolean => {
      const dropIndex = step.index
      const draggedIndex = draggedItem.index

      if (isLastStep !== true && draggedIndex + 1 === dropIndex) {
        return false
      }

      if (draggedItem.type === 'program-section') {
        return !isDefined(step.sectionIndex)
      }

      return draggedItem.id !== stepId
    },
    [step, stepId, isLastStep]
  )

  dragRef(handleRef(stepContainerRef))

  return (
    <DraggableStepContainer
      ref={stepContainerRef}
      role='button'
      tabIndex={0}
      $isDragging={isDragging}
      layout={!isDragging}
      transition={{
        layout: {
          type: 'spring',
          bounce: 0.1,
          duration: 0.3,
        },
      }}
    >
      <Dropzone
        step={step}
        position='above'
        canDrop={canDrop}
        isFirstStep={isFirstStep ?? false}
        isLastStep={isLastStep ?? false}
      />

      <Common.StepOutline isDragging={isDragging} highlighted={false} ref={stepRef}>
        <StepGrid>
          <StepContent
            step={step}
            isFirstStep={previousStep === undefined}
            stepNumber={stepNumberPill}
            isPreviousEmail={previousStep?.type === 'email'}
            assetContext={assetContext}
          />
        </StepGrid>
      </Common.StepOutline>

      {isLastStep === true && (
        <Dropzone step={step} position='below' canDrop={canDrop} isFirstStep={false} isLastStep />
      )}
    </DraggableStepContainer>
  )
}

export const ProgramStepsEdit: React.FC<{ programId: ProgramId }> = ({ programId }) => {
  // TODO: const { resetHighlight } = useScrollHighlight({ stepId: undefined })

  return (
    <>
      <Common.ProgramStepsRenderer
        // This component is only rendered when editing the outline.
        // The permission has already been checked at this point.
        canEdit={true}
        Renderer={{
          StepGrid,
          Header,
          Step,
          OptionalFooter: Common.AddStepFooter,
        }}
        programId={programId}
      />

      <ProgramStepDragOverlay />
      <ProgramSectionDragOverlay />
    </>
  )
}
