import React, { useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { resolveThemeColor, useThemes } from 'sierra-client/hooks/use-themes'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useAbortMultiSelectionClear } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-abort-multi-selection-clear'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { canChangeTheme } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/utils'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { usePolarisContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { ColorPicker } from 'sierra-client/views/v3-author/title-card/color-picker'
import { apply } from 'sierra-domain/editor/operations'
import { File, ThemeName } from 'sierra-domain/flexible-content/types'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import { Editor, Text as SlateText, Transforms } from 'slate'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  margin-top: 3px;
`

const StyledText = styled(Text)`
  padding-bottom: 5px;
  color: ${p => p.theme.color.grey40};
  border-bottom: 1px solid ${palette.grey[5]};
  font-weight: 500;
`
const Styleddiv = styled.div`
  padding: ${spacing['2']};
`

const ColorIconsWrapper = styled(View)`
  padding-bottom: 10px;
`
const StyledView = styled(View)`
  width: 224px;
  padding: 12px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
`

export const FileThemePicker: React.FC<{
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
}> = ({ file, previewThemes }) => {
  const { customThemes, newPresetThemes } = useThemes()
  const { t } = useTranslation()
  const fileTheme = useThemeForFile(file)
  const { setAbortingMultiSelectionClear } = useAbortMultiSelectionClear()
  const [themePickerOpen, setThemePickerOpen] = useState(false)
  const { selectedFileIds } = useMultiSelection()

  const [checkColorsModal, setCheckColorsModal] = useState<{
    open: boolean
    theme: File['theme'] | undefined
  }>({ open: false, theme: undefined })

  const { editor } = usePolarisContext()
  const { operationState } = useCreatePageContext()

  const updateTheme = (theme: File['theme']): void => {
    if (previewThemes !== undefined) {
      previewThemes(undefined)
    }

    apply(operationState, {
      type: 'update-files',
      fileIds: selectedFileIds,
      update: file => {
        if (canChangeTheme(file)) file.theme = theme
      },
    })

    setAbortingMultiSelectionClear(false)
  }

  const removeCustomColors = (): void => {
    if (editor.type === 'available') {
      Transforms.setNodes(
        editor.value,
        { color: undefined },
        {
          at: [],
          match: n => SlateText.isText(n) && n.color !== undefined,
        }
      )
    }
  }

  const onThemeClick = (theme: File['theme']): void => {
    const hasCustomColors =
      editor.type === 'available' &&
      Array.from(
        Editor.nodes(editor.value, {
          match: n => SlateText.isText(n) && n.color !== undefined,
          at: [],
        })
      ).length > 0

    if (hasCustomColors) {
      setCheckColorsModal({ open: true, theme: theme })
    } else {
      updateTheme(theme)
    }
  }

  return (
    <>
      <ColorPicker.Menu
        theme={fileTheme}
        isOpen={themePickerOpen}
        setOpen={newOpen => {
          setAbortingMultiSelectionClear(newOpen)
          setThemePickerOpen(newOpen)
        }}
      >
        <StyledView direction='column'>
          {Object.keys(customThemes).length > 0 && (
            <>
              <StyledText color='grey40' size='micro'>
                {t('author.custom')}
              </StyledText>
              <ColorIconsWrapper gap='none' wrap='wrap' direction='row'>
                {Object.entries(customThemes).map(([id, theme]) => (
                  <Styleddiv
                    key={id}
                    onMouseEnter={() => {
                      if (previewThemes !== undefined) {
                        previewThemes(ThemeName.parse(id))
                      }
                    }}
                    onMouseLeave={() => {
                      if (previewThemes !== undefined) {
                        previewThemes(undefined)
                      }
                    }}
                  >
                    <ColorPicker.Color
                      key={id}
                      color={resolveThemeColor(theme.backgroundColor)}
                      isSelected={file.theme === id}
                      onClick={() => onThemeClick(ThemeName.parse(id))}
                    >
                      <StyledIcon iconId='text--color' color={color(theme.foregroundColor)} />
                    </ColorPicker.Color>
                  </Styleddiv>
                ))}
              </ColorIconsWrapper>
            </>
          )}
          <StyledText color='grey40' size='micro'>
            {t('author.preset')}
          </StyledText>
          <ColorIconsWrapper gap='none' wrap='wrap' direction='row'>
            {Object.entries(newPresetThemes).map(([id, theme]) => (
              <Styleddiv
                key={id}
                onMouseEnter={() => {
                  if (previewThemes !== undefined) {
                    previewThemes(ThemeName.parse(id))
                  }
                }}
                onMouseLeave={() => {
                  if (previewThemes !== undefined) {
                    previewThemes(undefined)
                  }
                }}
              >
                <ColorPicker.Color
                  key={id}
                  color={resolveThemeColor(theme.backgroundColor)}
                  isSelected={file.theme === id}
                  onClick={() => {
                    onThemeClick(ThemeName.parse(id))
                    setThemePickerOpen(false)
                  }}
                >
                  <StyledIcon iconId='text--color' color={theme.textColor} />
                </ColorPicker.Color>
              </Styleddiv>
            ))}
          </ColorIconsWrapper>
        </StyledView>
      </ColorPicker.Menu>
      {checkColorsModal.theme !== undefined && (
        <ActionModal
          open={checkColorsModal.open}
          onClose={() => {
            setCheckColorsModal({ open: false, theme: undefined })
          }}
          primaryAction={() => updateTheme(checkColorsModal.theme)}
          primaryActionLabel={t('theme-picker.action-modal.primary-action')}
          secondaryAction={() => {
            removeCustomColors()
            updateTheme(checkColorsModal.theme)
          }}
          secondaryActionLabel={t('theme-picker.action-modal.secondary-action')}
        >
          {t('theme-picker.action-modal.body')}
        </ActionModal>
      )}
    </>
  )
}
