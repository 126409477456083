import { atom } from 'jotai'
import { atomWithStorage } from 'sierra-client/state/storage'

export const searchInsightsCmdKEnabledAtom = atomWithStorage('searchInsightsEnabled', false)
export const debugModeAtom = atomWithStorage('debugModeEnabled', false)
export const showTranslationStringsAtom = atom(false)
export const newPageCardColumnColorAtom = atom(false)
export const newPageCardAlignmentAtom = atom(false)
export const newPageScrollPageAtom = atom(false)
export const reflectionsSummaryAtom = atom(false)
export const chaosTestingEnabledAtom = atom(false)
export const searchRerankingAnnotationModeEnabledAtom = atom(false)
export const newRecapInfraAtom = atom(false)
export const askInsightsModelAtom = atomWithStorage<'sql-v2-few-shot'>('askInsightsModel', 'sql-v2-few-shot')
export const loadNamespacedCourseAssetsAtom = atomWithStorage('loadNamespacedCourseAssets', false)
export const videoAvatarsAtom = atom(false)
export const SEARCH_MODELS = [
  'default',
  'vespa:bm25',
  'vespa:bm25-freshness',
  'vespa:minilml6v2',
  'vespa:bm25-minilml6v2-freshness',
  'vespa:bm25-minilml6v2-freshness-prefix',
] as const
export type SearchModel = (typeof SEARCH_MODELS)[number]
export const searchModelAtom = atom<SearchModel>('default')
