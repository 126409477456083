import React, { useCallback, useEffect, useState } from 'react'
import { IdentitiesSelector, Identity } from 'sierra-client/components/common/identities-selector'
import { usePossibleReviewersIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { getFlag } from 'sierra-client/config/global-config'
import {
  useHomeworkAdminsToNotifyMutation,
  useHomeworkAdminsToNotifyQuery,
  useHomeworkReviewerMutation,
  useHomeworkReviewersQuery,
} from 'sierra-client/features/homework/use-homework-reviewers-and-admins'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { LightText } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { assert, isDefined } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const EMPTY_USER_ID_ARRAY: UserId[] = []

const ReviewersWrapper = styled(View).attrs({ direction: 'column', gap: 'xxsmall', grow: true })``

const useReivewerUserIdentities = ({ userIds }: { userIds: UserId[] }): Identity[] => {
  const [userIdentities, setUserIdentities] = useState<Identity[]>([])

  const identitiesFetcher = usePossibleReviewersIdentitiesFetcher()

  const updateSelectedIdentities = useCallback(
    async (userIds: UserId[]): Promise<void> => {
      const allReviewerIdentities = await identitiesFetcher('')

      setUserIdentities(
        userIds
          .map(userId => {
            const identity = allReviewerIdentities.find(reviewer => reviewer.id === userId)
            return identity
          })
          .filter(isDefined)
      )
    },
    [identitiesFetcher]
  )

  useEffect(() => {
    void updateSelectedIdentities(userIds)
  }, [userIds, updateSelectedIdentities])

  return userIdentities
}

export const ReviewerSelector: React.FC<{
  courseId: CreateContentId
  fileId: NanoId12
  canEdit: boolean
}> = ({ courseId, fileId, canEdit }) => {
  const { t } = useTranslation()

  const identitiesFetcher = usePossibleReviewersIdentitiesFetcher()
  const query = useHomeworkReviewersQuery({ courseId, fileId })
  const mutation = useHomeworkReviewerMutation({ courseId, fileId })

  const userIds = query.data?.reviewers ?? EMPTY_USER_ID_ARRAY
  const selectedReviewerIdentities = useReivewerUserIdentities({ userIds })

  return (
    <ReviewersWrapper>
      <Text size='small' bold>
        {t('author.homework.responsible-reviewers')}
      </Text>
      <IdentitiesSelector
        fetchIdentities={identitiesFetcher}
        selectedIdentities={selectedReviewerIdentities}
        onSelect={identity => {
          assert(identity.identity.type === 'user')

          mutation.mutate({
            courseId,
            fileId,
            action: 'add',
            reviewerId: identity.identity.id,
          })
        }}
        onUnselect={identity => {
          assert(identity.identity.type === 'user')

          mutation.mutate({
            courseId,
            fileId,
            action: 'remove',
            reviewerId: identity.identity.id,
          })
        }}
        placeholder={canEdit ? 'author.homework.settings.add-reviewers' : undefined}
        disabled={!canEdit}
      />
    </ReviewersWrapper>
  )
}

export const NotifyAdminSelector: React.FC<{
  courseId: CreateContentId
  fileId: NanoId12
  canEdit: boolean
}> = ({ courseId, fileId, canEdit }) => {
  const { t } = useTranslation()
  const isNotifyUsersEnabled = getFlag('homework/notify-users-on-hard-fail')

  const identitiesFetcher = usePossibleReviewersIdentitiesFetcher()
  const query = useHomeworkAdminsToNotifyQuery({ courseId, fileId })
  const mutation = useHomeworkAdminsToNotifyMutation({ courseId, fileId })

  const userIds = query.data?.admins ?? EMPTY_USER_ID_ARRAY
  const selectedAdminIdentities = useReivewerUserIdentities({ userIds })

  // Feature flag the feature!
  if (!isNotifyUsersEnabled) {
    return null
  }

  return (
    <ReviewersWrapper>
      <Text size='small' bold>
        {t('author.homework.responsible-admins')}
      </Text>
      <LightText size='small'>{t('author.homework.responsible-admins-description')}</LightText>
      <IdentitiesSelector
        fetchIdentities={identitiesFetcher}
        selectedIdentities={selectedAdminIdentities}
        onSelect={identity => {
          assert(identity.identity.type === 'user')

          mutation.mutate({
            courseId,
            fileId,
            action: 'add',
            userId: identity.identity.id,
          })
        }}
        onUnselect={identity => {
          assert(identity.identity.type === 'user')

          mutation.mutate({
            courseId,
            fileId,
            action: 'remove',
            userId: identity.identity.id,
          })
        }}
        placeholder={canEdit ? 'author.homework.settings.add-admins' : undefined}
        disabled={!canEdit}
      />
    </ReviewersWrapper>
  )
}
