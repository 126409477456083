import _ from 'lodash'
import { getFlag } from 'sierra-client/config/global-config'
import { rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { Editor, Path, Range } from 'slate'

export function onKeyDown(event: EditorKeyboardEvent, editor: Editor): void {
  if (!getFlag('editor-tabbing')) {
    return
  }

  const selection = editor.selection
  if (!selection || Range.isExpanded(selection) || event.key !== 'Tab') {
    return
  }

  const isBackwards = event.shiftKey

  const [startOfCurrentNode, endOfCurrentNode] = rangeOfNode(editor, Path.parent(selection.anchor.path))
  const isAtStartOfCurrentNode = _.isEqual(startOfCurrentNode, selection.anchor)

  if (isBackwards) {
    // Check if we are on the first element, and if so, we should let the browser handle the event
    const firstElementPath = Path.parent(selection.anchor.path)
    const isOnFirstPath = firstElementPath.every(i => i === 0)

    if (!isOnFirstPath || !isAtStartOfCurrentNode) {
      const pointBefore = isAtStartOfCurrentNode
        ? Editor.before(editor, selection.anchor.path, { unit: 'block' })
        : startOfCurrentNode

      if (pointBefore) {
        editor.select(pointBefore)
      }
      event.preventDefault()
    }
  } else {
    const [, lastNode] = Editor.edges(editor, [])
    const [, lastPoint] = rangeOfNode(editor, lastNode)
    const lastPath = Path.parent(lastPoint.path)
    const isOnLastPath = Path.equals(lastPath, Path.parent(selection.anchor.path))
    const isAtEndOfCurrentNode = _.isEqual(selection.anchor, endOfCurrentNode)

    if (!(isOnLastPath && isAtEndOfCurrentNode)) {
      const pointAfter = isAtEndOfCurrentNode
        ? Editor.after(editor, selection.anchor.path, { unit: 'block' })
        : endOfCurrentNode
      if (pointAfter) {
        editor.select(pointAfter)
      }
      event.preventDefault()
    }
  }
}
