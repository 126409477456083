import _ from 'lodash'

export type RemoteUpdateVerifierOptions = {
  isEnabled?: boolean
  timeout?: number
  maxUpdateAge?: number
  onMissingUpdates: (ids: number[]) => void
  onFailedToRecoverUpdates: (id: number[]) => void
  onLatestYUpdateIdChanged: (id: number) => void
}

export class RemoteUpdateVerifier {
  latestUpdateId: number | undefined = undefined

  private readonly pendingUpdateIds: Set<number> = new Set()

  private readonly missingUpdateIds: Map<number, { time: number }> = new Map()

  private readonly updateFailureCount: Map<number, number> = new Map()

  private readonly onLatestYUpdateIdChanged: (id: number) => void

  /**
   * The largest update id that has been checked for gaps. For ids lower than this,
   * we can assume that they are either confirmed (by being added as pending or by being
   * lower than latestUpdateId) or that they have been added to the map of missing update ids.
   */
  private maxValidatedId: number = -1

  /**
   * Finds missing update ids and adds them to the map.
   */
  private registerMissingUpdates(): void {
    if (this.latestUpdateId === undefined) return

    const lowerBound = Math.max(this.latestUpdateId, this.maxValidatedId)
    const upperBound = Math.max(this.latestUpdateId, ...this.pendingUpdateIds)

    for (let id = lowerBound + 1; id < upperBound; id++) {
      if (!this.pendingUpdateIds.has(id)) {
        this.missingUpdateIds.set(id, { time: Date.now() })
      }
    }

    this.maxValidatedId = upperBound
  }

  private compactPendingUpdates(): void {
    if (this.latestUpdateId === undefined) {
      return
    }

    while (this.pendingUpdateIds.has(this.latestUpdateId + 1)) {
      this.latestUpdateId++
      this.onLatestYUpdateIdChanged(this.latestUpdateId)
    }

    for (const pendingUpdateId of this.pendingUpdateIds) {
      if (pendingUpdateId <= this.latestUpdateId) {
        this.pendingUpdateIds.delete(pendingUpdateId)
      }
    }

    for (const missingUpdateId of this.missingUpdateIds.keys()) {
      if (missingUpdateId <= this.latestUpdateId || this.pendingUpdateIds.has(missingUpdateId)) {
        this.missingUpdateIds.delete(missingUpdateId)
      }
    }

    // Find missing updates
    this.registerMissingUpdates()

    this.throttledCheckMissingUpdates()
  }

  private readonly throttledCheckMissingUpdates: _.DebouncedFunc<() => void>
  private readonly onMissingUpdates: (ids: number[]) => void
  private readonly isEnabled: boolean

  constructor({
    isEnabled = true,
    timeout = 2500,
    maxUpdateAge = 2500,
    onMissingUpdates,
    onFailedToRecoverUpdates,
    onLatestYUpdateIdChanged,
  }: RemoteUpdateVerifierOptions) {
    this.isEnabled = isEnabled

    this.onMissingUpdates = onMissingUpdates

    this.onLatestYUpdateIdChanged = onLatestYUpdateIdChanged

    this.throttledCheckMissingUpdates = _.throttle(
      () => {
        const missingOldUpdates = []
        const now = Date.now()
        for (const [id, date] of this.missingUpdateIds) {
          if (now - date.time >= maxUpdateAge) {
            missingOldUpdates.push(id)
          }
        }

        if (missingOldUpdates.length > 0) {
          for (const id of missingOldUpdates) {
            const failCount = this.updateFailureCount.get(id) ?? 0
            this.updateFailureCount.set(id, failCount + 1)
          }
          this.onMissingUpdates(missingOldUpdates)
        }

        if (this.missingUpdateIds.size > 0) {
          this.throttledCheckMissingUpdates()
        }

        const updatesWithMoreThan3Failures = Array.from(this.updateFailureCount.entries())
          .filter(([, count]) => count > 3)
          .map(([id]) => id)

        if (updatesWithMoreThan3Failures.length > 0) {
          onFailedToRecoverUpdates(updatesWithMoreThan3Failures)
        }
      },
      timeout,
      { leading: false, trailing: true }
    )
  }

  setLatestUpdate(id: number): void {
    if (!this.isEnabled) {
      return
    }

    if (this.latestUpdateId === undefined || this.latestUpdateId < id) {
      this.latestUpdateId = id
      this.onLatestYUpdateIdChanged(this.latestUpdateId)
      this.compactPendingUpdates()
    }
  }

  addUpdate(id: number): void {
    if (!this.isEnabled) {
      return
    }

    this.pendingUpdateIds.add(id)
    this.compactPendingUpdates()
  }

  destroy(): void {
    this.throttledCheckMissingUpdates.cancel()
  }
}
