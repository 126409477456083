import { Link } from '@tanstack/react-router'
import fuzzysort from 'fuzzysort'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableMediumSearchTrigger } from 'sierra-client/components/table/table-medium'
import { date, joinFacilitators } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { MenuContainer } from 'sierra-client/views/manage/components/common'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { formatLiveSessionTimeAsSchedule } from 'sierra-client/views/manage/event-groups/event-utils'
import { liveSessionToCsv } from 'sierra-client/views/manage/live-session/live-session-utils'
import { CourseRating, LiveSessionRow } from 'sierra-domain/api/manage'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { IconMenu, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const MinWidth = styled.div`
  min-width: 160px;
`

export type CourseFeedbacksProps = {
  feedbacks: CourseRating[]
}

const mapFeedbackToCsv = (feedback: CourseRating): Record<string, string> => ({
  comment: feedback.comment,
  createdAt: date(feedback.createdAt),
  rating: feedback.rating.toFixed(1),
})

export const CourseFeedbackTable: React.FC<CourseFeedbacksProps> = ({ feedbacks }) => {
  const { t } = useTranslation()
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const columns: Column<CourseRating>[] = React.useMemo(
    () => [
      {
        id: 'comment',
        accessor: 'comment',
        Header: p => (
          <>
            <SelectableHeader {...p} />
            <SortableHeader label={t('dictionary.comment-singular')} smallLabel {...p} />
          </>
        ),
        Cell: p => (
          <>
            <SelectableRow {...p} />
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
              {p.row.original.comment}
            </Text>
          </>
        ),
        width: '60%',
      },
      {
        id: 'rating',
        accessor: item => `${item.rating.toFixed(1)} / 5`,
        Header: p => <SortableHeader label={t('table.rating')} {...p} />,
        width: '15%',
      },
      {
        id: 'createdAt',
        accessor: i => date(i.createdAt),
        width: '15%',
        Header: p => <SortableHeader label={t('dictionary.date')} {...p} />,
      },
      {
        width: '10%',
        id: 'actions',
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
      },
    ],
    [t]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: feedbacks, columns: columns },
    getEntityId: () => '',
  })

  return (
    <div>
      <SmallTableWrapper title={t('dictionary.feedback')}>
        <ManageTableSmall
          tableInstance={tableInstance}
          onViewDetails={() => {}}
          getEntityId={() => ''}
          mapEntityToCsv={mapFeedbackToCsv}
          isSearchOpen={isSearchOpen}
          searchTrigger={setIsSearchOpen}
          translations={{
            searchPlaceholder: t('dictionary.search'),
            tableLoading: t('dictionary.loading'),
            tableNoResults: t('author.slate.no-results'),
            csvPrefix: t('dictionary.feedback'),
          }}
          getCellProps={() => ({
            style: { verticalAlign: 'top' },
          })}
          isLoading={false}
          focusedId={undefined}
          bulkActions={<></>}
          footerButton={<></>}
        />
      </SmallTableWrapper>
    </div>
  )
}

type SessionDateFilter = 'upcoming' | 'all' | 'past'

type SessionFilter = {
  query: string
  dateFilter: SessionDateFilter
}

const DEFAULT_FILTER: SessionFilter = {
  query: '',
  dateFilter: 'upcoming',
}

const LiveSessionActionsCell: React.FC<{
  liveSessionId: LiveSessionId
  isFull: boolean
  openAssignModal: (_: LiveSessionId[]) => void
  onOpenChange?: (open: boolean) => void
}> = ({ liveSessionId, isFull, openAssignModal, onOpenChange }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <MenuContainer>
      <IconMenu
        iconId='overflow-menu--horizontal'
        variant='transparent'
        isOpen={isOpen}
        onOpenChange={open => {
          onOpenChange?.(open)
          setIsOpen(open)
        }}
        menuItems={[
          {
            id: 'view-details' as const,
            type: 'canvas',
            render: () => (
              <Link to='/manage/events/$liveSessionId' params={{ liveSessionId }}>
                <Text as='span'>{t('manage.view-details')}</Text>
              </Link>
            ),
          },
          {
            type: 'label',
            label: t('dictionary.assign'),
            id: 'assign' as const,
            disabled: isFull,
          },
        ]}
        onSelect={item => {
          switch (item.id) {
            case 'view-details':
              // not reachable, this should be a link
              break
            case 'assign':
              openAssignModal([liveSessionId])
              break
            default:
              assertNever(item.id)
          }
        }}
      />
    </MenuContainer>
  )
}

interface CourseLiveSessionTableProps {
  liveSessions: LiveSessionRow[]
  openAssignModal: (sessionIds: string[]) => void
}
export const CourseLiveSessionTable: React.FC<CourseLiveSessionTableProps> = ({
  liveSessions,
  openAssignModal,
}) => {
  const { t } = useTranslation()
  const [focusedLiveSessionId, setFocusedLiveSessionId] = useState<LiveSessionId | undefined>(undefined)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
  const [filter, setFilter] = useState<SessionFilter>(DEFAULT_FILTER)

  const filteredSessions = useMemo(() => {
    let sessions = liveSessions

    if (filter.dateFilter !== 'all') {
      sessions = sessions.filter(session => {
        if (session.data.type !== 'scheduled') return false

        const dt = DateTime.fromISO(session.data.endTime)

        if (filter.dateFilter === 'upcoming') {
          return dt >= DateTime.now()
        }

        if (filter.dateFilter === 'past') {
          return dt < DateTime.now()
        }

        return true
      })
    }

    if (filter.query.trim() !== '') {
      sessions = fuzzysort.go(filter.query, sessions, { key: 'data.title' }).map(({ obj }) => obj)
    }

    return sessions
  }, [liveSessions, filter])

  const goToDetails = useCallback((liveSessionId: string) => {
    void getGlobalRouter().navigate({ to: `/manage/events/${liveSessionId}` })
  }, [])

  const columns: Column<LiveSessionRow>[] = React.useMemo(
    () => [
      {
        id: 'time',
        Header: p => {
          return (
            <>
              {/* <SelectableHeader {...p} /> */}
              <SortableHeader label={t('dictionary.date')} smallLabel {...p} />
            </>
          )
        },
        accessor: ls => scheduledOrNull(ls.data)?.startTime,
        Cell: (p: CellProps<LiveSessionRow>) => {
          const session = scheduledOrNull(p.row.original.data)
          const formattedDate = formatLiveSessionTimeAsSchedule(session)

          return (
            <View alignItems='center'>
              <Text size='small'>
                {formattedDate !== undefined ? (
                  <>{formattedDate}</>
                ) : (
                  t('dictionary.live-session.not-scheduled')
                )}
              </Text>
            </View>
          )
        },
        width: '25%',
      },
      {
        id: 'title',
        accessor: ls => ls.data.title,
        width: '30%',
        Header: p => <SortableHeader label={t('table.name')} smallLabel {...p} />,
        Cell: (p: CellProps<LiveSessionRow>) => (
          <Text size='small' bold>
            {p.row.original.data.title}
          </Text>
        ),
      },
      {
        id: 'attendees',
        Header: p => <SortableHeader label={t('table.assigned')} smallLabel {...p} />,
        accessor: ls => ls.assignmentsCount,
        Cell: ({ row }: CellProps<LiveSessionRow>) => {
          const { assignmentsCount } = row.original
          const { maxNumberOfUsers } = row.original.data

          return (
            <>
              {[
                assignmentsCount,
                maxNumberOfUsers !== undefined && maxNumberOfUsers > 0 ? ` / ${maxNumberOfUsers}` : '',
              ].join('')}
            </>
          )
        },
        width: '15%',
      },
      {
        id: 'facilitator',
        Header: p => <SortableHeader label={t('manage.live-session.facilitator')} smallLabel {...p} />,
        accessor: ls => ls.data.facilitatorIds,
        Cell: ({ row }: CellProps<LiveSessionRow>) => <>{joinFacilitators(row.original.facilitatorsInfo)}</>,
        width: '20%',
      },
      // {
      //   id: 'status',
      //   Header: p => <SortableHeader label={t('table.status')} smallLabel {...p} />,
      //   accessor: ls => ls.status,
      //   // width: '15%',
      // },

      {
        id: 'actions',
        width: '10%',
        disableSortBy: true,
        accessor: 'liveSessionId',
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
        Cell: ({ row }: CellProps<LiveSessionRow>) => {
          const {
            liveSessionId,
            assignmentsCount,
            data: { maxNumberOfUsers },
          } = row.original

          const isFull = maxNumberOfUsers !== undefined && assignmentsCount >= maxNumberOfUsers

          return (
            <LiveSessionActionsCell
              liveSessionId={liveSessionId}
              isFull={isFull}
              openAssignModal={openAssignModal}
              onOpenChange={open => {
                if (open) {
                  setFocusedLiveSessionId(liveSessionId)
                } else {
                  setFocusedLiveSessionId(undefined)
                }
              }}
            />
          )
        },
      },
    ],
    [openAssignModal, t]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: filteredSessions, columns },
    getEntityId: ls => ls.liveSessionId,
  })

  // const selectedSessionIds = tableInstance.selectedFlatRows.map(r => r.original.liveSessionId)

  const filterItems: MenuItem<SessionDateFilter>[] = [
    {
      type: 'label',
      id: 'upcoming',
      label: t('manage.live-session.filter.upcoming-sessions'),
    },
    {
      type: 'label',
      id: 'all',
      label: t('admin.author.sessions.all'),
    },
    {
      type: 'label',
      id: 'past',
      label: t('manage.live-session.filter.past-sessions'),
    },
  ]
  const selectedItem = filterItems.find(item => item.id === filter.dateFilter)

  return (
    <View direction='column' gap='xsmall'>
      <Text size='large' bold>
        {t('content.sessions')}
      </Text>
      <View grow justifyContent='space-between'>
        <RoundedSearchBar
          value={filter.query}
          onChange={query => setFilter(curr => ({ ...curr, query }))}
          placeholder={t('manage.search.sessions')}
        />
        <View>
          <MinWidth>
            <SingleSelectDropdown
              selectedItem={selectedItem}
              menuItems={filterItems}
              onSelect={item => {
                setFilter(curr => ({ ...curr, dateFilter: item.id }))
              }}
            />
          </MinWidth>
        </View>
      </View>
      <ManageTableSmall
        tableInstance={tableInstance}
        focusedId={focusedLiveSessionId}
        onViewDetails={goToDetails}
        getEntityId={ls => ls.liveSessionId}
        mapEntityToCsv={entity =>
          liveSessionToCsv({
            liveSessionId: entity.liveSessionId,
            data: entity.data,
            facilitatorsInfo: entity.facilitatorsInfo,
            assignedUsers: entity.assignmentsCount,
          })
        }
        isSearchOpen={isSearchOpen}
        searchTrigger={setIsSearchOpen}
        bulkActions={<></>}
        hideSelect
        translations={{
          searchPlaceholder: t('search.sessions'),
          tableLoading: t('manage.sessions.table-loading'),
          tableNoResults: t('manage.sessions.no-results'),
          csvPrefix: t('content.sessions'),
        }}
      />
    </View>
  )
}
