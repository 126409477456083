import { useMemo } from 'react'
import { getFileContent, getFileContentImage } from 'sierra-client/api/content'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  newAssessmentData,
  newBreakoutRoomData,
  newBulletData,
  newDropAWordData,
  newEmbedData,
  newExternalNotepadData,
  newFlipCardsData,
  newGeneralData,
  newHomeworkData,
  newImageData,
  newLiveLobbyData,
  newPollData,
  newQuestionData,
  newReflectionsData,
  newScenarioData,
  newSlateCardData,
  newSlidingScaleData,
  newStickyNotesCardData,
  newStupidQuestionsData,
  newVideoData,
  useAddFileCreatedByUser,
} from 'sierra-client/state/flexible-content/factory'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  embedTypeToIconId,
  fileTypeToIconId,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/common'
import { useLiveQuizEnabled } from 'sierra-client/views/v3-author/live-quiz/use-live-quiz-enabled'
import { useAllowScenarioCard } from 'sierra-client/views/v3-author/scenario/hooks'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { FolderId } from 'sierra-domain/flexible-content/identifiers'
import { fileIsSupportedInContent } from 'sierra-domain/flexible-content/support'
import { FileType, Node, UrlType } from 'sierra-domain/flexible-content/types'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { IconId } from 'sierra-ui/components'

export type CreateCardCategory = 'text-and-media' | 'interactive' | 'live' | 'embed'

type CreateCardBaseConfig = {
  icon: IconId
  category: readonly CreateCardCategory[]
  onClick: () => void | Promise<void>
  cover: {
    src: string
  }
  video?: {
    src: string
  }
}

type CreateCardBase = CreateCardBaseConfig & {
  fileType: Exclude<FileType, 'embed'> | 'generate'
}

type EmbedCard = CreateCardBaseConfig & {
  fileType: 'embed'
  embedType?: UrlType
}

export type CreateCardConfig = CreateCardBase | EmbedCard

type UseTemplateCardsInput = {
  selectedNodeId?: Node['id']
  currentFolderId?: FolderId
  onClick: () => void
  openGenerateCourseModal: () => void
}

export const useTemplateCards = ({
  selectedNodeId,
  currentFolderId,
  onClick,
  openGenerateCourseModal,
}: UseTemplateCardsInput): CreateCardConfig[] => {
  const { scopedCreateContentId } = useCreatePageContext()
  const projectCardFlag = getFlag('sana-internal')
  const allowScenarioCard = useAllowScenarioCard()
  const { t } = useTranslation()

  const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)

  const addFile = useAddFileCreatedByUser({
    folderId: currentFolderId,
    nextTo: selectedNodeId,
    cardAddedByUserLoggingType: 'createdByUser',
  })

  // Assessments cannot be added within folders, so we always place them at the root.
  const addAssessment = useAddFileCreatedByUser({
    folderId: 'folder:root',
    nextTo: currentFolderId === 'folder:root' ? selectedNodeId : currentFolderId,
    cardAddedByUserLoggingType: 'createdByUser',
  })

  const isLiveQuizEnabled = useLiveQuizEnabled()

  const cards = useMemo<CreateCardConfig[]>(
    () =>
      (
        [
          {
            fileType: 'generate',
            label: 'Generate',
            icon: 'glitter',
            category: [],
            cover: {
              src: getFileContentImage('card-miniatures/images/generate.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/generate.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              openGenerateCourseModal()
              onClick()
            },
          },
          {
            fileType: 'slate-card',
            label: 'Title',
            icon: fileTypeToIconId('slate-card'),
            category: ['text-and-media'],
            cover: {
              src: getFileContentImage('card-miniatures/images/title.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/title.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newSlateCardData() })
              onClick()
            },
          },
          {
            fileType: 'bullet',
            label: 'Image + Text',
            icon: fileTypeToIconId('bullet'),
            category: ['text-and-media'],
            cover: {
              src: getFileContentImage('card-miniatures/images/image-text.png', {
                bucket: 'sierra-static',
              }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/image-text.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newBulletData() })
              onClick()
            },
          },
          {
            fileType: 'general',
            label: 'Page',
            icon: fileTypeToIconId('general'),
            category: ['text-and-media'],
            cover: {
              src: getFileContentImage('card-miniatures/images/blank.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/blank.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newGeneralData() })
              onClick()
            },
          },
          {
            fileType: 'drop-a-word',
            label: 'Drop a word',
            icon: fileTypeToIconId('drop-a-word'),
            category: ['interactive'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/word-drop-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newDropAWordData() })
              onClick()
            },
          },
          {
            fileType: 'stupid-questions',
            label: 'Q&A',
            icon: fileTypeToIconId('stupid-questions'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/q-and-a.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/qa-card.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newStupidQuestionsData() })
              onClick()
            },
          },
          {
            fileType: 'scenario',
            label: 'Scenario',
            icon: fileTypeToIconId('scenario'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/roleplay-card.png', {
                bucket: 'sierra-static',
              }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/scenario.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newScenarioData() })
              onClick()
            },
          },
          {
            fileType: 'live-lobby',
            label: 'Lobby',
            icon: fileTypeToIconId('live-lobby'),
            category: ['live'],
            cover: {
              src: getFileContentImage('card-miniatures/images/lobby.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/lobby.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newLiveLobbyData() })
              onClick()
            },
          },
          {
            fileType: 'reflections',
            label: 'Reflection',
            icon: fileTypeToIconId('reflections'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/reflections.png'),
            },
            video: {
              src: getFileContent('card-miniatures/videos/reflections.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newReflectionsData() })
              onClick()
            },
          },
          {
            fileType: 'poll',
            label: 'Poll',
            icon: fileTypeToIconId('poll'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/poll.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/polls-new.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newPollData() })
              onClick()
            },
          },
          {
            fileType: 'sliding-scale',
            label: 'Sliding scale',
            icon: fileTypeToIconId('sliding-scale'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/sliding-scale.png', {
                bucket: 'sierra-static',
              }),
            },
            onClick: () => {
              void addFile({ data: newSlidingScaleData() })
              onClick()
            },
          },
          {
            fileType: 'flip-cards',
            label: 'Flip card',
            icon: fileTypeToIconId('flip-cards'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/poll.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/flip-cards.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newFlipCardsData() })
              onClick()
            },
          },
          {
            fileType: 'homework',
            label: 'Exercise',
            icon: fileTypeToIconId('homework'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/exercise-card.png', {
                bucket: 'sierra-static',
              }),
            },

            onClick: () => {
              void addFile({ data: newHomeworkData() })
              onClick()
            },
          },
          {
            fileType: 'sticky-notes',
            label: 'Sticky notes',
            icon: fileTypeToIconId('sticky-notes'),
            category: ['live'],
            cover: {
              src: getFileContentImage('card-miniatures/images/sticky-notes.png', {
                bucket: 'sierra-static',
              }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/sticky-notes.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newStickyNotesCardData() })
              onClick()
            },
          },
          {
            fileType: 'question-card',
            label: 'Question',
            icon: fileTypeToIconId('question-card'),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/question.png', {
                bucket: 'sierra-static',
              }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/questions-new.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newQuestionData() })
              onClick()
            },
          },
          {
            fileType: 'embed',
            label: 'Embed',
            icon: fileTypeToIconId('embed'),
            category: ['embed'],
            cover: {
              src: getFileContentImage('card-miniatures/images/embed-anything.png', {
                bucket: 'sierra-static',
              }),
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: undefined }) })
              onClick()
            },
          },
          {
            fileType: 'external-notepad',
            label: 'Notepad',
            icon: fileTypeToIconId('external-notepad'),
            category: ['live'],
            cover: {
              src: getFileContentImage('card-miniatures/images/notepad.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/notepad.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({
                data: newExternalNotepadData(),
                slateDocument: [createParagraph()],
              })
            },
          },
          {
            fileType: 'project-card',
            label: 'Project card',
            icon: fileTypeToIconId('project-card'),
            category: ['live'],
            cover: {
              src: getFileContentImage('card-miniatures/images/project-card.png', {
                bucket: 'sierra-static',
              }),
            },
            onClick: () => {
              void addFile({
                data: {
                  type: 'project-card',
                },
              })
              onClick()
            },
          },
          {
            fileType: 'breakout-room',
            label: 'Breakout rooms',
            icon: fileTypeToIconId('breakout-room'),
            category: ['live'],
            cover: {
              src: getFileContentImage('card-miniatures/images/breakout-rooms.png', {
                bucket: 'sierra-static',
              }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/breakout-rooms.mp4', {
                bucket: 'sierra-static',
              }),
            },
            onClick: () => {
              void addFile({ data: newBreakoutRoomData() })
              onClick()
            },
          },
          {
            fileType: 'video',
            label: 'Video',
            icon: fileTypeToIconId('video'),
            category: ['text-and-media'],
            cover: {
              src: getFileContentImage('card-miniatures/images/video.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/video.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newVideoData() })
            },
          },
          {
            fileType: 'assessment-card',
            label: contentType === 'live' ? t('assessment-card.quiz') : t('assessment-card.assessment'),
            icon: fileTypeToIconId('assessment-card', contentType),
            category: ['interactive'],
            cover: {
              src: getFileContentImage('card-miniatures/images/assessment.png', {
                width: 800,
                bucket: 'sierra-static',
              }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/assessment.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addAssessment({ data: newAssessmentData() })
              onClick()
            },
          },
          {
            fileType: 'image',
            label: 'Image',
            icon: fileTypeToIconId('image'),
            category: ['text-and-media'],
            cover: {
              src: getFileContentImage('card-miniatures/images/image.png', { bucket: 'sierra-static' }),
            },
            video: {
              src: getFileContent('card-miniatures/videos/image.mp4', { bucket: 'sierra-static' }),
            },
            onClick: () => {
              void addFile({ data: newImageData() })
              onClick()
            },
          },

          {
            fileType: 'embed',
            embedType: 'drive',
            label: 'drive',
            icon: embedTypeToIconId('drive'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-google-drive-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'drive' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'airtable',
            label: 'airtable',
            icon: embedTypeToIconId('airtable'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-airtable-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'airtable' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'youtube',
            label: 'youtube',
            icon: embedTypeToIconId('youtube'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-youtube-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'youtube' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'vimeo',
            label: 'vimeo',
            icon: embedTypeToIconId('vimeo'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-vimeo-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'vimeo' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'loom',
            label: 'loom',
            icon: embedTypeToIconId('loom'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-loom-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'loom' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'miro',
            label: 'miro',
            icon: embedTypeToIconId('miro'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-miro-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'miro' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'figma',
            label: 'figma',
            icon: embedTypeToIconId('figma'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-figma-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'figma' }) })
              onClick()
            },
          },

          {
            fileType: 'embed',
            embedType: 'trello',
            label: 'trello',
            icon: embedTypeToIconId('trello'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-trello-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'trello' }) })
              onClick()
            },
          },
          {
            fileType: 'embed',
            embedType: 'pitch',
            label: 'pitch',
            icon: embedTypeToIconId('pitch'),
            category: ['embed'],
            cover: {
              src: 'https://storage.googleapis.com/sierra-static/card-miniatures/images/embed-pitch-thumbnail.png',
            },
            onClick: () => {
              void addFile({ data: newEmbedData({ urlType: 'pitch' }) })
              onClick()
            },
          },
        ] as const
      )
        .filter(miniature => {
          if (miniature.fileType !== 'project-card') return true
          else return projectCardFlag
        })
        .filter(miniature => {
          if (miniature.fileType !== 'scenario') return true
          else return allowScenarioCard
        })
        .filter(miniature => {
          if (miniature.fileType !== 'assessment-card') return true
          if (contentType === 'self-paced') return true
          return isLiveQuizEnabled
        })
        .filter(
          miniature =>
            miniature.fileType === 'generate' || fileIsSupportedInContent(miniature.fileType, contentType)
        ),
    [
      t,
      openGenerateCourseModal,
      onClick,
      addFile,
      addAssessment,
      projectCardFlag,
      allowScenarioCard,
      contentType,
      isLiveQuizEnabled,
    ]
  )

  return cards
}
