import { useSetAtom } from 'jotai'
import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { HeaderItem } from 'sierra-client/features/collapsable-sidebar'
import { useRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { courseSettingsChannel } from 'sierra-client/realtime-data/channels'
import { useCachedQuery } from 'sierra-client/state/api'
import { manageSessionsPanelStateAtom } from 'sierra-client/views/flexible-content/editor/topbar/manage-sessions-panel-state'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import styled from 'styled-components'

const BlinkingDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 3px;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: rgba(255, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: -1;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.7;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0.7;
    }
  }
`

const _SidebarOngoingSession: React.FC<{ createContentId: LiveContentId }> = ({ createContentId }) => {
  const { t } = useTranslation()
  const setSessionPanelState = useSetAtom(manageSessionsPanelStateAtom)

  const ongoingLiveSessionsQuery = useCachedQuery(
    XRealtimeAuthorLiveSessionsListLiveSessions,
    {
      flexibleContentId: createContentId,
    },
    {
      select(data) {
        return data.liveSessions.filter(
          session => session.data.startedAt !== undefined && session.data.endedAt === undefined
        )
      },
    }
  )

  courseSettingsChannel.useChannel({
    channelId: createContentId,
    callback: event => {
      if (event.event === 'live-session-data-changed') {
        void ongoingLiveSessionsQuery.refetch()
      }
    },
  })

  if (ongoingLiveSessionsQuery.data === undefined) return

  const hasMultipleOngoingSessions = ongoingLiveSessionsQuery.data.length > 1

  if (hasMultipleOngoingSessions) {
    return (
      <HeaderItem
        type='simple-button-item'
        preComponent={<BlinkingDot />}
        title={t('admin.author.sessions.ongoing-session-item-label', {
          count: ongoingLiveSessionsQuery.data.length,
        })}
        onClick={() => {
          setSessionPanelState({ type: 'list-sessions' })
        }}
      />
    )
  }

  const session = ongoingLiveSessionsQuery.data[0]
  if (session === undefined) return null

  return (
    <HeaderItem
      href={`/l/${session.liveSessionId}`}
      type='label'
      preComponent={<BlinkingDot />}
      title={t('admin.author.sessions.ongoing-session-item-label', {
        count: ongoingLiveSessionsQuery.data.length,
      })}
    />
  )
}

export const SidebarOngoingSession: React.FC = () => {
  const ids = useRouterEditorIds()
  const linkingToEditorEnabled = getFlag('linking-to-editor')

  if (!isDefined(ids) || ids.mode !== 'live' || !linkingToEditorEnabled) return null

  return <_SidebarOngoingSession createContentId={ids.contentId} />
}
