import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import {
  SearchBar,
  SearchBarContainer,
  SearchResult,
  SearchResults,
} from 'sierra-client/components/shortcut-menu/search-ui'
import { usePost } from 'sierra-client/hooks/use-post'
import { getGlobalRouter } from 'sierra-client/router'
import {
  XRealtimeAuthorCreateFlexibleContent,
  XRealtimeAuthorGetCourseAvailability,
} from 'sierra-domain/routes'
import { Layout, Modal } from 'sierra-ui/components'
import { LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FullScreenModal = styled(Modal)`
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px;
`

const ProgressBar = styled(motion.div)`
  height: 4px;
  width: 0;
  left: 0;
  background-image: linear-gradient(to right, #9548f5, rgba(255, 230, 0, 0.4));
`

export const GenerateCourseOutline: React.FC = () => {
  const dispatch = useShortcutMenuDispatch()
  const { postWithUserErrorException } = usePost()
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const notif = useNotif()

  const generate = useCallback(async () => {
    setLoading(true)

    try {
      const { courseId } = await postWithUserErrorException(XRealtimeAuthorCreateFlexibleContent, {
        flexibleContentTemplate: {
          type: 'sana',
          templateName: 'blank-self-paced',
        },
        title: title,
        nGeneratedModules: undefined,
      })

      const maxAttempts = 120
      const waitTime = 1000

      for (let attempts = 0; attempts < maxAttempts; attempts++) {
        const result = await postWithUserErrorException(XRealtimeAuthorGetCourseAvailability, {
          courseId: courseId,
        })

        if (result.availability === true) {
          await getGlobalRouter().navigate({ to: `/create/s/${courseId}` })
          setLoading(false)
          void dispatch({ type: 'close' })
          break
        } else if (attempts === maxAttempts - 1) {
          setLoading(false)
          notif.push({ type: 'custom', level: 'warning', body: 'Could not generate course. Try again.' })
          void dispatch({ type: 'close' })
          break
        }

        await new Promise(resolve => setTimeout(resolve, waitTime))
      }
    } catch (error) {
      setLoading(false)
      notif.push({ type: 'custom', level: 'warning', body: 'Could not generate course. Try again.' })
      void dispatch({ type: 'close' })
    }
  }, [dispatch, title, postWithUserErrorException, notif])

  return (
    <>
      {loading && (
        <FullScreenModal open overlayVariant='dark'>
          <Layout>
            <View grow direction='column' alignItems='center' justifyContent='center'>
              <LoadingSpinner />
              <Spacer size='xsmall' />
              <Text>Generating course...</Text>
              <Text color='foreground/muted'>This can take a minute</Text>
              <Spacer size='xsmall' />
              <div style={{ width: '400px' }}>
                <ProgressBar
                  animate={{ width: [0, 50, 150, 200, 250, 280, 300, 400] }}
                  transition={{ duration: 60 }}
                />
              </div>
            </View>
          </Layout>
        </FullScreenModal>
      )}
      <SearchBarContainer>
        <SearchBar
          value={title}
          placeholder={'Choose a course title...'}
          label={'Generate course outline'}
          onChange={text => {
            setTitle(text)
          }}
          onIndexChanged={() => {}}
          onResultSelected={() => generate()}
        />
      </SearchBarContainer>
      <SearchResults>
        {title.length > 0 && (
          <SearchResult $selected={true} onClick={() => generate()} key={'create-self-paced'}>
            {`Generate a self-paced course with title "${title}"`}
          </SearchResult>
        )}
      </SearchResults>
    </>
  )
}
