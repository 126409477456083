import { FC } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { SectionRenderer } from 'sierra-client/features/program/admin/view/section'
import { StepCard } from 'sierra-client/features/program/admin/view/step'
import { useProgramOutline } from 'sierra-client/features/program/hooks/use-program-outline'
import { clusterSections } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/clusterSection'
import { getStepId } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { ConditionalWrapper } from 'sierra-ui/utils'

type ProgramAdminViewProps = {
  programId: ProgramId
}

export const ProgramAdminView: FC<ProgramAdminViewProps> = ({ programId }) => {
  const { outline } = useProgramOutline({ programId })

  const clusters = clusterSections(outline)
  const sectionsFeatureEnabled = getFlag('programs/sections')

  return (
    <div role='list'>
      {clusters.map((cluster, clusterIndex) => {
        const sectionIndex = cluster.sectionIndex
        const section = isDefined(sectionIndex) ? outline.sections[sectionIndex] : null
        const clusterKey = isDefined(section)
          ? 'draftId' in section
            ? section.draftId
            : section.id
          : clusterIndex

        return (
          <ConditionalWrapper
            key={clusterKey}
            condition={sectionsFeatureEnabled && isDefined(sectionIndex)}
            renderWrapper={children => {
              return (
                <SectionRenderer outline={outline} sectionIndex={sectionIndex ?? undefined}>
                  {children}
                </SectionRenderer>
              )
            }}
          >
            {cluster.members.map((step, index) => {
              const previousStep = index > 0 ? outline.steps[index - 1] : undefined
              const withBorder =
                sectionsFeatureEnabled && index === cluster.members.length - 1 && step.sectionIndex === null

              return (
                <StepCard
                  key={getStepId(step)}
                  outline={outline}
                  step={step}
                  previousStep={previousStep}
                  withBorder={withBorder}
                  programId={programId}
                />
              )
            })}
          </ConditionalWrapper>
        )
      })}
    </div>
  )
}
