import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { useOutlineReducer } from 'sierra-client/features/program'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useHideIntercom } from 'sierra-client/intercom/intercom-visibility'
import { ImageUploadControl } from 'sierra-client/views/manage/paths/components/image-upload-control'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { SendTestEmailAlert } from 'sierra-client/views/manage/programs/staggered-assignments/alerts/send-test-email'
import { SendTestEmailRateLimitedAlert } from 'sierra-client/views/manage/programs/staggered-assignments/alerts/send-test-email-rate-limited'
import {
  emailPlaceholdersAtom,
  newEmailTemplateIds,
  previewElementAtom,
} from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import { useEmailTemplate } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-email-template'
import { useOutlineEditPanels } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit-panels'
import { useRenderProgramEmail } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-render-program-email'
import { ProgramPanelCommon } from 'sierra-client/views/manage/programs/staggered-assignments/panels/common'
import {
  createEmailTemplateBindings,
  fetchEmailTemplateBindings,
  sendTestEmail as sendTestEmailTemplate,
  updateEmailTemplateBindings,
} from 'sierra-client/views/manage/programs/staggered-assignments/queries/email-template-queries'
import {
  EmailPlaceholderKeys,
  EmailPlaceholderValues,
} from 'sierra-client/views/manage/programs/staggered-assignments/types'
import {
  createEmailStep,
  createTimeScheduleOnPreviousComplete,
  deserializeTemplateBindings,
  getNonEmptyEmailTemplate,
  serializeTemplateAsBindingsInput,
  serializeTemplateAsRenderable,
  useLightColor,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { useAtLeastElapsed } from 'sierra-client/views/manage/utils/use-at-least-elapsed'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { isLeft } from 'sierra-domain/either'
import { ImageData } from 'sierra-domain/flexible-content/types'
import { isNotNull } from 'sierra-domain/utils'
import { FormElement, MenuItem, Tooltip } from 'sierra-ui/components'
import { PanelInPanel, PanelInPanelCloseButton } from 'sierra-ui/components/layout-kit/layout-panel'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, InputPrimitive, LoadingSpinner, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { LightTokenProvider, token, useTokenValue } from 'sierra-ui/theming'
import styled from 'styled-components'

type TemplateIdProp = {
  templateId: string
}

const TemplateDataLoader: React.FC<
  TemplateIdProp & {
    setIsLoading: (isLoading: boolean) => void
  }
> = ({ templateId, setIsLoading }) => {
  const { updateEmailTemplate, setInitialEmailTemplate } = useEmailTemplate()
  const { t } = useTranslation()

  useEffect(() => {
    void (async () => {
      setIsLoading(true)

      const { emailTemplateBindings } = await fetchEmailTemplateBindings(templateId)
      const initialTemplate = deserializeTemplateBindings(emailTemplateBindings)

      setTimeout(() => {
        setInitialEmailTemplate(initialTemplate)
        updateEmailTemplate(initialTemplate)
        setIsLoading(false)
      }, 500)
    })()
  }, [t, updateEmailTemplate, setInitialEmailTemplate, setIsLoading, templateId])

  return <></>
}

const PanelLayout = styled(View).attrs({
  direction: 'column',
  gap: 'none',
  alignItems: 'stretch',
  paddingTop: '40',
  paddingLeft: '40',
  paddingRight: '40',
  grow: true,
})`
  position: relative;
`

const Footer: React.FC<{
  programId: string
  templateId?: string
}> = ({ programId, templateId }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const [isSaving, setIsSaving] = useState(false)
  const updateNewEmailTemplateIds = useSetAtom(newEmailTemplateIds)
  const atLeastElapsed = useAtLeastElapsed(10_000)

  const { outline, dispatch } = useOutlineReducer()

  const {
    setPanels: {
      addEmailNotification: { off: addEmailOff },
    },
  } = useOutlineEditPanels()
  const outlineLength = outline.steps.length
  const [isTestEmailSending, setTestEmailSending] = useState(false)
  const { emailTemplate, hasChanged, hasCorrectlyFilledTemplate, resetTemplate } = useEmailTemplate()
  const [sendTestEmailOpen, setSendTestEmailOpen] = useState(false)
  const [sendTestEmailWait, setSendSendTestEmailWait] = useState(0)
  const notifications = useNotif()
  const previousCompletionEnabled = getFlag('program-after-previous-completion')

  const save = useCallback(async (): Promise<void> => {
    setIsSaving(true)
    if (templateId === undefined) {
      // Create new template
      const newTemplate = await createEmailTemplateBindings({
        type: 'PROGRAM',
        forProgram: serializeTemplateAsBindingsInput(emailTemplate),
      })

      // Unused new email templates are added, so we can remove them if they are removed again
      updateNewEmailTemplateIds(ids => [
        ...ids,
        newTemplate.upsertEmailTemplateBindings.bindings.emailTemplateBindingsId,
      ])

      const { bindings } = newTemplate.upsertEmailTemplateBindings
      const emailStep = createEmailStep({
        resourceId: bindings.resourceId,
        emailTemplateBindingsId: bindings.emailTemplateBindingsId,
        title: bindings.headline,
        image: convertGQLImage(bindings.image),
        schedule:
          previousCompletionEnabled && outlineLength > 0 ? createTimeScheduleOnPreviousComplete() : undefined,
      })

      dispatch({ type: 'ADD_STEPS', steps: [emailStep] })
    } else {
      // Update old template
      const updatedTemplate = await updateEmailTemplateBindings({
        type: 'PROGRAM',
        id: templateId,
        forProgram: serializeTemplateAsBindingsInput(emailTemplate),
      })
      const { bindings } = updatedTemplate.upsertEmailTemplateBindings
      const updatedEmailStep = createEmailStep({
        resourceId: bindings.resourceId,
        emailTemplateBindingsId: bindings.emailTemplateBindingsId,
        title: bindings.headline,
        image: convertGQLImage(bindings.image),
      })

      dispatch({ type: 'UPDATE_STEP', updatedStep: updatedEmailStep })
    }

    resetTemplate()
    addEmailOff()
    setIsSaving(false)
  }, [
    templateId,
    resetTemplate,
    addEmailOff,
    emailTemplate,
    updateNewEmailTemplateIds,
    outlineLength,
    dispatch,
    previousCompletionEnabled,
  ])

  const sendTestEmail = async (): Promise<void> => {
    setTestEmailSending(true)

    await sendTestEmailTemplate({
      type: 'PROGRAM',
      forProgram: serializeTemplateAsRenderable(programId, emailTemplate),
    })

    notifications.push({
      type: 'custom',
      level: 'success',
      icon: 'send--filled',
      body: t('manage.program.program-details.steps.add-email.test-email-requested'),
    })

    setTestEmailSending(false)
    tracking.email.sendTestEmail(templateId)
  }

  const checkAllowSendTestEmail = (): void => {
    const checkTooFast = atLeastElapsed()
    if (isLeft(checkTooFast)) {
      setSendTestEmailOpen(true)
    } else {
      setSendSendTestEmailWait(checkTooFast.right.tryAgainInMs)
    }
  }

  return (
    <>
      <ProgramPanelCommon.InnerPanelFooterWrapper>
        <View>
          <Button
            variant='secondary'
            onClick={() => {
              addEmailOff()
              resetTemplate()
              if (templateId === undefined) {
                tracking.program.step.cancelSave()
              } else {
                tracking.program.step.cancelEdit(templateId)
              }
            }}
          >
            {t('dictionary.cancel')}
          </Button>
          <Tooltip
            title={
              !hasCorrectlyFilledTemplate
                ? t('manage.programs.emails.form-incorrectly-filled-description')
                : undefined
            }
          >
            <Button
              id='email-save'
              variant='primary'
              disabled={!hasChanged || isSaving || !hasCorrectlyFilledTemplate}
              loading={isSaving}
              onClick={save}
            >
              {t('dictionary.save')}
            </Button>
          </Tooltip>
        </View>
        <Button
          variant='secondary'
          loading={isTestEmailSending}
          disabled={isTestEmailSending}
          onClick={() => checkAllowSendTestEmail()}
        >
          {t('manage.program.program-details.steps.add-email.send-test-email')}
        </Button>
      </ProgramPanelCommon.InnerPanelFooterWrapper>
      <SendTestEmailAlert
        open={sendTestEmailOpen}
        onConfirm={sendTestEmail}
        onClose={() => setSendTestEmailOpen(false)}
        isLoading={isTestEmailSending}
      />
      <SendTestEmailRateLimitedAlert
        onClose={() => setSendSendTestEmailWait(0)}
        tryAfterMs={sendTestEmailWait}
      />
    </>
  )
}

const SideBySide = styled(View).attrs({ gap: '16' })`
  & > * {
    align-self: stretch;

    & button {
      height: 100%;
    }
  }
`
const linkOptions = (t: TranslationLookup): MenuItem[] => [
  {
    type: 'label',
    id: 'program-overview',
    label: t('manage.programs.emails.link-options.program-overview'),
    description: t('manage.programs.emails.link-options.program-overview-description'),
  },
  {
    type: 'label',
    id: 'custom-link',
    label: t('manage.programs.emails.link-options.custom-link'),
    description: t('manage.programs.emails.link-options.custom-link-description'),
  },
]

const Content: React.FC<{ programId: string }> = ({ programId }) => {
  const {
    panels: { addEmailNotificationOpen },
  } = useOutlineEditPanels()
  const { emailTemplate, updateEmailTemplate, previewEmailTemplate } = useEmailTemplate()
  const [selectedLinkOption, setSelectedLinkOption] = useState<MenuItem | undefined>(undefined)
  const [showImageModal, setShowImageModal] = useState(false)
  const { fetchRenderedEmail } = useRenderProgramEmail()
  const { t } = useTranslation()
  const isDebugMode = useIsDebugMode()

  const emailPlaceholders = useAtomValue(emailPlaceholdersAtom)
  const previewElement = useAtomValue(previewElementAtom)

  // update link option
  useEffect(() => {
    setSelectedLinkOption(emailTemplate.customButtonUrl === undefined ? linkOptions(t)[0] : linkOptions(t)[1])
  }, [emailTemplate, t])

  const sendUpdateToPreview = useCallback(
    (target: EmailPlaceholderKeys, value: EmailPlaceholderValues) => {
      if (previewElement === null) return

      const document = previewElement.contentDocument
      const element = document?.querySelector('[data-update-target="' + target + '"]')

      if (element === null || element === undefined) return
      element.innerHTML = value
    },
    [previewElement]
  )

  const handleImageChange = useCallback(
    (payload: ImageData['image'] | undefined): void => {
      updateEmailTemplate({ ...emailTemplate, image: payload })

      void (async (): Promise<void> => {
        await fetchRenderedEmail(programId, { ...previewEmailTemplate, image: payload })
      })()
    },
    [emailTemplate, fetchRenderedEmail, previewEmailTemplate, programId, updateEmailTemplate]
  )

  const handleUpdate = useCallback(
    (target: EmailPlaceholderKeys, value: EmailPlaceholderValues) => {
      updateEmailTemplate({ [target]: value })

      if (value === '') {
        value = emailPlaceholders[target]
      }

      sendUpdateToPreview(target, value)
    },
    [emailPlaceholders, sendUpdateToPreview, updateEmailTemplate]
  )

  const assetContext: AssetContext = useMemo(
    () => ({ type: 'program', programId: ProgramId.parse(programId) }),
    [programId]
  )
  const resolvedImageSrc = useResolveAsset({
    image: emailTemplate.image ?? undefined,
    assetContext,
    size: 'default',
  })
  const imageUrl = isNotNull(emailTemplate.image) ? resolvedImageSrc : undefined

  return (
    <View direction='column' justifyContent='flex-start' grow>
      <View direction='column' gap='24'>
        <FormElement label={t('manage.programs.emails.template.headline')}>
          <InputPrimitive
            id='email-headline-input'
            value={emailTemplate.headline}
            onChange={e => handleUpdate('headline', e.target.value)}
            placeholder={emailPlaceholders.headline}
          />
        </FormElement>
        <FormElement
          label={
            <View>
              <Text>{t('manage.programs.emails.template.description')}</Text>
              <Text size='micro' color='foreground/muted'>
                {t('input.optional')}
              </Text>
            </View>
          }
        >
          <FreeTextEditor
            inputId='email-description-input'
            key={addEmailNotificationOpen.toString()}
            content={emailTemplate.description}
            editable
            useHtml
            alignmentOptionsEnabled={true}
            textOptionsEnabled={false}
            strikethroughEnabled={false}
            underlineEnabled={false}
            placeholder={emailPlaceholders.description}
            onChange={content => handleUpdate('description', content)}
            menuTranslations={{
              list: t('dictionary.list'),
              alignment: t('create.toolbar.text-alignment'),
              text: t('dictionary.text'),
              heading: t('font.heading'),
            }}
          />
          {isDebugMode && (
            <TextAreaPrimitive
              value={emailTemplate.description}
              resize='vertical'
              rows={5}
              onChange={e => handleUpdate('description', e.target.value)}
              disabled
            />
          )}
        </FormElement>
        <SideBySide>
          <FormElement label={t('manage.programs.emails.template.button-text')}>
            <InputPrimitive
              id='email-button-text'
              value={emailTemplate.buttonText}
              onChange={e => handleUpdate('buttonText', e.target.value)}
              placeholder={emailPlaceholders.buttonText}
            />
          </FormElement>
          <FormElement label={t('manage.programs.emails.template.button-action')}>
            <SingleSelectDropdown
              selectedItem={selectedLinkOption}
              onSelect={item =>
                updateEmailTemplate({ customButtonUrl: item.id === 'custom-link' ? '' : undefined })
              }
              menuItems={linkOptions(t)}
            />
          </FormElement>
        </SideBySide>
        {emailTemplate.customButtonUrl !== undefined && (
          <FormElement grow label={t('manage.programs.emails.template.button-link')}>
            <InputPrimitive
              value={emailTemplate.customButtonUrl}
              onChange={e => updateEmailTemplate({ customButtonUrl: e.target.value })}
            />
          </FormElement>
        )}
        <FormElement grow label={t('manage.programs.emails.template.image')}>
          <ImageUploadControl image={imageUrl} onUpload={() => setShowImageModal(true)}>
            <UploadImageModal
              onUploadDone={handleImageChange}
              onClose={() => setShowImageModal(false)}
              open={showImageModal}
              assetContext={assetContext}
            />
          </ImageUploadControl>
        </FormElement>
      </View>
    </View>
  )
}

const NotificationEdit: React.FC<{ programId: string; templateId: string | undefined }> = ({
  programId,
  templateId,
}) => {
  useHideIntercom()
  const [isLoading, setIsLoading] = useState(templateId !== undefined)
  const { resetTemplate } = useEmailTemplate()
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(templateId !== undefined)
  }, [templateId])

  useEffect(() => {
    if (templateId === undefined) {
      resetTemplate()
    }
  }, [resetTemplate, templateId])

  const setEmailPlaceholders = useSetAtom(emailPlaceholdersAtom)

  useEffect(() => {
    setEmailPlaceholders({
      headline: t('manage.program.program-details.steps.add-email.placeholders.headline'),
      description: t('manage.program.program-details.steps.add-email.placeholders.description'),
      buttonText: t('manage.program.program-details.steps.add-email.placeholders.button-text'),
    })
  }, [setEmailPlaceholders, t])

  return (
    <>
      {templateId !== undefined && isLoading && (
        <TemplateDataLoader templateId={templateId} setIsLoading={setIsLoading} />
      )}
      {isLoading ? (
        <View direction='column' justifyContent='center' alignItems='center'>
          <LoadingSpinner size='xlarge' />
        </View>
      ) : (
        <>
          <Content programId={programId} />
          <Footer programId={programId} templateId={templateId} />
        </>
      )}
    </>
  )
}

const PreviewLayout = styled(View)<{ backgroundColor: string }>`
  background-color: ${p => p.backgroundColor};
  width: 100%;
  min-height: 350px;
  height: 400px;
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  border-bottom: 1px solid ${token('border/default')};
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  top: 0;
`

const StyledIframe = styled.iframe<{ backgroundColor: string }>`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  background-color: ${p => p.backgroundColor};
  overflow: hidden;
  transform-origin: top center;
  transform: scale(0.8);
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
`

const Preview: React.FC = () => {
  const [previewElement, setPreviewElement] = useAtom(previewElementAtom)
  const { renderedEmail } = useRenderProgramEmail()
  const { emailTemplate } = useEmailTemplate()

  const getToken = useTokenValue()
  const orgColor = getToken('org/primary')
  const lightColor = useLightColor(orgColor)

  const calculateHeight = useCallback(() => {
    if (previewElement === null || previewElement.contentWindow === null) return
    previewElement.style.height = '0'
    previewElement.style.height = previewElement.contentWindow.document.body.scrollHeight + 'px'
  }, [previewElement])

  useEffect(calculateHeight, [emailTemplate.description, calculateHeight])

  useEffect(() => {
    if (previewElement === null) return

    previewElement.onload = () => {
      calculateHeight()
    }

    const contentDocument = previewElement.contentDocument

    if (contentDocument !== null) {
      const htmlTag = contentDocument.querySelector('html')
      if (htmlTag !== null) {
        htmlTag.style.backgroundColor = lightColor // We do this to cover the "holes" in the iframe
      }
    }
  }, [previewElement, lightColor, orgColor, calculateHeight])

  return (
    renderedEmail !== null && (
      <StyledIframe
        ref={setPreviewElement}
        srcDoc={renderedEmail}
        backgroundColor={lightColor}
        scrolling='no'
      />
    )
  )
}

const PreviewSection: React.FC<{ programId: string }> = ({ programId }) => {
  const { initialEmailTemplate } = useEmailTemplate()
  const { fetchRenderedEmail } = useRenderProgramEmail()
  const previewElement = useAtomValue(previewElementAtom)

  const emailPlaceholders = useAtomValue(emailPlaceholdersAtom)

  useEffect(() => {
    const nonEmptyEmailTemplate = getNonEmptyEmailTemplate(emailPlaceholders, initialEmailTemplate)
    void (async (): Promise<void> => {
      await fetchRenderedEmail(programId, nonEmptyEmailTemplate)
    })()
  }, [emailPlaceholders, fetchRenderedEmail, initialEmailTemplate, previewElement, programId])

  const getToken = useTokenValue()
  const orgColor = getToken('org/primary')

  const lightPrimaryColor = useLightColor(orgColor)

  return (
    <PreviewLayout backgroundColor={lightPrimaryColor}>
      <Preview />
    </PreviewLayout>
  )
}

const BackButtonContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
`

export const AddEmailNotificationPanel: React.FC<{
  open: boolean
  programId: string
  templateId: string | undefined
}> = ({ open, templateId, programId }) => {
  const outlineEdit = useOutlineEditPanels()
  const { t } = useTranslation()

  const {
    setPanels: {
      addEmailNotification: { off: closePanel },
    },
  } = outlineEdit

  return (
    <PanelInPanel open={open} onEscapeKeyDown={closePanel}>
      <LightTokenProvider>
        <BackButtonContainer>
          <PanelInPanelCloseButton onClose={closePanel} label={t('dictionary.close')} />
        </BackButtonContainer>
      </LightTokenProvider>
      <PreviewSection programId={programId} />
      <PanelLayout>
        <NotificationEdit programId={programId} templateId={templateId} />
      </PanelLayout>
    </PanelInPanel>
  )
}
