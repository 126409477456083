import log from 'loglevel'
import React, { useEffect, useState } from 'react'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { config, getFlag } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import {
  getSkillsCountAndLevelsQuery,
  skillSettingsQuery,
  useSkillsFlagEnabled,
} from 'sierra-client/features/skills'
import { useLanguageItems } from 'sierra-client/hooks/use-language-items'
import { useRankedTags } from 'sierra-client/hooks/use-ordered-tags'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsAmazon } from 'sierra-client/state/organization/selectors'
import { selectAvatarColor, selectUser } from 'sierra-client/state/user/user-selector'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationForm } from 'sierra-client/views/authentication/authentication-layout'
import { Terms, getAuthMethodForEmail } from 'sierra-client/views/authentication/common'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { AuthenticationFooter } from 'sierra-client/views/authentication/native/components/authentication-footer'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { FileButton } from 'sierra-client/views/manage/components/file-button'
import { isLeft } from 'sierra-domain/either'
import {
  XRealtimeUserSetAccountSettings,
  XRealtimeUserSetRegistrationStep,
  XRealtimeUserUploadFile,
} from 'sierra-domain/routes'
import { UserRegistrationStep } from 'sierra-domain/user'
import { FormElement, RoundAvatar } from 'sierra-ui/components'
import { Button, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

const minContentOrTagsPerPage = 4
const minV2SkillsToActivateV2SkillsOnboarding = 1
export const AccountDetailsSetup: React.FC<unknown> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const orgConfig = config.organization
  const avatarColor = useSelector(selectAvatarColor)
  const isAmazon = useSelector(selectIsAmazon)
  const languageItems = useLanguageItems({ labelInItemLanguage: true })
  const hasV2Skills = useSkillsFlagEnabled()
  const nSkills =
    useGraphQuery({
      document: getSkillsCountAndLevelsQuery,
      queryOptions: { select: data => data.skills.totalCount },
    }).data ?? 0

  const hideV2Skills = useGraphQuery({ document: skillSettingsQuery }).data?.skillSettings.hideSkills ?? true

  const user = useSelector(selectUser)

  // For the profile page and other parts in the 'me' endpoint we default to the email if the firstName is undefined
  const [firstName, setFirstName] = useState<string>(
    user?.firstName === user?.email ? '' : user?.firstName ?? ''
  )
  const [firstNameError, setFirstNameError] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string>(user?.lastName ?? '')
  const [lastNameError, setLastNameError] = useState<string | undefined>(undefined)
  const [language, setLanguage] = useState<string | undefined>(orgConfig.defaultLanguage ?? 'en')
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  const tags = useRankedTags()

  const [avatarFile, setAvatarFile] = useState<string>()
  const { postWithUserErrorCode } = usePost()

  useEffect(() => {
    void dispatch(fetchTagsData())
  }, [dispatch])

  const authTerms = getFlag('auth/terms')
  const onboardingMode = getFlag('onboarding/mode')

  if (user === undefined) return null

  const submitDisabled = authTerms && !termsAccepted

  const authMethod = getAuthMethodForEmail(orgConfig.auth, user.email)

  if (authMethod === undefined) throw Error('No auth method for email')

  const isSaml = authMethod.type === 'saml'

  const isSamlAndProvisioning = authMethod.type === 'saml' && authMethod.provisioning

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFirstName(e.target.value)
    setFirstNameError(undefined)
  }

  const handleFamilyNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLastName(e.target.value)
    setLastNameError(undefined)
  }

  const handleLanguageChange = (value: string): void => {
    setLanguage(value)
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!e.target.files || e.target.files.length < 1) {
      log.debug('No file list available for upload')
      return
    }

    const result = await postWithUserErrorCode(XRealtimeUserUploadFile, {})

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    const blob = e.target.files[0]

    if (blob === undefined) {
      return
    }

    await fetch(result.right.namespacedUrl, {
      method: 'PUT',
      body: blob,
      headers: { 'Content-Type': blob.type },
    })

    setAvatarFile(`${result.right.fileId}.jpeg`)
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    setError(undefined)

    let errors = false

    if (!isSamlAndProvisioning) {
      if (!firstName) {
        errors = true
        setFirstNameError(t('create-account.errors.first-name'))
      } else {
        setFirstNameError(undefined)
      }

      if (!lastName) {
        errors = true
        setLastNameError(t('create-account.errors.last-name'))
      } else {
        setLastNameError(undefined)
      }
    }

    if (authTerms && !termsAccepted) {
      errors = true
    }

    if (errors) return

    const result1 = await postWithUserErrorCode(XRealtimeUserSetAccountSettings, {
      userAccountSettings: {
        firstName: isSamlAndProvisioning ? user.firstName : firstName,
        lastName: isSamlAndProvisioning ? user.lastName : lastName,
        avatar: avatarFile,
        language: language,
        tags: user.tags,
      },
      notificationSettings: user.notificationSettings,
    })

    if (isLeft(result1)) {
      setError(t(getUserErrorTranslationKey(result1.left)))
      return
    }

    let step: UserRegistrationStep
    const mode = onboardingMode

    if (
      hasV2Skills &&
      mode === 'new-skills-select' &&
      nSkills >= minV2SkillsToActivateV2SkillsOnboarding &&
      !hideV2Skills
    ) {
      step = 'select-new-skills'
    } else {
      if (mode === 'none') {
        step = 'done'
      } else if (
        // The roles-and-content mode is replaced with skills-and-content mode since we deleted roles onboarding
        (mode === 'auto' || mode === 'roles-and-content' || mode === 'skills-and-content') &&
        tags.length >= minContentOrTagsPerPage
      ) {
        step = 'skills'
      } else {
        step = 'done'
      }
    }

    const result2 = await postWithUserErrorCode(XRealtimeUserSetRegistrationStep, {
      step,
    })

    if (isLeft(result2)) {
      setError(t(getUserErrorTranslationKey(result2.left)))
      return
    }

    await Auth.getInstance().synchronize()
  }

  const selectedLanguageItem = languageItems.find(item => item.id === language)

  return (
    <AuthenticationContainer>
      <AuthenticationContent>
        <Text color='foreground/primary' size='small' align='center'>
          {t('create-account.complete-registration')}
        </Text>
        <AuthenticationForm onSubmit={submit}>
          <View direction='column' gap='xsmall'>
            {isAmazon && isSaml ? null : (
              <FormElement label={t('create-account.fields.email')}>
                <InputPrimitive type='text' value={user.email} onChange={() => {}} disabled />
              </FormElement>
            )}
            {isAmazon && isSaml ? null : isSamlAndProvisioning ? (
              <>
                <FormElement label={t('create-account.fields.first-name')}>
                  <InputPrimitive type='text' value={user.firstName} onChange={() => {}} disabled />
                </FormElement>
                <FormElement label={t('create-account.fields.last-name')}>
                  <InputPrimitive type='text' value={user.lastName} onChange={() => {}} disabled />
                </FormElement>
              </>
            ) : (
              <>
                <FormElement
                  label={t('create-account.fields.first-name')}
                  isError={firstNameError !== undefined}
                  helper={firstNameError}
                >
                  <InputPrimitive
                    type='text'
                    value={firstName}
                    autoComplete='given-name'
                    onChange={handleFirstNameChange}
                    autoFocus
                  />
                </FormElement>
                <FormElement
                  label={t('create-account.fields.last-name')}
                  isError={lastNameError !== undefined}
                  helper={lastNameError}
                >
                  <InputPrimitive
                    type='text'
                    value={lastName}
                    autoComplete='family-name'
                    onChange={handleFamilyNameChange}
                  />
                </FormElement>
              </>
            )}
            <FormElement label={t('create-account.preferred-language')}>
              <SingleSelectDropdown
                menuItems={languageItems}
                selectedItem={selectedLanguageItem}
                onSelect={item => handleLanguageChange(item.id)}
              />
            </FormElement>

            <FileButton
              label={
                avatarFile !== undefined
                  ? t('settings.fields.change-profile-image')
                  : t('settings.fields.add-a-profile-image')
              }
              type='file'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
              accept='image/png, image/jpeg'
              optionalLabel={avatarFile === undefined && t('create-account.optional')}
            >
              <RoundAvatar
                firstName={firstName}
                lastName={lastName}
                src={getAvatarImage(user.uuid, avatarFile)}
                color={avatarColor}
              />
            </FileButton>
            <Spacer size='xxsmall' />
            <Divider />
            {authTerms && <Terms setTermsAccepted={setTermsAccepted} termsAccepted={termsAccepted} />}
            {error !== undefined && (
              <Text size='micro' color='redBright'>
                {error}
              </Text>
            )}
            <Spacer size='xxsmall' />
            <Button variant='primary' type='submit' disabled={submitDisabled} grow>
              {t('create-account.continue')}
            </Button>
          </View>{' '}
        </AuthenticationForm>
      </AuthenticationContent>
      <AuthenticationFooter step={1} isShowing isDisabled={false} />
    </AuthenticationContainer>
  )
}
