import { getFlag } from 'sierra-client/config/global-config'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { Editor, Range } from 'slate'
import { ReactEditor } from 'slate-react'

export function onKeyDown(event: EditorKeyboardEvent, editor: Editor): void {
  if (!getFlag('editor-tabbing')) {
    return
  }

  const selection = editor.selection
  if (!selection || Range.isExpanded(selection) || event.key !== 'Escape') {
    return
  }

  event.preventDefault()
  ReactEditor.deselect(editor)
}
