import { capitalize, truncate } from 'lodash'
import React, {
  KeyboardEventHandler,
  ReactElement,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TimeSinceSent } from 'sierra-client/components/chat/chat-message/time-since-sent'
import { EmojiPickerPopupProvider } from 'sierra-client/components/common/emoji'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { getFlag } from 'sierra-client/config/global-config'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionSetShowReflectionCardSummary } from 'sierra-client/state/live-session/actions'
import { selectReflectionCardStates } from 'sierra-client/state/live-session/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useEditOrResponsesStateSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { AutoexpandingReflectionInput } from 'sierra-client/views/v3-author/reflection-card/atoms'
import { GroupingButton } from 'sierra-client/views/v3-author/reflection-card/grouping-button'
import { ReflectionsDataLayer } from 'sierra-client/views/v3-author/reflection-card/reflection-card-contexts'
import { useReflectionsData } from 'sierra-client/views/v3-author/reflection-card/reflection-card-data-layer'
import {
  ReflectionGroupDataLayer,
  useReflectionGroupData,
} from 'sierra-client/views/v3-author/reflection-card/reflection-group-data-layer'
import { SortButton } from 'sierra-client/views/v3-author/reflection-card/sort-button'
import { useIsReflectionsSummaryEnabled } from 'sierra-client/views/v3-author/reflection-card/use-is-reflections-summary-enabled'
import {
  ReflectionCardHeader,
  User,
  UserName,
  UserReflectionReponseMemo,
  UserWriting,
  useAnonymousUserData,
} from 'sierra-client/views/v3-author/reflection-card/user-reflection'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { UpdateReflectionsSummaryResponse } from 'sierra-domain/api/strategy-v2'
import { color } from 'sierra-ui/color'
import { Icon, MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Button, LoaderAnimation, Switch, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { LightTokenProvider, palette, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const StyledReflectionInput = styled.div`
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
`

const StyledReflectionInputInner = styled.div`
  font-size: 1rem;
  min-height: 200px;
  padding: 14px 20px 16px 20px;
  background-color: ${palette.primitives.white};
  color: ${palette.primitives.black};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
`

const UserNameWithPadding = styled(UserName)`
  padding: 8px;
`

let responseCache: Record<string, string> | undefined = undefined
const setResponseCache = (key: string, value: string): void => {
  responseCache = { [key]: value }
}

const getCachedInput = (key: string): string => {
  return responseCache?.[key] ?? ''
}

const ReflectionInput = forwardRef<HTMLDivElement, { allowSkip?: boolean }>(({ allowSkip = false }, ref) => {
  const { t } = useTranslation()
  const reflectionData = useReflectionsData()
  if (reflectionData.readOnly === true) throw new Error(`Can't add reflection in read only mode`)

  const { submitReflection, skip, anonymous, setAnonymous, setIsTypingReflection, reflectionId } =
    reflectionData
  const realUser = useSelector(selectUser)
  const [response, setResponse] = useState<string>(getCachedInput(reflectionId))

  const anonymousUser = useAnonymousUserData()
  const user = anonymous === true ? anonymousUser : realUser

  const handleResponseChanged = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = e.target.value
    const res = truncate(value, { length: 5000, omission: '' })
    setResponseCache(reflectionId, res)
    setResponse(res)
  }

  const handleSubmit = (): void => {
    if (response.length === 0) return
    submitReflection(response)
    setResponseCache(reflectionId, '')
    setResponse('')
  }

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    event.stopPropagation()
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  useEffect(() => {
    if (!setIsTypingReflection) return

    if (response.length > 0) {
      setIsTypingReflection(true)
      const timeout = setTimeout(() => setIsTypingReflection(false), 5000)
      return () => clearTimeout(timeout)
    } else {
      setIsTypingReflection(false)
    }
  }, [response, setIsTypingReflection])

  const menuItems: MenuItem<'not-anonymous' | 'anonymous'>[] = [
    {
      id: 'not-anonymous',
      type: 'label',
      label: t('learner.reflection-card.post-as-yourself'),
      selected: anonymous === false,
    },
    {
      id: 'anonymous',
      type: 'label',
      selected: anonymous === true,
      label: t('learner.reflection-card.post-anonymously'),
    },
  ]

  const selectedItem =
    anonymous === true
      ? menuItems.find(item => item.id === 'anonymous')
      : menuItems.find(item => item.id === 'not-anonymous')

  return (
    <StyledReflectionInput ref={ref}>
      <LightTokenProvider>
        <StyledReflectionInputInner>
          <ReflectionCardHeader>
            <User $isMe>
              <RoundAvatar
                size='small'
                firstName={user?.firstName}
                lastName={user?.lastName}
                src={getAvatarImage(user?.uuid, user?.avatar)}
                color={user?.avatarColor}
              />
              {setAnonymous ? (
                <SingleSelectDropdown
                  bold
                  selectedItem={selectedItem}
                  menuItems={menuItems}
                  variant='ghost'
                  onSelect={item => (item.id === 'anonymous' ? setAnonymous(true) : setAnonymous(false))}
                />
              ) : (
                <UserNameWithPadding>
                  {anonymous === true ? anonymousUser.firstName : capitalize(t('dictionary.you'))}
                </UserNameWithPadding>
              )}
            </User>
          </ReflectionCardHeader>
          <AutoexpandingReflectionInput
            placeholder={t('learner.reflection-card.placeholder')}
            title='Add a reflection...'
            value={response}
            onKeyDown={handleKeyPress}
            onChange={handleResponseChanged}
          />
          <View marginTop={'auto'} justifyContent='flex-end' gap='8'>
            {allowSkip && (
              <Button variant='secondary' onClick={skip}>
                {t('dictionary.skip')}
              </Button>
            )}
            <Button disabled={response.length === 0} onClick={handleSubmit}>
              {t('dictionary.post')}
            </Button>
          </View>
        </StyledReflectionInputInner>
      </LightTokenProvider>
    </StyledReflectionInput>
  )
})

const GRID_ROW_SIZE = 1
const GRID_GAP = 10

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 0 ${GRID_GAP}px;
  grid-auto-rows: ${GRID_ROW_SIZE}px;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    grid-template-columns: unset;
  }
`

const Cell: React.FC<{ contents: ReactElement<{ ref?: (ref: HTMLDivElement | null) => void }> }> = ({
  contents,
}) => {
  const [spans, setSpans] = useState(0)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const updateSize: ResizeObserverCallback = useCallback(entries => {
    requestAnimationFrame(() => {
      const [entry] = entries
      if (entry === undefined) return

      const height = entry.target.clientHeight
      const spans = Math.ceil(height / GRID_ROW_SIZE)

      // enusre some padding between the rows of the cells
      const padding = GRID_GAP

      setSpans(spans + padding)
    })
  }, [])

  useEffect(() => {
    if (ref === null) return

    const observer = new ResizeObserver(updateSize)
    observer.observe(ref)

    return () => observer.disconnect()
  }, [ref, updateSize])

  const handleRef = useCallback((ref: HTMLDivElement | null) => {
    setRef(ref)
  }, [])

  const style = useMemo(
    () => ({
      gridRowEnd: `span ${spans}`,
    }),
    [spans]
  )
  const contentElement = useMemo(() => cloneElement(contents, { ref: handleRef }), [contents, handleRef])

  // eslint-disable-next-line react/forbid-dom-props
  return <div style={style}>{contentElement}</div>
}

const SummaryContainer = styled(View).attrs({ direction: 'column' })`
  border-radius: 12px;
  padding: 24px;
  padding-bottom: 32px;
  gap: 24px;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 6px 12px 0px rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(82px);
  background: linear-gradient(
    145deg,
    hsla(
        ${p => {
          const colorPrimaryAsHsla = color(p.theme.home.backgroundColor).toHsla()
          const lightPrimaryColor = color({
            h: colorPrimaryAsHsla.h,
            s: colorPrimaryAsHsla.s + 20,
            l: (colorPrimaryAsHsla.l = 90),
            a: (colorPrimaryAsHsla.a = 1),
          }).toHsla()
          return `${lightPrimaryColor.h}, ${lightPrimaryColor.s}%`
        }},
        100%,
        1
      )
      0%,
    hsla(
        ${p => {
          const colorPrimaryAsHsla = color(p.theme.home.backgroundColor).toHsla()
          const lightPrimaryColor = color({
            h: colorPrimaryAsHsla.h,
            s: colorPrimaryAsHsla.s + 20,
            l: (colorPrimaryAsHsla.l = 90),
            a: (colorPrimaryAsHsla.a = 1),
          }).toHsla()
          return `${lightPrimaryColor.h}, ${lightPrimaryColor.s}%`
        }},
        97%,
        0.85
      )
      50%,
    hsla(
        ${p => {
          const colorPrimaryAsHsla = color(p.theme.home.backgroundColor).toHsla()
          const lightPrimaryColor = color({
            h: colorPrimaryAsHsla.h,
            s: colorPrimaryAsHsla.s + 20,
            l: (colorPrimaryAsHsla.l = 90),
            a: (colorPrimaryAsHsla.a = 1),
          }).toHsla()
          return `${lightPrimaryColor.h}, ${lightPrimaryColor.s}%`
        }},
        96%,
        0.75
      )
      100%
  );

  & li > span {
    position: relative;
    left: -8px;
  }
`

const UnorderedList = styled.ul`
  list-style: none;
  text-indent: -15px;

  ul li::before,
  &::before {
    color: ${p => p.theme.home.textColor};
    position: absolute;
    left: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledListItem = styled.li<{ $muted?: boolean }>`
  margin-left: 24px;
  font-size: 16px;
  color: ${p => (p.$muted === true ? 'rgba(0, 0, 0, 0.50)' : 'black')};

  &::before {
    content: '•  ';
    color: ${p => (p.$muted === true ? 'rgba(0, 0, 0, 0.50)' : 'black')};
  }
`

const StyledButton = styled.button`
  align-self: flex-end;
  width: 100%;
  font-size: 14px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 6px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }
`

const SummaryCellContentLive: React.FC<{
  reflectionId: string
  summary: UpdateReflectionsSummaryResponse['summary']
  updatedAt: UpdateReflectionsSummaryResponse['updatedAt']
  loading: boolean
  newReflections: number
  onUpdate: () => void
}> = forwardRef<
  HTMLDivElement,
  {
    reflectionId: string
    summary: UpdateReflectionsSummaryResponse['summary']
    updatedAt: UpdateReflectionsSummaryResponse['updatedAt']
    loading: boolean
    newReflections: number
    onUpdate: () => void
  }
>(({ reflectionId, summary, updatedAt, loading, newReflections, onUpdate }, ref) => {
  const { t } = useTranslation()
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const isFacilitator = useIsFacilitatorOrLearnerLedSession()

  const dispatch = useDispatch()
  const liveReflectionCardStates = useSelector(selectReflectionCardStates) ?? {}
  const showSummaryForAll = liveReflectionCardStates[reflectionId]?.showSummary ?? false

  const hideSummary =
    (isFacilitatorOrLearnerLedSession && !isFacilitator) ||
    (!isFacilitatorOrLearnerLedSession && !showSummaryForAll)

  if (hideSummary) return null

  const newReflectionsText = `${newReflections} new reflections` // todo: translat

  const toggleShowSummaryForAll = (): void => {
    dispatch(
      liveSessionSetShowReflectionCardSummary({
        reflectionId,
        showSummary: !showSummaryForAll,
      })
    )
  }

  return (
    <AppThemeTokenProvider>
      <SummaryContainer ref={ref}>
        <View justifyContent='space-between'>
          <View direction='row' gap='8'>
            {loading ? (
              <LoaderAnimation color='black' size={18} />
            ) : (
              <Icon iconId='sana-symbol' color='black' />
            )}
            <Text bold color='black'>
              {t('dictionary.summary')}
            </Text>
          </View>
          {isFacilitatorOrLearnerLedSession && (
            <LightTokenProvider>
              <Switch
                ariaLabel='Toggle summary visibility'
                textPos='left'
                size='small'
                text={
                  showSummaryForAll
                    ? t('learner.reflection-card.summary.toggle.visible')
                    : t('learner.reflection-card.summary.toggle.hidden')
                }
                checked={showSummaryForAll}
                onChange={toggleShowSummaryForAll}
              />
            </LightTokenProvider>
          )}
        </View>
        {!loading && (
          <>
            <Text size='regular' color={showSummaryForAll ? 'black' : 'foreground/secondary'}>
              {summary?.shortSummary}
            </Text>
            {summary?.categories.map((category, index) => {
              const { heading, bullets } = category
              return (
                <View key={`reflection_category_${index}`} gap='8' direction='column'>
                  <Text bold size='regular' color={showSummaryForAll ? 'black' : 'foreground/secondary'}>
                    {heading}
                  </Text>
                  <UnorderedList>
                    {bullets.map(bullet => (
                      <StyledListItem key={bullet} $muted={!showSummaryForAll}>
                        {bullet}
                      </StyledListItem>
                    ))}
                  </UnorderedList>
                </View>
              )
            })}
            <Text size='micro' color='foreground/secondary'>
              Updated <TimeSinceSent timestamp={updatedAt} />
            </Text>
            {isFacilitatorOrLearnerLedSession && newReflections > 0 && (
              <>
                <StyledButton onClick={onUpdate}>
                  <Icon iconId='rotate--360' color='black' />
                  <Text bold color='black'>
                    {t('dictionary.update')}
                  </Text>
                  <Text size='micro' color='black'>
                    {newReflectionsText}
                  </Text>
                </StyledButton>
              </>
            )}
          </>
        )}
      </SummaryContainer>
    </AppThemeTokenProvider>
  )
})

const LiveSessionReflectionSummary: React.FC<{
  reflectionId: string
  reflectionsSummary: ReflectionsDataLayer['reflectionsSummary']
  totalReflectionsCount: number
}> = ({ reflectionId, reflectionsSummary, totalReflectionsCount }) => {
  const editOrResponsesState = useEditOrResponsesStateSafe()
  const liveReflectionCardStates = useSelector(selectReflectionCardStates) ?? {}
  const isFacilitator = useIsFacilitatorOrLearnerLedSession()
  const showSummaryForAll = liveReflectionCardStates[reflectionId]?.showSummary ?? false

  const showSummary =
    reflectionsSummary.summaryEnabledInEditor &&
    totalReflectionsCount > 4 &&
    editOrResponsesState?.type !== 'responses' &&
    (isFacilitator || showSummaryForAll)

  if (!showSummary) return null

  return (
    <Cell
      contents={
        <SummaryCellContentLive
          reflectionId={reflectionId}
          onUpdate={() => reflectionsSummary.updateSummary()}
          summary={reflectionsSummary.summary}
          updatedAt={reflectionsSummary.updatedAt}
          newReflections={totalReflectionsCount - (reflectionsSummary.responseIds?.length ?? 0)}
          loading={
            reflectionsSummary.loading ||
            (totalReflectionsCount > 4 && reflectionsSummary.summary === undefined)
          }
        />
      }
    />
  )
}

const BasicMaisonryGrid: React.FC<{ mode: EditorMode }> = ({ mode }): JSX.Element => {
  const isReflectionSummaryEnabled = useIsReflectionsSummaryEnabled()
  const reflectionData = useReflectionsData()
  const {
    readOnly,
    reflectionId,
    responses: _responses,
    totalReflectionsCount,
    usersTyping,
    userHasResponded,
    reflectionsSummary,
  } = reflectionData
  const responses = _responses ?? []
  const hasMore = responses.length !== totalReflectionsCount
  const showResponses = readOnly === true || userHasResponded === true
  const isLiveSession = mode === 'live'

  return (
    <GridContainer>
      {readOnly !== true && (
        <Cell contents={<ReflectionInput allowSkip={userHasResponded === false} />}></Cell>
      )}
      {showResponses && (
        <>
          {isReflectionSummaryEnabled && isLiveSession && (
            <LiveSessionReflectionSummary
              reflectionId={reflectionId}
              reflectionsSummary={reflectionsSummary}
              totalReflectionsCount={totalReflectionsCount}
            />
          )}
          {responses
            .filter(response => response.skipped !== true)
            .map(response => (
              <Cell
                key={response.id}
                contents={
                  <UserReflectionReponseMemo
                    reflectionId={reflectionId}
                    reflectionResponse={response}
                    editResponse={readOnly === true ? undefined : reflectionData.editResponse}
                    reactToResponse={readOnly === true ? undefined : reflectionData.reactToResponse}
                    unReactToResponse={readOnly === true ? undefined : reflectionData.unReactToResponse}
                    deleteReflection={readOnly === true ? undefined : reflectionData.deleteReflection}
                    submitReflectionAnswer={
                      readOnly === true ? undefined : reflectionData.submitReflectionAnswer
                    }
                    editReflectionAnswer={readOnly === true ? undefined : reflectionData.editReflectionAnswer}
                    deleteReflectionAnswer={
                      readOnly === true ? undefined : reflectionData.deleteReflectionAnswer
                    }
                    allowAnonymity={
                      readOnly === true ? undefined : reflectionData.setAnonymous !== undefined ? true : false
                    }
                  />
                }
              />
            ))}
          {!hasMore &&
            usersTyping.map(({ userId, anonymous }) => (
              <Cell
                key={userId}
                contents={<UserWriting key={userId} userId={userId} anonymous={anonymous} />}
              />
            ))}
        </>
      )}
    </GridContainer>
  )
}

const GroupView = styled(View).attrs({ direction: 'column', padding: '16', radius: 'size-16' })`
  background-color: ${token('foreground/primary').opacity(0.1)};
`

const BasicMaisonryGridWithGroups: React.FC = (): JSX.Element => {
  const reflectionData = useReflectionsData()
  const {
    readOnly,
    reflectionId,
    responses: _responses,
    totalReflectionsCount,
    usersTyping,
    userHasResponded,
  } = reflectionData
  const responses = _responses ?? []
  const hasMore = responses.length !== totalReflectionsCount
  const showResponses = readOnly === true || userHasResponded === true

  return (
    <GridContainer>
      {readOnly !== true && (
        <Cell contents={<ReflectionInput allowSkip={userHasResponded === false} />}></Cell>
      )}
      {showResponses && (
        <>
          {reflectionData.buckets?.map(bucket => {
            return (
              <Cell
                key={bucket.bucketName}
                contents={
                  <GroupView key={bucket.bucketName}>
                    <View paddingLeft='8' paddingBottom='4'>
                      <Text bold size='small'>
                        {bucket.bucketName}
                      </Text>
                    </View>
                    {responses
                      .filter(response => response.skipped !== true)
                      .filter(response => bucket.responseIds.includes(response.id))
                      .map(response => {
                        return (
                          <UserReflectionReponseMemo
                            key={response.id}
                            reflectionId={reflectionId}
                            reflectionResponse={response}
                            editResponse={readOnly === true ? undefined : reflectionData.editResponse}
                            reactToResponse={readOnly === true ? undefined : reflectionData.reactToResponse}
                            unReactToResponse={
                              readOnly === true ? undefined : reflectionData.unReactToResponse
                            }
                            deleteReflection={readOnly === true ? undefined : reflectionData.deleteReflection}
                            submitReflectionAnswer={
                              readOnly === true ? undefined : reflectionData.submitReflectionAnswer
                            }
                            editReflectionAnswer={
                              readOnly === true ? undefined : reflectionData.editReflectionAnswer
                            }
                            deleteReflectionAnswer={
                              readOnly === true ? undefined : reflectionData.deleteReflectionAnswer
                            }
                            allowAnonymity={
                              readOnly === true
                                ? undefined
                                : reflectionData.setAnonymous !== undefined
                                  ? true
                                  : false
                            }
                          />
                        )
                      })}
                  </GroupView>
                }
              />
            )
          })}
          {responses
            .filter(response => response.skipped !== true)
            .filter(response =>
              reflectionData.buckets !== undefined
                ? !reflectionData.buckets.flatMap(bucket => bucket.responseIds).includes(response.id)
                : true
            )
            .map(response => (
              <Cell
                key={response.id}
                contents={
                  <View paddingLeft='16' paddingRight='16'>
                    <UserReflectionReponseMemo
                      reflectionId={reflectionId}
                      reflectionResponse={response}
                      editResponse={readOnly === true ? undefined : reflectionData.editResponse}
                      reactToResponse={readOnly === true ? undefined : reflectionData.reactToResponse}
                      unReactToResponse={readOnly === true ? undefined : reflectionData.unReactToResponse}
                      deleteReflection={readOnly === true ? undefined : reflectionData.deleteReflection}
                      submitReflectionAnswer={
                        readOnly === true ? undefined : reflectionData.submitReflectionAnswer
                      }
                      editReflectionAnswer={
                        readOnly === true ? undefined : reflectionData.editReflectionAnswer
                      }
                      deleteReflectionAnswer={
                        readOnly === true ? undefined : reflectionData.deleteReflectionAnswer
                      }
                      allowAnonymity={
                        readOnly === true
                          ? undefined
                          : reflectionData.setAnonymous !== undefined
                            ? true
                            : false
                      }
                    />
                  </View>
                }
              />
            ))}
          {!hasMore &&
            usersTyping.map(({ userId, anonymous }) => (
              <Cell
                key={userId}
                contents={<UserWriting key={userId} userId={userId} anonymous={anonymous} />}
              />
            ))}
        </>
      )}
    </GridContainer>
  )
}

const ReflectionsWithGroupDataLayer: React.FC<{ mode: EditorMode }> = ({ mode }) => {
  const { t } = useTranslation()

  const { responses, loadMore, totalReflectionsCount, userHasResponded } = useReflectionsData()

  const groupingEnabled = getFlag('smart-group-reflections')
  const { groupBy } = useReflectionGroupData()

  const hasMore = responses !== undefined && responses.length !== totalReflectionsCount
  const isMobile = useIsMobile()

  return (
    <>
      <View
        padding='xsmall none'
        justifyContent='space-between'
        alignItems={isMobile ? 'flex-start' : 'center'}
        direction={isMobile ? 'column' : 'row'}
      >
        <Text color='foreground/primary' bold size='small'>
          {t('dictionary.reflection-plural')}
        </Text>
        <View>
          <GroupingButton />
          <SortButton />
        </View>
      </View>

      {groupingEnabled && groupBy === 'smart' ? (
        <BasicMaisonryGridWithGroups />
      ) : (
        <BasicMaisonryGrid mode={mode} />
      )}
      {hasMore && userHasResponded === true && (
        <View justifyContent='center' alignItems='center' margin='small none'>
          <Button variant='ghost' icon='add' onClick={loadMore}>
            {t('dictionary.show-more')}
          </Button>
        </View>
      )}
    </>
  )
}

export const LearnerReflections: React.FC<{ mode: EditorMode }> = ({ mode }) => {
  const { reflectionId } = useReflectionsData()

  return (
    <EmojiPickerPopupProvider>
      <ReflectionGroupDataLayer reflectionId={reflectionId}>
        <ReflectionsWithGroupDataLayer mode={mode} />
      </ReflectionGroupDataLayer>
    </EmojiPickerPopupProvider>
  )
}
