import { config } from 'sierra-client/config/global-config'
import { errorLogger } from 'sierra-client/error/error-logger'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId, nameUUIDFromString } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion, MediaLibraryImage } from 'sierra-domain/content/v2/content'
import { assertNever, iife, isDefined, isUUID } from 'sierra-domain/utils'

export const MEDIA_LIBRARY_BUCKET = `${config.organization.bucketPrefix}-media-library`

type GoogleStorageBucket =
  | 'sierra-files'
  | 'sierra-static'
  | 'sierra-uploads'
  | 'sierra-document-uploads'
  | 'sierra-homework-uploads'

type GetFileContentOptions = {
  bucket?: GoogleStorageBucket
}

type GetFileContentImageOptions = GetFileContentOptions &
  (
    | {
        optimize: false
      }
    | {
        width?: number
        optimize?: true
        crop?: 'limit'
        invert?: boolean
        format?: 'png' | 'jpg'
        blur?: number
      }
  )

const googleStorageBaseUrl = 'https://storage.googleapis.com/' as const

export const isUrlGoogleStorageBucket = (url: string, bucket: GoogleStorageBucket): boolean => {
  return url.startsWith(`${googleStorageBaseUrl}${bucket}`)
}

export function getCloudinaryUrlFromFileContentVideo(fileContentVideo: string): string {
  const isCLoudinaryUrl = fileContentVideo.startsWith('https://res.cloudinary.com/sana-ai/video/upload/')
  if (isCLoudinaryUrl) {
    return fileContentVideo
  }

  const videoUrl = fileContentVideo.replace(googleStorageBaseUrl, '')
  // The order of the transformation parameters are important.
  // Do not change them without corresponding changes in the backend
  const cloudinaryUrl = `https://res.cloudinary.com/sana-ai/video/upload/f_mp4,q_auto,vc_auto/${videoUrl}`
  return cloudinaryUrl
}

export function getFileContentVideo(
  name: string,
  orgId: string,
  options: GetFileContentOptions = {}
): string {
  const bucket = options.bucket ?? 'sierra-files'
  return `https://storage.googleapis.com/${bucket}/${orgId}/${name}`
}

export type AssetType = 'image' | 'video' | 'raw'

export const FakeCdnPrefix = 'https://fake-cdn.sanalabs.com/'

// Temporary support for overrides until we've solved copy-pasting of assets across courses/tenants/regions
type GetNamespacedImageOverrides = {
  tenantId?: string
  bucket?: string
}

export function fileIdAsUUID(fileId: string): string {
  // Before October 2024, fileId could be either a UUID, NanoId12, or an arbitrary path.
  // The data has been rewritten to namespaced location using only UUIDs, but the file ids in content
  // have not been updated.
  if (isUUID(fileId)) {
    return fileId
  }

  // Images that are used with PDF generative features are prefixed with a fake CDN URL
  // The URL does not point to a valid image, but is there to make the LLM more likely
  // to output the image in the generated HTML.
  //
  // Stripping the prefix here to get the UUID
  if (fileId.startsWith(FakeCdnPrefix)) {
    const fileIdWithoutPrefix = fileId.slice(FakeCdnPrefix.length)
    if (isUUID(fileIdWithoutPrefix)) {
      return fileIdWithoutPrefix
    }
  }

  return nameUUIDFromString(fileId)
}

export function getNamespacedImage(
  assetContext: AssetContext,
  fileId: string,
  assetType: AssetType,
  options: GetFileContentImageOptions = {},
  overrides: GetNamespacedImageOverrides = {}
): string {
  const parameters: string[] = []

  if (options.optimize !== false && assetType === 'image') {
    const crop = options.crop ?? 'limit'
    const width = options.width ?? 1536
    const invert = options.invert ?? false
    parameters.push('q_auto:best', 'f_auto')

    if (width) {
      parameters.push(`w_${width}`)
    }

    if (window.devicePixelRatio >= 2) {
      parameters.push('dpr_2.0')
    }

    switch (crop) {
      case 'limit':
        parameters.push('c_limit')
        break
    }

    if (invert) {
      parameters.push('e_negate')
    }

    if (isDefined(options.format)) {
      parameters.push(`f_${options.format}`)
    }

    if (isDefined(options.blur)) {
      parameters.push(`e_blur:${options.blur}`)
    }
  }

  const tenantId = overrides.tenantId ?? config.organization.tenantId

  const bucket = iife(() => {
    if (overrides.bucket !== undefined) {
      return overrides.bucket
    }

    if (assetContext.type === 'courseTemplate') {
      return 'sierra-static'
    }

    return `${config.organization.bucketPrefix}-assets`
  })

  const filePath = iife(() => {
    if (assetContext.type === 'course')
      return `${tenantId}/courses/${assetContext.courseId}/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'program')
      return `${tenantId}/programs/${assetContext.programId}/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'path')
      return `${tenantId}/paths/${assetContext.pathId}/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'certificate') return `${tenantId}/certificates/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'courseTemplate')
      return `template-asset/${assetContext.templateId}/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'organization-settings')
      return `${tenantId}/organization-settings/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'tag') return `${tenantId}/tags/${fileIdAsUUID(fileId)}`
    if (assetContext.type === 'pdf-image') {
      return `${tenantId}/pdf-images/${assetContext.pdfId}/${fileIdAsUUID(fileId)}`
    }

    throw new Error('Unsupported asset context')
  })

  const args = iife(() => {
    switch (assetType) {
      case 'image':
        return `image/upload/${parameters.join()}${parameters.length > 0 ? '/' : ''}`
      case 'video':
        return `video/upload/f_mp4,q_auto,vc_auto/`
      case 'raw':
        return `raw/upload/`
      default:
        assertNever(assetType)
    }
  })

  return `https://res.cloudinary.com/sana-ai/${args}${bucket}/${filePath}`
}

export type ParsedCloudinaryUrlResponse =
  | {
      parsed: false
    }
  | {
      parsed: true
      type: 'image' | 'video' | 'raw'
      assetContext: AssetContext
      fileId: string
      tenantId: string
      bucket: string
    }

export const parseCloudinaryUrl = (url: string): ParsedCloudinaryUrlResponse => {
  if (!url.startsWith('https://res.cloudinary.com/sana-ai/')) {
    return { parsed: false }
  }

  const path = url.replace('https://res.cloudinary.com/sana-ai/', '')
  const parts = path.split('/')

  if (parts.length < 5) {
    return { parsed: false }
  }

  const [assetType, cloudinaryType, ...rest] = parts

  if (cloudinaryType !== 'upload') {
    return { parsed: false }
  }
  if (assetType !== 'image' && assetType !== 'video' && assetType !== 'raw') {
    return { parsed: false }
  }

  // Skip paths until it starts with "sierra-" which is always the bucket prefix
  const bucketPrefixIndex = rest.findIndex(part => part.startsWith('sierra-'))
  if (bucketPrefixIndex === -1) {
    return { parsed: false }
  }

  const publicId = rest.slice(bucketPrefixIndex)

  if (publicId.length < 2) {
    return { parsed: false }
  }

  const [bucket, tenantId, ...filePath] = publicId

  if (bucket === undefined) {
    return { parsed: false }
  }

  if (tenantId === undefined) {
    return { parsed: false }
  }

  if (filePath.length < 2) {
    return { parsed: false }
  }

  const [assetContextType, ...assetContextId] = filePath

  if (assetContextId.length < 2) {
    return { parsed: false }
  }

  // Can only handle courses for now
  if (assetContextType === 'courses') {
    return {
      parsed: true,
      type: assetType as 'image' | 'video' | 'raw',
      assetContext: {
        type: 'course',
        courseId: CourseId.parse(assetContextId[0]),
      },
      fileId: assetContextId[1] as string,
      tenantId,
      bucket,
    }
  }

  return { parsed: false }
}

export function getFileContentImage(name: string, options: GetFileContentImageOptions = {}): string {
  const bucket = options.bucket ?? 'sierra-files'

  if (options.optimize !== false) {
    const crop = options.crop ?? 'limit'
    const width = options.width ?? 1536
    const invert = options.invert ?? false
    const parameters = ['q_auto:best', 'f_auto']

    if (width) {
      parameters.push(`w_${width}`)
    }

    if (window.devicePixelRatio >= 2) {
      parameters.push('dpr_2.0')
    }

    switch (crop) {
      case 'limit':
        parameters.push('c_limit')
        break
    }

    if (invert) {
      parameters.push('e_negate')
    }

    if (isDefined(options.format)) {
      parameters.push(`f_${options.format}`)
    }

    return `https://res.cloudinary.com/sana-ai/image/fetch/${parameters.join()}/https://storage.googleapis.com/${bucket}/${name}`
  } else {
    return `https://storage.googleapis.com/${bucket}/${name}`
  }
}

export function getMediaLibraryImageUrl(image: MediaLibraryImage, options: { width?: number } = {}): string {
  const width = options.width ?? 1536
  const parameters = [`w_${width}`, 'q_auto:best', 'f_auto', 'c_limit']

  if (window.devicePixelRatio >= 2) {
    parameters.push('dpr_2.0')
  }

  return `https://res.cloudinary.com/sana-ai/image/upload/${parameters.join(',')}/${MEDIA_LIBRARY_BUCKET}/${
    image.file
  }`
}

export function getMediaLibraryImagePlaceholderUrl(image: MediaLibraryImage): string {
  const parameters = ['w_200', 'e_blur:2000']
  return `https://res.cloudinary.com/sana-ai/image/upload/${parameters.join(',')}/${MEDIA_LIBRARY_BUCKET}/${
    image.file
  }`
}

export function getPlaceholderImageUrl(url: string): string {
  const parameters = ['w_200', 'e_blur:2000']
  return `https://res.cloudinary.com/sana-ai/image/fetch/${parameters.join(',')}/${encodeURIComponent(url)}`
}

type GetUnsplashContentImageOptions = {
  width?: number
}

export function getUnsplashPreviewImage(href: string): string {
  const url = new URL(href)

  url.searchParams.set('crop', 'entropy')
  url.searchParams.set('cs', 'tinysrgb')
  url.searchParams.set('ixlib', 'rb-4.0.3')
  url.searchParams.set('fit', 'max')
  url.searchParams.set('fm', 'jpg')
  url.searchParams.delete('auto')
  url.searchParams.set('q', '80')
  url.searchParams.set('w', '400')

  return url.href
}

export function getUnsplashContentImage(href: string, options: GetUnsplashContentImageOptions = {}): string {
  const width = options.width ?? 1536

  const url = new URL(href)

  const dpr = window.devicePixelRatio >= 2 ? 2 : 1

  url.searchParams.delete('fm')
  url.searchParams.set('auto', 'format')
  url.searchParams.set('q', '50')
  url.searchParams.set('crop', 'edges')
  url.searchParams.set('fit', 'max')
  url.searchParams.set('dpr', `${dpr}`)
  url.searchParams.set('w', `${width}`)

  return url.href
}

export function resolveUserAvatar<UserLike extends { uuid: UserId; avatar?: string }>(
  user: UserLike
): UserLike {
  return {
    ...user,
    avatar: getAvatarImage(user.uuid, user.avatar),
  }
}

export function getFileContent(name: string, options: GetFileContentImageOptions = {}): string {
  const bucket = options.bucket ?? 'sierra-files'

  return `https://storage.googleapis.com/${bucket}/${name}`
}

export type ImageSizeType = keyof typeof imageSizeTypes
const imageSizeTypes = {
  'course': { width: 600 },
  'course-sm': { width: 300 },
  'path': { width: 600 },
  'path-sm': { width: 300 },
  'admin': { width: 160 },
  'admin-sm': { width: 80 },
  'default': { width: 1536 },
  'default-sm': { width: 600 },
  'content-card': { width: 420 },
  'page-card-center': { width: 1024 },
  'thumbnail': { width: 70 },
}

export const getCourseDefaultImage = (options?: { width: number }): string =>
  getFileContentImage('images/courseDefault.png', { bucket: 'sierra-static', width: options?.width })

const fallbackResolveCourseImage = (image: ImageUnion | undefined, size: ImageSizeType): string => {
  const parameters = ['q_auto:best', 'f_auto', 'c_lfill'] // 'g_center' is default, might want 'g_auto'
  const { width } = imageSizeTypes[size]

  switch (image?.type) {
    case 'file':
      if (window.devicePixelRatio >= 2) {
        parameters.push('dpr_2.0')
      }

      return `https://res.cloudinary.com/sana-ai/image/fetch/w_${width},${parameters.join()}/https://storage.googleapis.com/sierra-files/${
        image.file
      }`
    case 'media-library-image':
      if (window.devicePixelRatio >= 2) {
        parameters.push('dpr_2.0')
      }

      return `https://res.cloudinary.com/sana-ai/image/upload/w_${width},${parameters.join()}/${MEDIA_LIBRARY_BUCKET}/${
        image.file
      }`
    case 'unsplash':
      return getUnsplashContentImage(image.url, { width })
    case 'url':
      return image.url
    case undefined:
      return getCourseDefaultImage({ width: width })
    default:
      assertNever(image)
  }
}

export const normalizeCourseImageUrl = (url: string): [string, boolean] => {
  // Converting URL images where the URL is from sierra-files, to a namespaced image with fileId
  // This matches the conversion done in the backend in FlexibleContentRecordAssets.kt

  const storagePrefix = 'https://storage.googleapis.com/sierra-files/'
  const cloudinaryPrefix = 'https://res.cloudinary.com/sana-ai/image/fetch/'

  if (url.startsWith(cloudinaryPrefix)) {
    const url1 = url.slice(cloudinaryPrefix.length)
    const url2 = url1.substring(url1.indexOf('/') + 1)

    if (url2.startsWith(storagePrefix)) {
      return [url2.slice(storagePrefix.length), false]
    }
  }
  if (url.startsWith(storagePrefix)) {
    return [url.slice(storagePrefix.length), false]
  }

  return [url, true]
}

// Many URL images point to sierra-files, and we can rewrite these to FileImage directly.
export const simplifyUrlImage = (image: ImageUnion | undefined): ImageUnion | undefined => {
  if (image !== undefined && image.type === 'url') {
    const [fileId, isUrl] = normalizeCourseImageUrl(image.url)

    if (isUrl)
      return {
        type: 'url',
        url: fileId,
      }
    else
      return {
        type: 'file',
        file: fileId,
      }
  } else {
    return image
  }
}

// The URL images that have previously been resolved through the backend (copied to GCS) can be simplified
// to File images. The method simplifyUrlImage() needs to be applied first.
export const resolveUrlImage = (image: ImageUnion | undefined): ImageUnion | undefined => {
  if (image !== undefined && image.type === 'url') {
    return {
      type: 'file',
      file: nameUUIDFromString(image.url),
    }
  } else {
    return image
  }
}

export const resolveNamespacedCourseImage = (
  image: ImageUnion | undefined,
  size: ImageSizeType,
  loadNamespacedCourseAssets: boolean,
  assetContext: AssetContext,
  assetType?: AssetType
): string => {
  const { width } = imageSizeTypes[size]

  if (image === undefined) {
    return getCourseDefaultImage({ width: width })
  }

  if (
    image.type === 'file' &&
    loadNamespacedCourseAssets &&
    (assetContext.type === 'course' ||
      assetContext.type === 'courseTemplate' ||
      assetContext.type === 'certificate' ||
      assetContext.type === 'program' ||
      assetContext.type === 'path' ||
      assetContext.type === 'pdf-image' ||
      assetContext.type === 'tag')
  ) {
    return getNamespacedImage(assetContext, image.file, assetType ?? 'image', { optimize: true, width })
  }

  // For courses and courseTemplates, no new URL images are created, and they have been backfilled to file images
  // The URL is converted to a file ID
  if (
    image.type === 'url' &&
    loadNamespacedCourseAssets &&
    (assetContext.type === 'course' || assetContext.type === 'courseTemplate')
  ) {
    // The fileId here can either be a URL or plain fileId
    const [fileId] = normalizeCourseImageUrl(image.url)

    return getNamespacedImage(assetContext, fileIdAsUUID(fileId), assetType ?? 'image', {
      optimize: true,
      width,
    })
  }

  // Load image from URL
  if (image.type === 'url' && loadNamespacedCourseAssets) {
    return image.url
  }

  if (image.type === 'media-library-image') {
    const parameters = ['q_auto:best', 'f_auto', 'c_lfill']
    if (window.devicePixelRatio >= 2) {
      parameters.push('dpr_2.0')
    }
    return `https://res.cloudinary.com/sana-ai/image/upload/w_${width},${parameters.join()}/${MEDIA_LIBRARY_BUCKET}/${
      image.file
    }`
  }

  if (image.type === 'unsplash') {
    return getUnsplashContentImage(image.url, { width })
  }

  if (loadNamespacedCourseAssets || assetContext.type === 'unknown') {
    // Log to sentry if this happens, so that we can track down the last few cases
    errorLogger.captureWarning(
      new Error('resolveNamespacedCourseImage unhandled case, falling back to resolveCourseImage.'),
      {
        tags: {
          assetContextType: assetContext.type,
          imageType: image.type,
        },
      }
    )
  }

  return fallbackResolveCourseImage(image, size)
}
