import { transform } from 'framer-motion'
import _ from 'lodash'
import {
  Dispatch,
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { resolveUrlImage, simplifyUrlImage } from 'sierra-client/api/content'
import { getFlag } from 'sierra-client/config/global-config'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAssetWithoutFallback } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { createDataURlFromThumbhashBase64, thumbhashHasAlhpa } from 'sierra-client/utils/image-thumbhash'
import {
  Wrap as LessonImageWrap,
  imageNarrowLayoutMaxWidth,
  imageWideLayoutMaxWidth,
} from 'sierra-client/views/author/components/block-editor/v2/blocks/image'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { ToolbarV2 } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import { CreditInput } from 'sierra-client/views/v3-author/common/media-uploader/shared'
import { useEditorAssetContext } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useIsUniquelySelected } from 'sierra-client/views/v3-author/hooks'
import { ensureImageUnionHasThumbhash } from 'sierra-client/views/v3-author/images/ensure-thumbhash'
import { AuthorHotspots, LearnerHotspotsStatic } from 'sierra-client/views/v3-author/images/hotspots'
import { hotspotReducer } from 'sierra-client/views/v3-author/images/hotspots/reducer'
import { HotspotAction, HotspotState } from 'sierra-client/views/v3-author/images/hotspots/types'
import { ImageSelectorWithSuggestions } from 'sierra-client/views/v3-author/images/image-selector-with-suggestions'
import { ImageWithFirstSuggestion } from 'sierra-client/views/v3-author/images/image-with-first-suggestion'
import { ImageWrapper } from 'sierra-client/views/v3-author/images/image-wrapper'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { XRealtimeAuthorResolveImageUrl, XRealtimeImportAssetsFromZip } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { Image as AuthorImage, SanaEditor } from 'sierra-domain/v3-author'
import { deriveTheme } from 'sierra-ui/color'
import { BlockToolbar } from 'sierra-ui/components'
import { GroupMenuItem, MenuItem } from 'sierra-ui/components/menu/types'
import { TextAreaPrimitive } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import { useSlateStatic } from 'slate-react'
import styled, { useTheme } from 'styled-components'

const AltTextInput = styled(TextAreaPrimitive).attrs({ rows: 1, resize: 'none', autoExpand: true })<{
  disabled?: boolean
}>`
  width: 100%;
  display: block;
  font-size: 0.875rem;
  margin: 4px auto 0;
  color: ${p => deriveTheme(p.theme).textColor};
  background-color: ${p => deriveTheme(p.theme).backgroundColor};
  outline: none;

  &:hover {
    outline: 1px solid
      ${p => (p.disabled !== true ? `${deriveTheme(p.theme).secondaryTextColor}` : 'transparent')};
  }

  &:focus {
    outline: 1px solid ${p => deriveTheme(p.theme).textColor};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${p => deriveTheme(p.theme).secondaryTextColor};
  }
`

const SizeDebug = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 4px;
  background-color: black;
  color: white;
  z-index: 1;
  border-radius: 6px;
`

const ImportingImagePlaceholder = styled.div.attrs({ contentEditable: false })<{
  $width: number
  $aspectRatio: string
  $background: string
}>`
  display: block;
  margin: 0;
  width: ${p => p.$width};
  aspect-ratio: ${p => p.$aspectRatio};
  border-radius: ${p => p.theme.borderRadius['size-10']};
  background: ${p => p.$background};
`

const ImportingImagePlaceholderWrapper: React.FC<{
  block: AuthorImage
  resolvedImageSize: number | undefined
}> = ({ block, resolvedImageSize }) => {
  const width = resolvedImageSize ?? 100
  const aspectRatio =
    block.image !== undefined && block.image.type === 'file'
      ? `${block.image.width} / ${block.image.height}`
      : '1 / 1'
  const background =
    block.image !== undefined && block.image.type === 'file' && block.image.thumbHashBase64 !== undefined
      ? `center / cover url(${createDataURlFromThumbhashBase64(block.image.thumbHashBase64)})`
      : 'rgba(0, 0, 0, 0.1)'
  return <ImportingImagePlaceholder $width={width} $aspectRatio={aspectRatio} $background={background} />
}

// This is an invisible container that we use to measure the
// width of the container that the image is in
const SizeReference = styled.div`
  height: 0;
  position: absolute;
  inset: 0;

  ${p => (p.theme.editor.isWideLayout ? imageWideLayoutMaxWidth : imageNarrowLayoutMaxWidth)}
`

type ImageResizeState = {
  imageWidth: number
  mouseOffset: number
}

type ImageCustomStyleState = AuthorImage['customStyle']

const MIN_SCALE = 0.5
const MAX_SCALE = 5

const IMAGE_SCALE_RANGE = [MIN_SCALE, 1, MAX_SCALE]
const SLIDER_RANGE = [0, 50, 100]

const EditImageScaleSlider: FC<{
  scale: number
  onScaleChanged: (newScale: number) => void
}> = props => {
  const sliderValue = transform(props.scale, IMAGE_SCALE_RANGE, SLIDER_RANGE)
  const labelValue = `${Math.round(props.scale * 100)}%`

  return (
    <BlockToolbar.Slider
      defaultValue={50}
      leftLabel={labelValue}
      onValueChange={sliderPosition => {
        // do a little snappy action around the center
        if (sliderPosition > 48 && sliderPosition < 52) {
          props.onScaleChanged(transform(50, SLIDER_RANGE, IMAGE_SCALE_RANGE))
        } else {
          props.onScaleChanged(transform(sliderPosition, SLIDER_RANGE, IMAGE_SCALE_RANGE))
        }
      }}
      value={sliderValue}
    />
  )
}

const RemoveImageButton: FC<{
  imgSrc: string | undefined
  onClick: () => void
}> = props => {
  const { t } = useTranslation()

  if (props.imgSrc === undefined) return null
  return (
    <BlockToolbar.Button
      label={t('dictionary.clear')}
      onClick={props.onClick}
      imageUrl={props.imgSrc}
      tooltip={t('author.slate.clear-image')}
    />
  )
}

const NewImageToolbar: FC<{
  element: AuthorImage & { id: string }
  editor: SanaEditor
  openCredit: boolean
  setOpenCredit: (open: boolean) => void
  openAlt: boolean
  setOpenAlt: (open: boolean) => void
  hotspotDispatch: Dispatch<HotspotAction>
  hotspotState: HotspotState
  imageScaleEditMode: boolean
  imageScale: number
  onImageStyleEditStart: () => void
  setImageScale: (scale: number) => void
  onSaveImageStyle: () => void
  onResetImageStyle: () => void
}> = ({
  element,
  editor,
  openCredit,
  setOpenCredit,
  setOpenAlt,
  openAlt,
  hotspotDispatch,
  hotspotState,
  imageScaleEditMode,
  imageScale,
  setImageScale,
  onSaveImageStyle,
  onResetImageStyle,
  onImageStyleEditStart,
}) => {
  const { variant } = element
  const withGrid = useRenderingContext().withGrid
  const { t } = useTranslation()
  const customImageStylesEnabled = getFlag('custom-image-styles')

  const assetContext = useEditorAssetContext()
  const image = resolveUrlImage(simplifyUrlImage(element.image))
  const imgSrc = useResolveAssetWithoutFallback({ assetContext, image, size: 'thumbnail' })

  const wrapperSizeMenuItems = useMemo(
    (): [GroupMenuItem<Exclude<typeof variant, undefined> | 'title'>] => [
      {
        id: 'title',
        type: 'group',
        label: t('author.block-editor.image-toolbar.size'),
        menuItems: [
          {
            id: 'center',
            type: 'label',
            label: t('author.block-editor.image-center'),
            icon: 'resize--mini',
          },
          {
            id: 'narrow',
            type: 'label',
            label: t('author.block-editor.image-narrow'),
            icon: 'resize--small',
          },
          {
            id: 'wide',
            type: 'label',
            label: t('author.block-editor.image-wide'),
            icon: 'resize--medium',
          },
          {
            id: 'full-width',
            type: 'label',
            label: t('author.block-editor.image-fullWidth'),
            icon: 'resize--large',
          },
        ],
      },
    ],
    [t]
  )

  const wrapperSizeInColumnMenuItems = useMemo(
    (): [GroupMenuItem<'padded' | 'full-width' | 'title'>] => [
      {
        id: 'title',
        type: 'group',
        label: t('author.block-editor.image-toolbar.size'),
        menuItems: [
          {
            id: 'padded',
            type: 'label',
            label: t('author.block-editor.image-narrow'),
            icon: 'resize--small',
          },
          {
            id: 'full-width',
            type: 'label',
            label: t('author.block-editor.image-fullWidth'),
            icon: 'resize--large',
          },
        ],
      },
    ],
    [t]
  )

  const imagehasTransparency = useMemo(() => {
    const thumbhash = iife(() => {
      switch (image?.type) {
        case 'file':
        case 'media-library-image':
        case 'unsplash':
          return image.thumbHashBase64
        case 'url':
        case undefined:
          return undefined
        default:
          image satisfies never
      }
    })

    if (thumbhash === undefined) return false
    const hasAlpha = thumbhashHasAlhpa(thumbhash)
    return hasAlpha
  }, [image])

  const wrapperSizeButtonLabel = useMemo(() => {
    if (element.customSize !== undefined) return 'Custom'
    return wrapperSizeMenuItems[0].menuItems.find(item => item.id === variant)?.label ?? ''
  }, [element.customSize, variant, wrapperSizeMenuItems])

  const settingsMenuItems = useMemo(
    (): [GroupMenuItem<'alt-text' | 'credits' | 'hotspots' | 'title'>] => [
      {
        type: 'group',
        id: 'title',
        label: t('author.block-editor.image-toolbar.options'),
        menuItems: [
          {
            id: 'alt-text',
            type: 'label',
            label: t('author.block-editor.image-toolbar.add-alt-text'),
            icon: 'generate--paragraph',
          },
          {
            id: 'credits',
            type: 'label',
            label: t('author.block-editor.image-toolbar.image-credit'),
            icon: 'image-and-text',
          },
          {
            id: 'hotspots',
            type: 'label',
            label: t('author.block-editor.image-toolbar.create-hotspot'),
            icon: 'add-hotspot',
          },
        ],
      },
    ],
    [t]
  )
  const backgroundMenuItems = useMemo(
    (): [GroupMenuItem<'title' | 'dominant-color' | 'transparent' | 'semi-transparent'>] => [
      {
        type: 'group',
        id: 'title',
        label: t('author.block-editor.image-toolbar.select-background-title'),
        menuItems: [
          {
            id: 'transparent',
            type: 'label',
            label: t('author.block-editor.image-toolbar.transparent'),
            icon: 'transparency',
          },
          {
            id: 'semi-transparent',
            type: 'label',
            label: t('author.block-editor.image-toolbar.semi-transparent'),
            icon: 'eye--dropper',
          },
          {
            id: 'dominant-color',
            type: 'label',
            label: t('author.block-editor.image-toolbar.dominant-color'),
            icon: 'circle',
          },
        ],
      },
    ],
    [t]
  )

  const handleSelectOptionsItem = (item: MenuItem<'alt-text' | 'credits' | 'hotspots' | 'title'>): void => {
    switch (item.id) {
      case 'alt-text':
        setOpenAlt(!openAlt)
        break
      case 'credits':
        setOpenCredit(!openCredit)
        break
      case 'hotspots':
        hotspotDispatch({ type: 'set-add-mode', value: !hotspotState.addModeEnabled })
        break
    }
  }

  const handleChangeBackground = (
    item: MenuItem<'dominant-color' | 'transparent' | 'semi-transparent' | 'title'>
  ): void => {
    switch (item.id) {
      case 'transparent':
        updateNodeWithId(editor, element.id, {
          background: 'transparent',
        })
        break
      case 'dominant-color': {
        updateNodeWithId(editor, element.id, { background: 'dominant-color' })

        break
      }
      case 'semi-transparent':
        updateNodeWithId(editor, element.id, { background: 'semi-transparent' })
        break
    }

    // Some older images don't have thumbhashes
    // so we make sure to create it if they set the background to dominant color
    if (element.image !== undefined && imgSrc !== undefined) {
      void ensureImageUnionHasThumbhash(element.image, imgSrc).then(newImage => {
        updateNodeWithId(editor, element.id, { image: newImage })
      })
    }
  }

  if (imageScaleEditMode) {
    return (
      <ToolbarV2 elementId={element.id} ignoreEditorFocus>
        <EditImageScaleSlider scale={imageScale} onScaleChanged={setImageScale} />
        <BlockToolbar.IconButton iconId='reset' onClick={onResetImageStyle} tooltip={t('dictionary.reset')} />
        <BlockToolbar.Separator />
        <BlockToolbar.IconButton
          iconId='checkmark--filled'
          onClick={onSaveImageStyle}
          tooltip={t('dictionary.save')}
        />
      </ToolbarV2>
    )
  }

  return (
    <ToolbarV2 elementId={element.id} ignoreEditorFocus>
      {withGrid ? (
        <BlockToolbar.DropdownButton
          menuItems={wrapperSizeMenuItems}
          label={wrapperSizeButtonLabel}
          onSelect={item => {
            switch (item.id) {
              case 'center':
                updateNodeWithId(editor, element.id, { variant: 'center', customSize: undefined })
                break
              case 'wide':
                updateNodeWithId(editor, element.id, { variant: 'wide', customSize: undefined })
                break
              case 'narrow':
                updateNodeWithId(editor, element.id, { variant: 'narrow', customSize: undefined })
                break
              case 'full-width':
                updateNodeWithId(editor, element.id, {
                  variant: 'full-width',
                  hotspotsMandatory: false,
                  customSize: undefined,
                })
                break
              case 'title':
                // shouldn't happen
                throw new Error('Unexpected menu item')
            }
          }}
        />
      ) : (
        <BlockToolbar.DropdownIconButton
          withChevron
          menuItems={wrapperSizeInColumnMenuItems}
          iconId={iife(() => {
            switch (element.columnVariant) {
              case undefined:
              case 'full-width':
                return 'resize--large'
              case 'padded':
                return 'resize--small'
              default:
                assertNever(element.columnVariant)
            }
          })}
          tooltip={t('author.block-editor.image-toolbar.size')}
          onSelect={item => {
            switch (item.id) {
              case 'padded':
                updateNodeWithId(editor, element.id, { columnVariant: 'padded', customSize: undefined })
                break
              case 'full-width':
                updateNodeWithId(editor, element.id, {
                  columnVariant: 'full-width',
                  hotspotsMandatory: false,
                  customSize: undefined,
                })
                break
              case 'title':
                // shouldn't happen
                throw new Error('Unexpected menu item')
            }
          }}
        />
      )}

      {
        // only show background if the background is visible
        (imagehasTransparency ||
          (element.customStyle?.scale !== undefined && element.customStyle.scale < 1)) && (
          <BlockToolbar.DropdownIconButton
            iconId='transparency'
            menuItems={backgroundMenuItems}
            onSelect={handleChangeBackground}
            tooltip={t('author.block-editor.image-toolbar.select-background-title')}
            withChevron
          />
        )
      }

      {customImageStylesEnabled && (
        <BlockToolbar.IconButton
          iconId='settings--adjust'
          onClick={() => onImageStyleEditStart()}
          tooltip={t('author.block-editor.image-toolbar.options')}
        />
      )}
      <BlockToolbar.DropdownIconButton
        iconId='overflow-menu--horizontal'
        menuItems={settingsMenuItems}
        onSelect={handleSelectOptionsItem}
        tooltip={t('author.block-editor.image-toolbar.options')}
        withChevron={false}
      />
      <BlockToolbar.Separator />
      <RemoveImageButton
        imgSrc={imgSrc}
        onClick={() => {
          updateNodeWithId(editor, element.id, {
            hotspotsMandatory: false,
            customSize: undefined,
            image: undefined,
            customStyle: undefined,
          })
        }}
      />
      <BlockToolbar.IconButton
        iconId='trash-can'
        onClick={() => removeNodeWithId(editor, element.id)}
        tooltip={t('author.article.remove-image')}
      />
    </ToolbarV2>
  )
}

export const Image: SlateFC = ({ children, element, readOnly, ...props }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const customImageStylesEnabled = getFlag('custom-image-styles')

  const { postWithUserErrorException } = usePost()

  const nodeId = element.id

  const assetContext = useEditorAssetContext()
  const withGrid = useRenderingContext().withGrid

  const [initialUrl] = useState(() => {
    const url = editor.initialImageUrls[nodeId]
    if (url !== undefined) delete editor.initialImageUrls[nodeId]
    return url
  })

  // If the image is pasted into the editor as HTML, the image is transformed into a file image, but it
  // still needs to be resolved.
  //
  // If the image is copied as a slate fragment from another course, the image is imported in the background, and should not be rendered until the import is complete.
  //
  // The image must not be accessed before it is resolved since that seems to break the Cloudinary
  // caching and the URL continues to return 404.
  const [isResolved, setIsResolved] = useState(initialUrl === undefined)

  useEffect(() => {
    const resolveImageUrl = async (): Promise<void> => {
      if (initialUrl !== undefined && assetContext.type === 'course') {
        await postWithUserErrorException(XRealtimeAuthorResolveImageUrl, {
          type: 'course',
          courseId: assetContext.courseId,
          url: initialUrl,
        })
        setIsResolved(true)
      }
    }

    void resolveImageUrl()
  }, [postWithUserErrorException, initialUrl, assetContext, setIsResolved])

  const block = element.type === 'image' ? element : undefined
  if (block === undefined) throw new Error(`Expected to render an image. ${JSON.stringify(element)}`)

  const imageFileId = block.image?.type === 'file' ? block.image.file : undefined

  const [imageIsImporting, setImageIsImporting] = useState(() => {
    return imageFileId !== undefined && editor.importingAssetsFileUrls[imageFileId] !== undefined
  })

  useEffect(() => {
    if (!imageIsImporting) return

    const importAsset = async (): Promise<void> => {
      if (imageFileId === undefined) return
      if (assetContext.type !== 'course') return
      const signedUrl = editor.importingAssetsFileUrls[imageFileId]
      if (signedUrl === undefined) return
      await typedPost(XRealtimeImportAssetsFromZip, {
        courseId: assetContext.courseId,
        signedUrl,
        filterImageId: [imageFileId],
      })
      setImageIsImporting(false)
    }
    void importAsset()
  }, [assetContext, imageFileId, editor, setImageIsImporting, imageIsImporting])

  const shouldRenderImage = isResolved && !imageIsImporting

  const [credit, setCredit] = useState<string | undefined>(block.credit)
  const noCredit = credit?.length === 0 || credit === undefined
  const [openCredit, setOpenCredit] = useState<boolean>(!noCredit)

  const [altText, setAltText] = useState<string | undefined>(block.altText)
  const noAlt = altText === undefined || altText.trim() === ''
  const [openAlt, setOpenAlt] = useState<boolean>(!noAlt)

  const [resizeState, setResizeState] = useState<ImageResizeState | undefined>(undefined)
  const [imageCustomStyleState, setImageCustomStyleState] = useState<ImageCustomStyleState | undefined>()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)

  const [openModal, setOpenModal] = useState<boolean>(false)

  const containerWidth = useCallback(() => containerRef.current?.getBoundingClientRect().width ?? 0, [])
  const containerX = useCallback(() => containerRef.current?.getBoundingClientRect().x ?? 0, [])

  const imageWidth = useCallback(() => imageRef.current?.getBoundingClientRect().width ?? 0, [])
  const imageRight = useCallback(() => imageRef.current?.getBoundingClientRect().right ?? 0, [])
  const imageHeight = useCallback(() => imageRef.current?.getBoundingClientRect().height ?? 0, [])

  const imageContainerRatio = imageWidth() / imageHeight()

  const isEditingStyle = imageCustomStyleState !== undefined

  const clampPixels = useCallback(
    (value: number): number => _.clamp(value, 100, containerWidth()),
    [containerWidth]
  )

  const setTemporarySize = useCallback(
    (value: ImageResizeState | undefined) => {
      if (value === undefined) return setResizeState(value)

      setResizeState({ imageWidth: clampPixels(value.imageWidth), mouseOffset: value.mouseOffset })
    },
    [clampPixels]
  )

  useOnChanged(() => {
    if (!openCredit) {
      updateNodeWithId(editor, element.id, { credit: undefined })
    } else {
      updateNodeWithId(editor, element.id, { credit })
    }
  }, openCredit)

  useOnChanged(() => {
    if (!openAlt) {
      updateNodeWithId(editor, element.id, { altText: undefined })
    } else {
      updateNodeWithId(editor, element.id, { altText })
    }
  }, openAlt)

  const resolvedImageSize = useMemo((): ImageResizeState['imageWidth'] | undefined => {
    if (resizeState !== undefined) return resizeState.imageWidth
    if (block.customSize === undefined) return
    if (block.customSize > 1) return block.customSize
    // Custom sizes used to be percentage values between 0 and 1.
    // To maintain backwards compatibility we convert these into pixels here.
    return block.customSize * containerWidth()
  }, [block.customSize, containerWidth, resizeState])

  const resolvedImageStyle = useMemo((): ImageCustomStyleState | undefined => {
    if (isEditingStyle) return imageCustomStyleState
    return block.customStyle
  }, [block.customStyle, imageCustomStyleState, isEditingStyle])

  useEffect(() => {
    const handleMouseUp = (): void => {
      if (resizeState !== undefined) {
        if (Math.abs(resizeState.imageWidth - containerWidth()) < 10) {
          updateNodeWithId(editor, block.id, {
            customSize: undefined,
            variant: 'full-width',
            columnVariant: 'full-width',
          })
        } else {
          updateNodeWithId(editor, block.id, { customSize: clampPixels(resizeState.imageWidth) })
        }
      }

      setTemporarySize(undefined)
    }
    window.addEventListener('mouseup', handleMouseUp)
    return () => window.removeEventListener('mouseup', handleMouseUp)
  }, [block.id, clampPixels, containerWidth, editor, imageWidth, resizeState, setTemporarySize])

  const hasCustomWidth = block.customSize !== undefined

  const isWideLayout = useTheme().editor.isWideLayout

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent): void => {
      if (resizeState !== undefined) {
        const mouseX = e.clientX - containerX() + resizeState.mouseOffset

        if (isWideLayout) {
          setTemporarySize({ ...resizeState, imageWidth: mouseX })
        } else {
          setTemporarySize({ ...resizeState, imageWidth: Math.abs(containerWidth() / 2 - mouseX) * 2 })
        }

        if (block.customSize === undefined)
          updateNodeWithId(editor, block.id, { customSize: resizeState.imageWidth })
      }
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [
    block.customSize,
    block.id,
    block.variant,
    editor,
    hasCustomWidth,
    imageContainerRatio,
    containerWidth,
    containerX,
    resizeState,
    isWideLayout,
    setTemporarySize,
  ])

  const [hotspotState, hotspotDispatch] = useReducer(hotspotReducer, {
    hotspots: block.hotspots ?? {},
    addModeEnabled: false,
  })

  const isDebug = useIsDebugMode()

  // Update block when hotspots change
  useEffect(() => {
    if (JSON.stringify(block.hotspots) !== JSON.stringify(hotspotState.hotspots)) {
      updateNodeWithId(editor, block.id, { hotspots: hotspotState.hotspots })
    }
  }, [editor, block, hotspotState.hotspots])

  ///////////
  // Handle style editing save and abort
  const isOnlySelectedElement = useIsUniquelySelected({ nodeId: block.id })
  const saveImageStyles = useCallback(() => {
    if (imageCustomStyleState === undefined) return
    updateNodeWithId(editor, block.id, { customStyle: imageCustomStyleState })
    setImageCustomStyleState(undefined)
  }, [block.id, editor, imageCustomStyleState])

  const resetImageStyle = useCallback(() => {
    updateNodeWithId(editor, block.id, { customStyle: undefined })
    setImageCustomStyleState({ scale: 1, translateX: 0, translateY: 0 })
  }, [block.id, editor])

  useOnChanged(() => {
    if (!isOnlySelectedElement && isEditingStyle) {
      saveImageStyles()
    }
  }, isOnlySelectedElement)

  useEffect(() => {
    if (isEditingStyle) {
      const abortStyleEditing = (e: KeyboardEvent): void => {
        if (e.key === 'Escape') {
          setImageCustomStyleState(undefined)
        }
      }

      window.addEventListener('keydown', abortStyleEditing)
      return () => window.removeEventListener('keydown', abortStyleEditing)
    }
  }, [isEditingStyle])

  ///////////

  const hasNoImage =
    !block.image ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !block.image.type ||
    (block.image.type === 'file' && !block.image.file) ||
    (block.image.type === 'unsplash' && !block.image.url)

  // We should likely have a better indicator than this that is more explicit
  const useFirstSuggestion = block.altText !== undefined && block.altText.trim() !== ''

  const [initialFile] = useState(() => {
    const image = editor.initialImageUploads[element.id]
    if (image) delete editor.initialImageUploads[element.id]
    return image
  })

  const onImagePositionChangeStart = useCallback(
    (startEvent: ReactMouseEvent<unknown>) => {
      const containerRect = imageRef.current?.getBoundingClientRect()
      if (containerRect === undefined || imageCustomStyleState === undefined) return
      const conatinerWidth = containerRect.width
      const containerHeight = containerRect.height

      const startOffsetX = imageCustomStyleState.translateX
      const startOffsetY = imageCustomStyleState.translateY

      const startPositionX = conatinerWidth * (startOffsetX / 100)
      const startPositionY = containerHeight * (startOffsetY / 100)

      const handleMouseMove = (moveEvent: MouseEvent): void => {
        const movementX = startPositionX + (moveEvent.clientX - startEvent.clientX)
        const movementY = startPositionY + (moveEvent.clientY - startEvent.clientY)

        const movementXAsPercent = (movementX / conatinerWidth) * 100
        const movementYAsPercent = (movementY / containerHeight) * 100

        setImageCustomStyleState(curr =>
          curr !== undefined
            ? {
                scale: curr.scale,
                translateX: movementXAsPercent,
                translateY: movementYAsPercent,
              }
            : curr
        )
      }

      const handleMouseUp = (): void => {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('mouseup', handleMouseUp)
      }

      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    },
    [imageCustomStyleState]
  )

  return (
    <>
      <LessonImageWrap
        isResizing={resizeState !== undefined}
        customSize={resolvedImageSize}
        variant={block.variant}
        $withGrid={withGrid}
        $columnVariant={block.columnVariant}
        {...props}
      >
        {children}

        {isDebug && (
          <SizeDebug contentEditable={false}>
            {resizeState !== undefined ? (
              <p>temporarySize: {resizeState.imageWidth.toFixed(1)}px</p>
            ) : (
              <p>size: {imageWidth().toFixed(1)}px</p>
            )}
            {block.customSize !== undefined && <p>customSize: {block.customSize.toFixed(1)}px</p>}
            <p>imageWidth: {imageWidth().toFixed(0)}px</p>
            <p>containerWidth: {containerWidth().toFixed(0)}px</p>
            <p>ctx: {JSON.stringify(assetContext)}</p>
          </SizeDebug>
        )}

        {hasNoImage && (
          <>
            <div contentEditable={false}>
              {useFirstSuggestion ? (
                <ImageWithFirstSuggestion nodeId={element.id} />
              ) : (
                !readOnly && (
                  <ImageSelectorWithSuggestions
                    nodeId={element.id}
                    openModal={() => setOpenModal(true)}
                    initialFile={initialFile}
                    assetContext={assetContext}
                  />
                )
              )}
            </div>

            <UploadImageModal
              open={openModal}
              onUploadDone={image => updateNodeWithId(editor, element.id, { image })}
              onClose={() => setOpenModal(false)}
              assetContext={assetContext}
            />

            <BlockCommentIndicator element={element} radius='4' />
          </>
        )}

        {!hasNoImage && (
          <>
            {shouldRenderImage ? (
              <>
                <ImageWrapper
                  customSize={resolvedImageSize}
                  assetContext={assetContext}
                  ref={imageRef}
                  block={block}
                  readOnly={readOnly}
                  altText={altText}
                  altTextInput={
                    openAlt &&
                    !readOnly && (
                      <AltTextInput
                        value={altText ?? ''}
                        disabled={readOnly}
                        placeholder={t('author.block-editor.image-add-altText')}
                        onChange={e => setAltText(e.currentTarget.value)}
                        onBlur={() => updateNodeWithId(editor, element.id, { altText })}
                      />
                    )
                  }
                  creditInput={
                    ((readOnly && !noCredit) || openCredit) && (
                      <CreditInput
                        value={credit ?? ''}
                        disabled={readOnly}
                        placeholder={t('author.block-editor.image-credit')}
                        onChange={e => setCredit(e.currentTarget.value)}
                        onBlur={() => updateNodeWithId(editor, element.id, { credit })}
                      />
                    )
                  }
                  hotspotLayer={
                    !readOnly && !isEditingStyle ? (
                      <AuthorHotspots hotspotState={hotspotState} hotspotDispatch={hotspotDispatch} />
                    ) : (
                      <LearnerHotspotsStatic
                        onMouseDown={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        imageBlock={block}
                        hotspots={block.hotspots ?? {}}
                      />
                    )
                  }
                  onResizeStart={event => {
                    const mouseOffset = imageRight() - event.clientX
                    setTemporarySize({ imageWidth: imageWidth(), mouseOffset })
                  }}
                  isEditingStyle={isEditingStyle}
                  customStyle={resolvedImageStyle}
                  onImageStyleEditStart={() => {
                    if (!customImageStylesEnabled) return
                    setImageCustomStyleState(block.customStyle ?? { scale: 1, translateX: 0, translateY: 0 })
                  }}
                  onImageStyleDragStart={event => {
                    onImagePositionChangeStart(event)
                  }}
                />

                <BlockCommentIndicator element={element} radius='4' />

                <NewImageToolbar
                  editor={editor}
                  element={block}
                  openCredit={openCredit}
                  setOpenCredit={setOpenCredit}
                  openAlt={openAlt}
                  setOpenAlt={setOpenAlt}
                  hotspotDispatch={hotspotDispatch}
                  hotspotState={hotspotState}
                  imageScale={resolvedImageStyle?.scale ?? 1}
                  imageScaleEditMode={isEditingStyle}
                  setImageScale={newScale => {
                    setImageCustomStyleState(curr =>
                      curr !== undefined
                        ? {
                            translateX: curr.translateX,
                            translateY: curr.translateY,
                            scale: newScale,
                          }
                        : curr
                    )
                  }}
                  onSaveImageStyle={saveImageStyles}
                  onResetImageStyle={resetImageStyle}
                  onImageStyleEditStart={() => {
                    if (!customImageStylesEnabled) return
                    setImageCustomStyleState(block.customStyle ?? { scale: 1, translateX: 0, translateY: 0 })
                  }}
                />
              </>
            ) : (
              <ImportingImagePlaceholderWrapper block={block} resolvedImageSize={resolvedImageSize} />
            )}
          </>
        )}
      </LessonImageWrap>
      <SizeReference contentEditable={false} ref={containerRef}>
        <span />
      </SizeReference>
    </>
  )
}
