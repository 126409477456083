import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useCreateEditableContentMutation } from 'sierra-client/api/hooks/use-editable-content'
import { getFlag } from 'sierra-client/config/global-config'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { getGlobalRouter } from 'sierra-client/router'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

function Page(): JSX.Element {
  const { teamspaceId, folderId } = Route.useSearch()
  const sanaNow = getFlag('sana-now')

  const { mutate } = useCreateEditableContentMutation()

  useEffect(() => {
    mutate(
      {
        flexibleContentTemplate: {
          type: 'sana',
          templateName: sanaNow ? 'new-live-with-title-card' : 'new-live',
        },
        teamspaceId,
        folderId,
      },
      {
        onSuccess: result => {
          void getGlobalRouter().navigate({ to: `/create/l/${result.courseId}`, replace: true })
        },
      }
    )
  }, [teamspaceId, mutate, folderId, sanaNow])

  return <></>
}

export const Route = createFileRoute('/create/l/new')({
  component: requireLoggedIn(Page) as React.FC,
  validateSearch: z.object({
    teamspaceId: NanoId12.optional().catch(undefined),
    folderId: NanoId12.optional().catch(undefined),
  }),
})
