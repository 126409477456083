import { useEffect, useState } from 'react'
import { getVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service'
import { VideoCallServiceError } from 'sierra-client/components/liveV2/services/video-call-service/helpers/errors'
import { VideoCallService } from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'
import { config, getFlag } from 'sierra-client/config/global-config'
import { useOrgTenantId } from 'sierra-client/hooks/use-org-tenant-id'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useDispatch } from 'sierra-client/state/hooks'
import { callStateChanged } from 'sierra-client/state/live/actions'

export const useSetupVideoCallService = ({
  handleVideoCallError,
}: {
  handleVideoCallError: (e: VideoCallServiceError) => void
}): { isReady: boolean; videoCallService?: VideoCallService } => {
  const liveConfig = config.organization.live
  const tenantId = useOrgTenantId()

  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const [videoCallService, setVideoCallService] = useState<VideoCallService>()

  const handleError = useStableFunction(handleVideoCallError)

  const useExperimentalStatistics = getFlag('sana-internal')
  const useSlowJoinProxy = getFlag('sana-internal')
  const forwardLogs = getFlag('polaris/agora-forward-logs')
  // Setup the call by subscribing to the error events and start the camera and microphone
  useEffect(() => {
    if (tenantId === undefined) return

    let videoCallService: VideoCallService | undefined

    const onLiveStateChange = (): void => {
      void dispatch(callStateChanged())
    }

    void (async () => {
      const forceCloudProxy = liveConfig?.cloudProxy ?? false
      const geofenceAreas = liveConfig?.geofenceAreas ?? undefined

      videoCallService = await getVideoCallService()

      videoCallService.setEnableAgoraLogUpload(forwardLogs)

      videoCallService.on('state-changed', onLiveStateChange)
      videoCallService.on('error', handleError)

      // We wait until after the error handler is setup to do this, so we can capture any errors that might occur
      await videoCallService.setup({
        forceCloudProxy,
        geofenceAreas,
        useExperimentalStatistics,
        tenantId,
        useSlowJoinProxy,
      })

      setIsReady(true)
      setVideoCallService(videoCallService)
    })()

    return () => {
      videoCallService?.setEnableAgoraLogUpload(false)
      videoCallService?.off()
      void videoCallService?.teardown()
    }
  }, [liveConfig, dispatch, forwardLogs, handleError, useExperimentalStatistics, tenantId, useSlowJoinProxy])

  return { isReady, videoCallService }
}
