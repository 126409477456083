import { PrimitiveAtom } from 'jotai'
import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { AINarrationControlsState } from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { AINarrationsNewInfra } from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations-new-infra'
import { AINarrationsOldInfra } from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations-old-infra'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { CreateOperationState } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const AINarrations: React.FC<{
  fileId: FileId
  scopedCreateContentId: ScopedSelfPacedContentId
  operationState: CreateOperationState
  aiNarrationControlStateAtom: PrimitiveAtom<AINarrationControlsState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  assetContext: AssetContext
}> = ({
  fileId,
  scopedCreateContentId,
  operationState,
  aiNarrationControlStateAtom,
  legacyNarrationStateAtom,
  narrationSettings,
  narrationMetadata,
  assetContext,
}) => {
  const isNewNarrationInfraEnabled = getFlag('new-narration-infra')
  const file = useSelector(state =>
    selectFlexibleContentFile(state, ScopedCreateContentId.extractId(scopedCreateContentId), fileId)
  )

  if (file === undefined) {
    return <Debug>File is undefined</Debug>
  }

  if (isNewNarrationInfraEnabled) {
    return (
      <AINarrationsNewInfra
        file={file}
        scopedCreateContentId={scopedCreateContentId}
        operationState={operationState}
        aiNarrationControlStateAtom={aiNarrationControlStateAtom}
        legacyNarrationStateAtom={legacyNarrationStateAtom}
        narrationSettings={narrationSettings}
        narrationMetadata={narrationMetadata}
        assetContext={assetContext}
      />
    )
  } else {
    return (
      <AINarrationsOldInfra
        fileId={fileId}
        scopedCreateContentId={scopedCreateContentId}
        operationState={operationState}
        aiNarrationControlStateAtom={aiNarrationControlStateAtom}
        legacyNarrationStateAtom={legacyNarrationStateAtom}
        narrationSettings={narrationSettings}
        narrationMetadata={narrationMetadata}
        assetContext={assetContext}
      />
    )
  }
}
