import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useCreateEditableContentMutation } from 'sierra-client/api/hooks/use-editable-content'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { getGlobalRouter } from 'sierra-client/router'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

function Page(): JSX.Element {
  const { teamspaceId, folderId, modules, title } = Route.useSearch()
  const { mutate } = useCreateEditableContentMutation()

  useEffect(() => {
    mutate(
      {
        flexibleContentTemplate: {
          type: 'sana',
          templateName: 'blank-self-paced',
        },
        title: title,
        teamspaceId,
        folderId,
        nGeneratedModules: modules !== undefined ? modules : undefined,
      },
      {
        onSuccess: result => {
          void getGlobalRouter().navigate({ to: `/create/s/${result.courseId}`, replace: true })
        },
      }
    )
  }, [title, modules, mutate, teamspaceId, folderId])

  return <></>
}

export const Route = createFileRoute('/create/s/new')({
  component: requireLoggedIn(Page) as React.FC,
  validateSearch: z.object({
    teamspaceId: NanoId12.optional().catch(undefined),
    folderId: NanoId12.optional().catch(undefined),
    modules: z.number().optional().catch(undefined),
    title: z.string().optional(),
  }),
})
