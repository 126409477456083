import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  useReadLatestValidSelection,
  useSetSlashMenuState,
  useSlashMenuState,
} from 'sierra-client/editor/editor-jotai-context'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { useIsVideoAvatarsEnabled } from 'sierra-client/views/flexible-content/ai-narrations/use-is-video-avatars-enabled'
import { useSlashMenuCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useEditorUploadFile } from 'sierra-client/views/v3-author/file-attachment/use-editor-upload-file'
import { getCurrentlySelectedNode } from 'sierra-client/views/v3-author/queries'
import * as ShortcutCommands from 'sierra-client/views/v3-author/slash-menu/event-handlers'
import { slashMenuEntries } from 'sierra-client/views/v3-author/slash-menu/slash-menu-entries'
import { resolveLabel } from 'sierra-client/views/v3-author/slash-menu/types'
import { clearSearchText } from 'sierra-client/views/v3-author/slash-menu/utils'
import {
  ActiveSlashMenuState,
  SLASH_MENU_COLUMN_SIZE,
  SlashMenuEntryId,
  SlashMenuState,
} from 'sierra-domain/v3-author/slash-menu'
import { aiGradient, AiText, Icon } from 'sierra-ui/components'
import { MUIPopper } from 'sierra-ui/mui'
import { View } from 'sierra-ui/primitives'
import { palette, zIndex } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { Editor, Element, Node, NodeEntry } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const ShortcutPopper = styled(MUIPopper)`
  padding: 0.5rem;

  margin: 0;
  list-style: none;

  border-radius: 8px;
  border: 1px solid ${p => p.theme.color.grey10};
  background-color: ${palette.primitives.white};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

  z-index: ${zIndex.MODAL};
`

const ShortcutList = styled.ul`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
  grid-template-rows: repeat(${SLASH_MENU_COLUMN_SIZE}, auto);
  grid-auto-flow: column;
  list-style: none;
`

const ItemInner = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  white-space: nowrap;

  ${fonts.body.small};
`

const ItemIcon = styled(Icon).attrs({ size: 'size-16' })`` //size was 'small'

const ItemLabel = styled.span`
  padding-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 100%;
`

const ShortcutItemWrapper = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 232px /* Needs to have a fixed width in order for the ellipsis to work */;
  border-radius: 3px;

  margin: 0;
  padding: 0.5rem 1rem;
  ${fonts.body.small};
  font-weight: 700;

  background-color: ${p => (p.selected === true ? 'hsl(0deg, 0%, 95%)' : 'initial')};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &,
  ${ItemLabel}, ${ItemIcon} {
    color: ${p => p.theme.color.grey35};
    transition: 0.05s color ease;
  }

  ${p => p.selected === true && `${ItemLabel}, ${ItemIcon} { color: ${palette.primitives.black}; }`};

  &:hover,
  &:hover ${ItemLabel}, &:hover ${ItemIcon}, &:focus-within {
    color: ${palette.primitives.black};
  }
`

const AINewLabelWrapper = styled(View).attrs({ base: '#5B59EA', variant: 'ghost' })`
  border-radius: 100px;
  padding: 2.5px 8px;
  margin-left: 8px;
  ${aiGradient};
`

const NewLabel: React.FC = () => {
  const { t } = useTranslation()
  return (
    <AINewLabelWrapper>
      <AiText base='#5B59EA' size='technical' bold>
        {t('admin.author.new')}
      </AiText>
    </AINewLabelWrapper>
  )
}

const ShortcutItem: FCC<{
  selected: boolean
  index: number
  onHover: (index: number) => void
  onClick: () => void
  id: SlashMenuEntryId
}> = ({ onHover, onClick, index, selected, id, children }) => {
  const ref = useRef<HTMLLIElement | null>(null)
  const isVideoAvatarsEnabled = useIsVideoAvatarsEnabled()

  // Scroll the element into view when it is selected
  useEffect(() => {
    if (selected) ref.current?.scrollIntoView({ block: 'nearest' })
  }, [selected])

  return (
    <li ref={ref}>
      <ShortcutItemWrapper onMouseEnter={() => onHover(index)} onClick={onClick} selected={selected}>
        {children}
        {id === 'video' && isVideoAvatarsEnabled && <NewLabel />}
      </ShortcutItemWrapper>
    </li>
  )
}

const NoMatchingSlashMenuEntries = styled(ShortcutItemWrapper)`
  color: ${p => p.theme.color.grey35};
`

// This needs to support the reference object interface from popper, see https://popper.js.org/docs/v1/#referenceObject
const getCaretCoordinates = (
  editor: Editor,
  lastSelectedNode: NodeEntry<Node> | undefined
): { clientWidth: number; clientHeight: number; getBoundingClientRect: () => DOMRect } | undefined => {
  const isSupported = typeof window.getSelection !== 'undefined'
  const selection = window.getSelection()

  if (isSupported && selection !== null) {
    if (selection.rangeCount !== 0) {
      // needs to have at least one active selection
      const range = selection.getRangeAt(0).cloneRange() // get that range
      range.collapse(true)
      const rect = range.getClientRects()[0] // get the rectangle for the range

      if (rect) {
        return {
          clientWidth: rect.width,
          clientHeight: rect.height,
          getBoundingClientRect: () => rect,
        }
      } else if (lastSelectedNode !== undefined) {
        // Fallback: In some cases the previous code fails on Firefox. We'll use the first found
        // text node in the last selected node instead, which seems to be accurate enough.
        // Even if not perfect, it should be an improvement over rendering the menu in the top left corner,
        // which was the previous fallback behavior.

        const wrapperEl = ReactEditor.toDOMNode(editor, lastSelectedNode[0])
        const textEl = wrapperEl.querySelector('span[data-slate-node="text"]')
        if (textEl !== null) {
          const textRect = textEl.getBoundingClientRect()

          return {
            clientWidth: textRect.width,
            clientHeight: textRect.height,
            getBoundingClientRect: () => textRect,
          }
        }
      }
    }
  }
}

const ShortcutMenu: React.FC<
  ActiveSlashMenuState & {
    closeShortcuts: () => void
    updateShortcutSelection: (index: number) => void
  }
> = ({ startPoint, selectedIndex, idsMatchingSearchText, updateShortcutSelection, closeShortcuts }) => {
  const readLatestSelection = useReadLatestValidSelection()
  const { t, dynamicT } = useTranslation()
  const editor = useSlateStatic()
  const createPageContext = useSlashMenuCreatePageContext()
  const matchingEntries = idsMatchingSearchText.map(id => ({ id, ...slashMenuEntries[id] }))
  const selectedEntry = matchingEntries[selectedIndex % matchingEntries.length]
  const dispatch = useDispatch()
  // We need to store the selected node in state since the selection will disappear when the user clicks outside.
  // We'll do the same for the caret position.
  const [{ lastSelectedNode, caretPosition }] = useState(() => {
    try {
      const lastSelectedNode = getCurrentlySelectedNode(editor)
      const caretPosition = getCaretCoordinates(editor, lastSelectedNode)
      return { lastSelectedNode, caretPosition }
    } catch (e) {
      console.debug('[slash-menu] unable to resolve position.', e)

      return { lastSelectedNode: undefined, caretPosition: undefined }
    }
  })
  const notification = useNotif()
  const showUploadFileModal = useEditorUploadFile().showUploadModal

  return (
    <ShortcutPopper open anchorEl={caretPosition} placement={'bottom-start'}>
      {matchingEntries.length > 0 ? (
        <ShortcutList>
          {matchingEntries.map(({ id, icon, label, edit }, index) => (
            <ShortcutItem
              id={id}
              index={index}
              key={id}
              selected={id === selectedEntry?.id}
              onHover={updateShortcutSelection}
              onClick={() => {
                const latestSelection = readLatestSelection()

                if (latestSelection === null) {
                  return
                }

                editor.pushActionsLogEntry({ type: 'select-slash-menu-entry', id })
                setTimeout(() => {
                  const currentId =
                    lastSelectedNode !== undefined
                      ? Element.isElement(lastSelectedNode[0])
                        ? lastSelectedNode[0].id
                        : undefined
                      : undefined

                  clearSearchText(editor, latestSelection, startPoint)
                  edit({
                    createPageContext,
                    editor,
                    dispatch,
                    currentId,
                    lastSelection: latestSelection,
                    notification,
                    showUploadFileModal,
                    dynamicT,
                  })
                  closeShortcuts()
                })
              }}
            >
              <ItemInner>
                {/* icons size was 'small' */}
                {icon && <ItemIcon iconId={icon} size={'size-16'} />}{' '}
                <ItemLabel>{resolveLabel(label, dynamicT)}</ItemLabel>
              </ItemInner>
            </ShortcutItem>
          ))}
        </ShortcutList>
      ) : (
        <NoMatchingSlashMenuEntries>{t('author.slate.no-results')}</NoMatchingSlashMenuEntries>
      )}
    </ShortcutPopper>
  )
}

export const Shortcut: React.FC<{
  supportedSlashMenuEntryIds: SlashMenuState['idsSupportedByEditor']
}> = ({ supportedSlashMenuEntryIds: initiallySupportedSlashMenuEntryIds }) => {
  const state = useSlashMenuState()
  const updateShortcutState = useSetSlashMenuState()

  useEffect(() => {
    if (!_.isEqual(state.idsSupportedByEditor, initiallySupportedSlashMenuEntryIds)) {
      updateShortcutState(state => ({ ...state, idsSupportedByEditor: initiallySupportedSlashMenuEntryIds }))
    }
  }, [initiallySupportedSlashMenuEntryIds, state, updateShortcutState])

  const updateShortcutSelection = useCallback(
    (index: number) => updateShortcutState(state => ShortcutCommands.onHover(state, index)),
    [updateShortcutState]
  )

  const { idsSupportedByEditor } = state
  const closeShortcuts = useCallback(
    (): void => updateShortcutState(() => ShortcutCommands.onClick({ idsSupportedByEditor })),
    [updateShortcutState, idsSupportedByEditor]
  )

  if (state.type === 'active')
    return (
      <ShortcutMenu
        {...state}
        updateShortcutSelection={updateShortcutSelection}
        closeShortcuts={closeShortcuts}
      />
    )
  else return null
}
