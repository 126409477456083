import { useBlocker } from '@tanstack/react-router'
import { Atom, atom, useSetAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { AblyYjsAwarenessWrapper } from 'sierra-client/collaboration/awareness/sync-ably-presence'
import { createFakeAwareness } from 'sierra-client/collaboration/fake-awareness'
import { AblyYjsProvider } from 'sierra-client/collaboration/yjs-provider'
import { useNonExpiringNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { useDevelopmentSnackbar } from 'sierra-client/hooks/use-debug-notif'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useRealTimeDataClient } from 'sierra-client/realtime-data/real-time-data-provider'
import { useChannelPresenceIfConnected } from 'sierra-client/realtime-data/use-channel-presence'
import { ContentPermission } from 'sierra-domain/api/common'
import { ScopedCreateContentId, ScopedDocumentId } from 'sierra-domain/collaboration/types'
import { assertNever } from 'sierra-domain/utils'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

function debug(...messages: unknown[]): void {
  console.debug('[useAblyYjsProvider]', ...messages)
}

type FlexibleContentYjsProviderState = {
  yDoc: Y.Doc | undefined
  permission: ContentPermission
  latestYUpdateIdAtom: Atom<number | undefined>
}

function useAblyYjsProvider({ channelId }: { channelId: ScopedDocumentId }): FlexibleContentYjsProviderState {
  const realTimeClient = useRealTimeDataClient()
  const [yDoc, setYDoc] = useState<Y.Doc | undefined>(undefined)
  const [permission, setPermission] = useState<ContentPermission>('none')
  const [errorToThrow, setErrorToThrow] = useState<unknown | undefined>(undefined)
  const isRemoteUpdateVerifierEnabled = getFlag('remote-update-verifier')
  const notif = useNonExpiringNotif()
  const { reportInDev } = useDevelopmentSnackbar()
  const latestYUpdateIdAtom = useMemo(() => atom<number | undefined>(undefined), [])
  const setLatestYUpdateId = useSetAtom(latestYUpdateIdAtom)

  const [isSyncing, setIsSyncing] = useState(false)

  const { t } = useTranslation()

  // Prevent the user from navigating while we are syncing
  useBlocker({
    blockerFn: () => window.confirm(t('author.changes-not-saved-warning')),
    condition: isSyncing,
  })

  useEffect(() => {
    const provider = new AblyYjsProvider(
      channelId,
      realTimeClient,
      isRemoteUpdateVerifierEnabled,
      getFlag('yjs-fetch-latest-update')
    )
    provider.on('permissionChanged', permission => setPermission(permission))
    provider.on('yDocStatusChanged', (status, error) => {
      if (status === 'loaded') {
        setYDoc(provider.yDoc)
      } else if (status === 'destroyed') {
        setYDoc(undefined)
      }

      if (error !== undefined) {
        setErrorToThrow(error)
      }
    })

    provider.on('yDocMissingUpdates', updateIds => {
      const message = `Missing updates: ${updateIds.join(', ')}`
      debug(message)
      reportInDev(message, { variant: 'error' })
    })
    provider.on('yDocRecoveredUpdates', updateIds => {
      const message = `Recovered updates: ${updateIds.join(', ')}`
      debug(message)
      reportInDev(message, { variant: 'success' })
    })

    provider.on('latestYUpdateIdChanged', updateId => {
      setLatestYUpdateId(updateId)
    })

    const isCreateContentYDoc = ScopedCreateContentId.tryExtractId(channelId) !== null

    let reconnectingNotificationId: string | undefined = undefined
    let reconnectingTimeout: ReturnType<typeof setTimeout> | undefined = undefined

    let notificationId: string | undefined = undefined
    let syncingTimeout: ReturnType<typeof setTimeout> | undefined = undefined

    // Show a warning in the editor if the yDocSyncer is in the syncing state for too long
    provider.on('yDocSyncerStatusChanged', status => {
      if (!isCreateContentYDoc) {
        // Ignore yDocs that are not create content yDocs, we only want to show this warning in the editor for now.
        return
      }

      // Reconnecting notification
      switch (status.type) {
        case 'idle': {
          setIsSyncing(false)
          clearTimeout(reconnectingTimeout)
          if (reconnectingNotificationId !== undefined) {
            notif.remove(reconnectingNotificationId)
          }

          break
        }
        case 'syncing': {
          setIsSyncing(true)
          clearTimeout(reconnectingTimeout)
          if (reconnectingNotificationId !== undefined) {
            notif.remove(reconnectingNotificationId)
          }
          reconnectingTimeout = setTimeout(() => {
            reconnectingNotificationId = notif.push({
              type: 'custom',
              level: 'info',
              body: t('author.reconnecting'),
            })
          }, 2000)

          break
        }

        default: {
          assertNever(status.type)
        }
      }

      // Syncing error notification
      switch (status.type) {
        case 'idle': {
          clearTimeout(syncingTimeout)

          if (notificationId !== undefined) {
            notif.remove(notificationId)
          }

          break
        }
        case 'syncing': {
          clearTimeout(syncingTimeout)
          syncingTimeout = setTimeout(() => {
            notificationId = notif.push({
              type: 'error',
              body: t('author.slate.connection-warning'),
            })
          }, 7_500)
          break
        }
        default: {
          assertNever(status.type)
        }
      }
    })

    provider.init()

    return () => {
      clearTimeout(syncingTimeout)

      if (notificationId !== undefined) {
        notif.remove(notificationId)
      }

      provider.destroy()
    }
  }, [channelId, realTimeClient, notif, t, reportInDev, isRemoteUpdateVerifierEnabled, setLatestYUpdateId])

  useEffect(() => {
    if (errorToThrow !== undefined) {
      throw errorToThrow
    }
  }, [errorToThrow])

  return useMemo(() => ({ yDoc, permission, latestYUpdateIdAtom }), [yDoc, permission, latestYUpdateIdAtom])
}

function useAblyYjsAwareness({
  yDoc,
  channelId,
}: {
  yDoc: Y.Doc | undefined
  channelId: ScopedDocumentId
}): Awareness | undefined {
  const [awareness, setAwareness] = useState<Awareness | undefined>(undefined)
  const channelPresence = useChannelPresenceIfConnected(channelId)

  useEffect(() => {
    if (yDoc === undefined) return
    const awareness = new Awareness(yDoc)
    setAwareness(awareness)
    return () => awareness.destroy()
  }, [yDoc])

  useEffect(() => {
    if (channelPresence === undefined || awareness === undefined) return

    const ablyAwareness = new AblyYjsAwarenessWrapper(
      awareness,
      channelPresence.presence,
      channelPresence.connectionId
    )
    return () => ablyAwareness.destroy()
  }, [awareness, channelPresence])

  return awareness
}

type AblyYDocState = {
  yDoc: Y.Doc
  permission: ContentPermission
  latestYUpdateIdAtom: Atom<number | undefined>
}

export function useAblyYDoc(channelId: ScopedDocumentId): AblyYDocState | undefined {
  const { yDoc, permission, latestYUpdateIdAtom } = useAblyYjsProvider({ channelId })

  return useMemo(
    () => (yDoc ? { yDoc, permission, latestYUpdateIdAtom } : undefined),
    [yDoc, permission, latestYUpdateIdAtom]
  )
}

export type AblyYDocWithAwarenessState = {
  yDoc: Y.Doc
  permission: ContentPermission
  awareness: Awareness
  latestYUpdateIdAtom: Atom<number | undefined>
}

export function useAblyYDocWithAwareness(
  channelId: ScopedDocumentId
): AblyYDocWithAwarenessState | undefined {
  const state = useAblyYDoc(channelId)
  const awareness = useAblyYjsAwareness({
    yDoc: state?.yDoc,
    channelId,
  })

  return useMemo(
    () =>
      state && awareness
        ? {
            yDoc: state.yDoc,
            permission: state.permission,
            awareness,
            latestYUpdateIdAtom: state.latestYUpdateIdAtom,
          }
        : undefined,
    [awareness, state]
  )
}

export function useAblyYDocWithFakeAwareness(
  channelId: ScopedDocumentId
): AblyYDocWithAwarenessState | undefined {
  const state = useAblyYDoc(channelId)
  const awareness = useMemo(
    () => (state?.yDoc !== undefined ? createFakeAwareness(state.yDoc) : undefined),
    [state?.yDoc]
  )

  return useMemo(
    () =>
      state && awareness
        ? {
            yDoc: state.yDoc,
            permission: state.permission,
            awareness,
            latestYUpdateIdAtom: state.latestYUpdateIdAtom,
          }
        : undefined,
    [awareness, state]
  )
}
