import React, { useState } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { useAddSubtitleTracks } from 'sierra-client/components/blocks/video/use-add-subtitle-tracks'
import { VideoIsTranscribingIndicator } from 'sierra-client/components/blocks/video/video-transcription-loading-indicator'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUploadVideoWithProgress } from 'sierra-client/hooks/use-video-upload-with-progress'
import {
  GenerateNarrationButton,
  GeneratingNarrationView,
  getVideoState,
  NarrationModal,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations-video-card'
import { useIsVideoAvatarsEnabled } from 'sierra-client/views/flexible-content/ai-narrations/use-is-video-avatars-enabled'
import {
  NarrationState,
  NarrationStateSync,
} from 'sierra-client/views/flexible-content/card-narration/narration-state-sync'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { Upload } from 'sierra-client/views/v3-author/common/media-uploader/shared'
import { acceptedVideoTypes, maxFileSizeGb } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { AssetContext } from 'sierra-domain/asset-context'
import { File, VideoData } from 'sierra-domain/flexible-content/types'
import { iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const VideoPlaceholder = styled(View).attrs({
  justifyContent: 'center',
  cursor: 'pointer',
})`
  width: 100%;
  height: 100%;
`

type Video = {
  url?: string
  disableSkipping?: boolean
  disableSubtitles?: boolean
}

export type VideoCardProps = {
  readOnly: boolean
  video: VideoData['video']
  onUploadDone?: (video: Video) => void
  setVideoDuration?: (durationInSeconds: number) => void
  assetContext: AssetContext
  file: File
}

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  && > .video-js {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
  border: none;
`

const CreateVideoCard: React.FC<{
  video: VideoData['video']
  onUploadDone?: (video: Video) => void
  setVideoDuration?: (durationInSeconds: number) => void
  assetContext: AssetContext
  file: File
}> = ({ video, onUploadDone, setVideoDuration, assetContext, file }) => {
  const { t } = useTranslation()
  const { uploadVideo } = useUploadVideoWithProgress()
  const addSubtitles = useAddSubtitleTracks(video.url, video.disableSubtitles !== true)
  const isAiVideoAvatarsEnabled = useIsVideoAvatarsEnabled()
  const [narrationModalOpen, setNarrationModalOpen] = useState(false)
  const [narrationState, setNarrationState] = useState<NarrationState | undefined>(undefined)

  const { permission } = useCreatePageYDocContext()
  const { scopedCreateContentId, createContentId, operationState } = useCreatePageContext()
  const { nodeId: selectedNodeId } = useCreatePageNodeIdContext()
  const narrationMetadata =
    selectedNodeId !== undefined
      ? narrationState?.narrations[selectedNodeId]?.find(it => it.destinationTypeApi.type === 'video-card')
      : undefined

  const aiNarrationsOpen = selectedNodeId !== undefined && narrationModalOpen && narrationState !== undefined

  const handleUploadDone = (payload: { url: string } | undefined): void => {
    if (payload === undefined) return

    onUploadDone?.({
      url: payload.url,
    })
  }
  const videoState = getVideoState(video.url, narrationMetadata)

  return (
    <>
      {iife(() => {
        switch (videoState.type) {
          case 'generating':
            return (
              <Container>
                <GeneratingNarrationView
                  generatingMetadata={videoState.generatingMetadata}
                  contentId={createContentId}
                  canEdit={permission === 'edit'}
                />
              </Container>
            )
          case 'no-video':
            return (
              <VideoPlaceholder>
                <Upload
                  iconId={'play--outline'}
                  accept={acceptedVideoTypes}
                  additionalInfo={` ${t('author.slate.up-to', {
                    number: maxFileSizeGb,
                    filetype: 'GB',
                  })}`}
                  uploadMedia={uploadVideo}
                  onUploaded={handleUploadDone}
                  assetContext={assetContext}
                  buttonVariant={isAiVideoAvatarsEnabled ? 'secondary' : 'primary'}
                  narrationButton={
                    isAiVideoAvatarsEnabled ? (
                      <GenerateNarrationButton
                        setNarrationModalOpen={setNarrationModalOpen}
                        destinationType='video-card'
                      />
                    ) : undefined
                  }
                  uploadTitle={
                    isAiVideoAvatarsEnabled ? 'author.slate.video-upload-options-with-generate' : undefined
                  }
                  uploadSupportBody={'author.slate.uploading-supports'}
                />

                {aiNarrationsOpen && (
                  <NarrationModal
                    destinationTypeApi={{ type: 'video-card' }}
                    narrationModalOpen={narrationModalOpen}
                    closeNarrationModal={() => {
                      setNarrationModalOpen(false)
                    }}
                    file={file}
                    operationState={operationState}
                    scopedCreateContentId={scopedCreateContentId}
                    narrationSettings={narrationState.settings}
                    narrationMetadata={narrationMetadata}
                  />
                )}
              </VideoPlaceholder>
            )
          case 'has-video':
            return (
              <CardWrapper>
                <VideoPlayer
                  options={{
                    autoplay: false,
                    controls: true,
                    responsive: true,
                    fluid: true,
                    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
                    controlBar: {
                      liveDisplay: false,
                      pictureInPictureToggle: false,
                    },
                    sources: [
                      {
                        src: videoState.videoUrl,
                        type: 'video/mp4',
                      },
                    ],
                  }}
                  onLoadedMetadata={player => {
                    const duration = player.player().duration()
                    if (!Number.isNaN(duration)) setVideoDuration?.(duration)
                    addSubtitles(player)
                  }}
                  disableSkipping={false}
                />
                <VideoIsTranscribingIndicator videoUrl={videoState.videoUrl} />
              </CardWrapper>
            )
        }
      })}
      <NarrationStateSync
        contentId={createContentId}
        canEdit={permission === 'edit'}
        setNarrationState={setNarrationState}
        destinationTypeApi={{ type: 'video-card' }}
      />
    </>
  )
}

const ReadOnlyVideoCard: React.FC<{ video: VideoData['video']; file: File }> = ({ video, file }) => {
  const isAiVideoAvatarsEnabled = useIsVideoAvatarsEnabled()

  const possibleNarrationUrl = file.narration?.url ?? video.url
  const videoUrl = isAiVideoAvatarsEnabled ? possibleNarrationUrl : video.url

  return videoUrl === undefined ? (
    <VideoPlaceholder>
      <Icon iconId='play--outline' color='grey35' />
      <Text size='regular' color='grey35'>
        No video set
      </Text>
    </VideoPlaceholder>
  ) : (
    <CardWrapper>
      <VideoPlayer
        options={{
          autoplay: false,
          controls: true,
          responsive: true,
          fluid: true,
          playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          controlBar: {
            liveDisplay: false,
            pictureInPictureToggle: false,
          },
          sources: [
            {
              src: videoUrl,
              type: 'video/mp4',
            },
          ],
        }}
        disableSkipping={video.disableSkipping}
      />
    </CardWrapper>
  )
}

export const VideoCard: React.FC<VideoCardProps> = ({
  video,
  onUploadDone,
  setVideoDuration,
  readOnly,
  assetContext,
  file,
}) => {
  if (readOnly) {
    return <ReadOnlyVideoCard video={video} file={file} />
  }

  return (
    <CreateVideoCard
      file={file}
      video={video}
      onUploadDone={onUploadDone}
      setVideoDuration={setVideoDuration}
      assetContext={assetContext}
    />
  )
}
