import { useAtom } from 'jotai'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import {
  colorThemePreferenceAtom,
  type ColorThemePreference,
} from 'sierra-client/components/common/color-theme-preference'
import { UserAndCourseAssignment } from 'sierra-client/components/shortcut-menu/assignment'
import { AutoAssignSkills } from 'sierra-client/components/shortcut-menu/auto-assign-skills'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import {
  ExportCourse,
  ExportCourseAsZip,
  ImportCourseAsZip,
  importCourseFromClipboard,
} from 'sierra-client/components/shortcut-menu/course-export'
import { ExportCourseText } from 'sierra-client/components/shortcut-menu/course-text'
import { getDevTools } from 'sierra-client/components/shortcut-menu/devtools'
import { GenerateCourseOutline } from 'sierra-client/components/shortcut-menu/generate-course-outline'
import { GenerateCourseOutlineDeprecation } from 'sierra-client/components/shortcut-menu/generate-course-outline-deprecation'
import { IntercomProductTourShortcut } from 'sierra-client/components/shortcut-menu/intercom-product-tour'
import { DebugPermissionsShortcut } from 'sierra-client/components/shortcut-menu/permissions-inspector/debug-permissions-shortcut'
import {
  OWNER_ONLY,
  ShortcutMenu,
  ShortcutMenuSettingsItem,
} from 'sierra-client/components/shortcut-menu/shortcut-menu'
import { SwitchOrganization } from 'sierra-client/components/shortcut-menu/switch-organization'
import { RunAction } from 'sierra-client/components/shortcut-menu/types'
import { config, getFlag } from 'sierra-client/config/global-config'
import { requestChaosTest } from 'sierra-client/debug/chaos-mode'
import { ReactDebugModeShortcutToggle } from 'sierra-client/features/react-debug-mode'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { useLoadNamespacedCourseAssets } from 'sierra-client/hooks/use-resolve-asset'
import { useShowCorrectAnswersContext } from 'sierra-client/hooks/use-show-correct-answers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AccessibilityTestingShortcut } from 'sierra-client/lib/accessibility-testing/shortcut'
import { getGlobalRouter } from 'sierra-client/router'
import { selectCurrentLiveSessionId } from 'sierra-client/state/content/selectors'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import {
  SEARCH_MODELS,
  SearchModel,
  askInsightsModelAtom,
  chaosTestingEnabledAtom,
  debugModeAtom,
  loadNamespacedCourseAssetsAtom,
  newPageCardAlignmentAtom,
  newPageCardColumnColorAtom,
  newPageScrollPageAtom,
  newRecapInfraAtom,
  reflectionsSummaryAtom,
  searchInsightsCmdKEnabledAtom,
  searchModelAtom,
  searchRerankingAnnotationModeEnabledAtom,
  showTranslationStringsAtom,
  videoAvatarsAtom,
} from 'sierra-client/state/settings'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { ShowMockDataAtom } from 'sierra-client/views/global-assistant/atoms'
import { newHomeOverride } from 'sierra-client/views/learner/home/atoms'
import { XRealtimeUserResetState, XRealtimeUserSetRegistrationStep } from 'sierra-domain/routes'

function toggleHighlightComponentsOnRender(): void {
  const devTools = getDevTools()
  if (!devTools) return
  devTools.setTraceUpdatesEnabled(!devTools._traceUpdatesEnabled)
}

const resetProgressAction: RunAction = async ({ t, postWithUserErrorException, store }) => {
  const userId = selectUserId(store.getState())
  if (userId === undefined) return
  if (confirm(`${t('sana-settings.operation-irreversible')} ${t('sana-settings.reset-progress')}`)) {
    await postWithUserErrorException(XRealtimeUserResetState, { userId })
    window.location.reload()
  }
}

const resetOnboardingAction: RunAction = async ({ t, postWithUserErrorException, store }) => {
  const userId = selectUserId(store.getState())
  if (userId === undefined) return
  if (confirm(t('sana-settings.reset-onboarding'))) {
    await postWithUserErrorException(XRealtimeUserResetState, { userId })
    await postWithUserErrorException(XRealtimeUserSetRegistrationStep, {
      step: 'account-details',
    })
    window.location.reload()
  }
}

const goToSentryAction: RunAction = ({ store }) => {
  const baseUrl = `https://sentry.io/organizations/sanalabs/issues/`
  const project: string = config.sentryBrowserDsn.split('/')[3] ?? ''

  const tenantId = selectOrganization(store.getState())?.tenantId
  const liveSessionId = selectCurrentLiveSessionId(store.getState())
  const queryParams: Record<string, string | undefined> = {
    is: 'unresolved',
    tenantId,
    liveSessionId,
    url: window.location.href,
  }

  const query = Object.entries(_.omitBy(queryParams, _.isNil))
    .map(([key, value]) => window.encodeURIComponent(`${key}:${value}`))
    .join('+')

  const statsPeriod: `${number}h` | `${number}d` = '24h'

  const pathParams: Record<string, string> = { project, query, statsPeriod }

  const url = `${baseUrl}?${Object.entries(pathParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`
  window.open(url, '_blank')
}

const CreateNewShortcuts: React.FC = () => {
  const { t } = useTranslation()
  const [items] = useState<ShortcutMenuSettingsItem[]>(() => [
    {
      id: 'shortcut-menu.default.create-new-course',
      label: t('shortcut-menu.default.create-new-course'),
      icon: 'bookmark--filled',
    },
    {
      id: 'shortcut-menu.default.create-new-live',
      label: t('shortcut-menu.default.create-new-live'),
      icon: 'play--circle--filled',
    },
  ])

  const dispatch = useShortcutMenuDispatch()
  const onItemSelected = useCallback(
    ({ id }: { id: string }): void => {
      if (id === 'shortcut-menu.default.create-new-course')
        void getGlobalRouter().navigate({ to: '/create/s/new' })
      if (id === 'shortcut-menu.default.create-new-live')
        void getGlobalRouter().navigate({ to: '/create/l/new' })
      void dispatch({ type: 'close' })
    },
    [dispatch]
  )

  return (
    <ShortcutMenu.Settings
      group='commands'
      label='shortcut-menu.default.create-new'
      iconId='add'
      permission={'ACCESS_EDITOR'}
      items={items}
      onItemSelected={onItemSelected}
    />
  )
}

const Links: React.FC = () => {
  return (
    <>
      <ShortcutMenu.Group label='navigation'>
        <ShortcutMenu.Link label='topbar.home' href='/' iconId='arrow--right' permission='ACCESS_LMS' />
        <ShortcutMenu.Link
          label='topbar.author'
          href='/create'
          iconId='arrow--right'
          permission={'ACCESS_EDITOR'}
        />
        <ShortcutMenu.Link label='topbar.learn' href='/learn' iconId='arrow--right' permission='ACCESS_LMS' />
        <ShortcutMenu.Link
          label='topbar.manage'
          href='/manage/overview'
          iconId='arrow--right'
          permission={'ACCESS_MANAGE'}
        />
      </ShortcutMenu.Group>
      <CreateNewShortcuts />
    </>
  )
}

const Actions: React.FC = () => {
  const { toggle: toggleShowCorrectAnswers, shouldShowCorrectAnswer } = useShowCorrectAnswersContext()
  const [newHomeScreen, setNewHomeScreen] = useAtom(newHomeOverride)
  const [newRecapInfra, setNewRecapInfra] = useAtom(newRecapInfraAtom)

  const areDeveloperToolsEnabled = useDeveloperToolsEnabled()
  if (!areDeveloperToolsEnabled) return null

  return (
    <>
      <ShortcutMenu.Group label='developer-tools'>
        <ShortcutMenu.Toggle
          label={{ type: 'untranslated', value: 'New home screen' }}
          toggle={() => setNewHomeScreen(previous => !(previous ?? false))}
          isOn={() => newHomeScreen === true}
          iconId='home'
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Toggle
          label={{ type: 'untranslated', value: 'Show correct answers' }}
          toggle={toggleShowCorrectAnswers}
          isOn={() => shouldShowCorrectAnswer}
          iconId='checkmark'
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Action
          label='sana-settings.reset-progress'
          run={resetProgressAction}
          iconId='reset'
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Action
          label='sana-settings.reset-onboarding'
          run={resetOnboardingAction}
          iconId='reset'
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Action
          label='shortcut-menu.default.go-to-sentry'
          run={goToSentryAction}
          iconId='logout'
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Action
          label='shortcut-menu.default.highlight-components-updates'
          run={toggleHighlightComponentsOnRender}
          iconId='blur--1'
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Toggle
          label={{ type: 'untranslated', value: 'Toggle request chaos testing' }}
          iconId='cognitive'
          toggle={() => {
            if (requestChaosTest.isEnabled()) requestChaosTest.disable()
            else requestChaosTest.enable()
          }}
          isOn={() => requestChaosTest.isEnabled()}
          permission='ACCESS_LMS'
        />
        <ShortcutMenu.Toggle
          label={{ type: 'untranslated', value: 'New recap infra' }}
          toggle={() => setNewRecapInfra(previous => !previous)}
          isOn={() => newRecapInfra}
          iconId='checkmark'
          permission='ACCESS_LMS'
        />
        <AccessibilityTestingShortcut />
      </ShortcutMenu.Group>
    </>
  )
}

const Toggles: React.FC = () => {
  const developerToolsEnabled = useDeveloperToolsEnabled()

  const [searchInsightsEnabled, setSearchInsightsEnabled] = useAtom(searchInsightsCmdKEnabledAtom)
  const [askInsightsModel, setAskInsightsModel] = useAtom(askInsightsModelAtom)

  const [isDebugMode, setIsDebugMode] = useAtom(debugModeAtom)
  const [showTranslationStrings, setShowTranslationStrings] = useAtom(showTranslationStringsAtom)
  const [newPageCardColumnColor, setNewPageCardColumnColor] = useAtom(newPageCardColumnColorAtom)
  const [newPageCardAlignment, setNewPageCardAlignment] = useAtom(newPageCardAlignmentAtom)
  const [newPageScrollPage, setNewPageScrollPage] = useAtom(newPageScrollPageAtom)
  const newPageCardAllOn = newPageCardColumnColor && newPageCardAlignment && newPageScrollPage
  const [reflectionsSummary, setReflectionsSummary] = useAtom(reflectionsSummaryAtom)
  const [videoAvatars, setVideoAvatars] = useAtom(videoAvatarsAtom)
  const [chaosTestingEnabled, setChaosTestingEnabled] = useAtom(chaosTestingEnabledAtom)
  const [searchRerankingAnnotationModeEnabled, setSearchRerankingAnnotationModeEnabled] = useAtom(
    searchRerankingAnnotationModeEnabledAtom
  )
  const [searchModel, setSearchModel] = useAtom(searchModelAtom)
  const [showMockData, setShowMockData] = useAtom(ShowMockDataAtom)

  const [colorThemePreference, setColorThemePreference] = useAtom(colorThemePreferenceAtom)

  const [loadNamespacedCourseAssets, setLoadNamespacedCourseAssets] = useAtom(loadNamespacedCourseAssetsAtom)
  const sanaInternal = getFlag('sana-internal')

  if (!developerToolsEnabled) return null

  return (
    <ShortcutMenu.Group label='developer-tools'>
      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'Debug Mode' }}
        toggle={() => setIsDebugMode(prev => !prev)}
        isOn={() => isDebugMode}
        iconId='inspection'
        permission='ACCESS_LMS'
      />
      {sanaInternal && (
        <ShortcutMenu.Toggle
          label={{ type: 'untranslated', value: 'Enable Reflection Summary' }}
          toggle={() => setReflectionsSummary(prev => !prev)}
          isOn={() => reflectionsSummary}
          iconId='phone'
          permission='ACCESS_LMS'
        />
      )}
      {sanaInternal && (
        <ShortcutMenu.Toggle
          label={{ type: 'untranslated', value: 'Enable avatars in video card and block' }}
          toggle={() => setVideoAvatars(prev => !prev)}
          isOn={() => videoAvatars}
          iconId='rating--5'
          permission='ACCESS_LMS'
        />
      )}
      <DebugPermissionsShortcut />

      <ReactDebugModeShortcutToggle />

      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'New Page Card: All changes' }}
        iconId='translate'
        toggle={() => {
          setNewPageCardColumnColor(!newPageCardAllOn)
          setNewPageCardAlignment(!newPageCardAllOn)
          setNewPageScrollPage(!newPageCardAllOn)
        }}
        isOn={() => newPageCardAllOn}
        permission='ACCESS_LMS'
      />
      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'New Page Card: Column colors' }}
        iconId='translate'
        toggle={() => setNewPageCardColumnColor(prev => !prev)}
        isOn={() => newPageCardColumnColor}
        permission='ACCESS_LMS'
      />
      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'New Page Card: Left alignment' }}
        iconId='translate'
        toggle={() => setNewPageCardAlignment(prev => !prev)}
        isOn={() => newPageCardAlignment}
        permission='ACCESS_LMS'
      />

      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'New Page Card: Scroll progress' }}
        iconId='translate'
        toggle={() => setNewPageScrollPage(prev => !prev)}
        isOn={() => newPageScrollPage}
        permission='ACCESS_LMS'
      />

      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'Show translation strings' }}
        iconId='translate'
        toggle={() => setShowTranslationStrings(prev => !prev)}
        isOn={() => showTranslationStrings}
        permission='ACCESS_LMS'
      />

      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'Toggle chaos testing' }}
        iconId='cognitive'
        toggle={() => setChaosTestingEnabled(prev => !prev)}
        isOn={() => chaosTestingEnabled}
        permission='ACCESS_LMS'
      />

      <ShortcutMenu.Toggle
        isOn={() => searchRerankingAnnotationModeEnabled}
        label={{ type: 'untranslated', value: 'Toggle search reranking annotation mode' }}
        iconId='cognitive'
        toggle={() => setSearchRerankingAnnotationModeEnabled(prev => !prev)}
        permission={OWNER_ONLY}
      />

      <ShortcutMenu.Settings
        label={{ type: 'untranslated', value: 'Search model' }}
        iconId='cognitive'
        permission='ACCESS_LMS'
        items={SEARCH_MODELS.map(smId => ({
          id: smId,
          label: smId,
          iconId: searchModel === smId ? 'checkmark' : undefined,
        }))}
        onItemSelected={item => setSearchModel(item.id as SearchModel)}
        currentItemIds={[searchModel]}
        group='developer-tools'
      />

      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'Search mock data' }}
        iconId='cognitive'
        permission='ACCESS_LMS'
        toggle={() => setShowMockData(prev => !prev)}
        isOn={() => showMockData}
        group='developer-tools'
      />
      <ShortcutMenu.Toggle
        group='developer-tools'
        permission={OWNER_ONLY}
        label={{ type: 'untranslated', value: 'Toggle search insights' }}
        iconId='cognitive'
        toggle={() => setSearchInsightsEnabled(prev => !prev)}
        isOn={() => searchInsightsEnabled}
      />
      <ShortcutMenu.Settings
        group='developer-tools'
        permission='ACCESS_LMS'
        label={{ type: 'untranslated', value: 'Ask insights model' }}
        iconId='cognitive'
        items={[
          {
            id: 'sql-v2-few-shot',
            label: 'sql-v2-few-shot',
            iconId: 'checkmark',
          },
        ]}
        onItemSelected={item => setAskInsightsModel(item.id as 'sql-v2-few-shot')}
        currentItemIds={[askInsightsModel]}
      />

      <ShortcutMenu.Settings
        group='developer-tools'
        permission='ACCESS_LMS'
        label={{ type: 'untranslated', value: 'Color Theme' }}
        iconId='skill--paintbrush--pointed'
        items={[
          {
            id: 'light',
            label: 'Light mode',
            iconId: 'idea',
          },
          {
            id: 'dark',
            label: 'Dark mode',
            iconId: 'skill--moon--stars',
          },
          {
            id: 'system-preference',
            label: 'System preference',
            iconId: 'skill--gear',
          },
        ]}
        onItemSelected={item => setColorThemePreference(item.id as ColorThemePreference)}
        currentItemIds={[colorThemePreference]}
      />

      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'Load namespaced course assets' }}
        iconId='cognitive'
        permission='ACCESS_LMS'
        toggle={() => setLoadNamespacedCourseAssets(prev => !prev)}
        isOn={() => loadNamespacedCourseAssets}
        group='developer-tools'
      />
    </ShortcutMenu.Group>
  )
}

const Components: React.FC = () => {
  const developerToolsEnabled = useDeveloperToolsEnabled()
  const useNamespaced = useLoadNamespacedCourseAssets()

  // We are removing the "generate course outline" feature.
  // Until the end of 2024, we will show a deprecation message.
  const showCourseGenDeprecation = new Date().getFullYear() < 2025
  const removeLegacyCourseGenFlag = getFlag('remove-legacy-course-gen')

  return (
    <>
      <ShortcutMenu.NestedComponent
        label='shortcut-menu.default.switch-organization'
        Component={SwitchOrganization}
        group='developer-tools'
        iconId='building'
        permission={OWNER_ONLY}
      />

      {!removeLegacyCourseGenFlag ? (
        <ShortcutMenu.NestedComponent
          label='shortcut-menu.default.generate-course-outline'
          Component={GenerateCourseOutline}
          group='commands'
          iconId='smart'
          permission='ACCESS_EDITOR'
        />
      ) : (
        showCourseGenDeprecation && (
          <ShortcutMenu.NestedComponent
            label='shortcut-menu.default.generate-course-outline'
            Component={GenerateCourseOutlineDeprecation}
            group='commands'
            iconId='smart'
            permission='ACCESS_EDITOR'
          />
        )
      )}

      <ShortcutMenu.NestedComponent
        label={{ type: 'untranslated', value: 'Assign content' }}
        Component={UserAndCourseAssignment}
        group='commands'
        iconId='add--alt'
        permission='ACCESS_MANAGE'
      />

      {developerToolsEnabled && (
        <>
          <ShortcutMenu.NestedComponent
            label={{ type: 'untranslated', value: 'Intercom product tour' }}
            Component={IntercomProductTourShortcut}
            group='developer-tools'
            iconId='earth--filled'
            permission='ACCESS_LMS'
          />

          {!useNamespaced && (
            <ShortcutMenu.NestedComponent
              label={{ type: 'untranslated', value: 'Export Course' }}
              Component={ExportCourse}
              group='developer-tools'
              iconId='download'
              permission='ACCESS_EDITOR'
            />
          )}

          <ShortcutMenu.NestedComponent
            label={{ type: 'untranslated', value: 'Export Course as ZIP' }}
            Component={ExportCourseAsZip}
            group='developer-tools'
            iconId='download'
            permission='ACCESS_EDITOR'
          />

          <ShortcutMenu.NestedComponent
            label={{ type: 'untranslated', value: 'Export Course Text' }}
            Component={ExportCourseText}
            group='developer-tools'
            iconId='text--bold'
            permission='ACCESS_EDITOR'
          />
          {!useNamespaced && (
            <ShortcutMenu.Action
              label={{ type: 'untranslated', value: 'Import course from clipboard' }}
              group='developer-tools'
              run={importCourseFromClipboard}
              iconId='upload'
              permission='ACCESS_EDITOR'
            />
          )}

          <ShortcutMenu.NestedComponent
            label={{ type: 'untranslated', value: 'Import Course from ZIP' }}
            Component={ImportCourseAsZip}
            group='developer-tools'
            iconId='upload'
            permission='ACCESS_EDITOR'
          />

          <ShortcutMenu.NestedComponent
            label={{ type: 'untranslated', value: 'Auto-assign skills to course' }}
            Component={AutoAssignSkills}
            group='developer-tools'
            iconId='smart'
            permission={OWNER_ONLY}
          />
        </>
      )}
    </>
  )
}

export const AppWideShortcuts: React.FC = () => {
  return (
    <>
      <Links />
      <Toggles />
      <Actions />
      <Components />
    </>
  )
}
