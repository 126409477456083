import React, { useState } from 'react'
import { getFileContentImage, getMediaLibraryImageUrl, getNamespacedImage } from 'sierra-client/api/content'
import { HeaderButtonGroupWrapper, HeaderGroupButton } from 'sierra-client/components/liveV2/header-buttons'
import { useLoadNamespacedCourseAssets } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  FileBackgroundPicker,
  getFileImage,
} from 'sierra-client/views/flexible-content/card-background/file-background-picker'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import {
  useDefaultImageFitOption,
  useImageFitOptions,
} from 'sierra-client/views/v3-author/bullet-card/image-fit-options'
import { ImageSelector } from 'sierra-client/views/v3-author/images/image-selector'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import type { AssetContext } from 'sierra-domain/asset-context'
import { apply } from 'sierra-domain/editor/operations'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { File, ImageData } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'
import { deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { scrollViewStyles } from 'sierra-ui/primitives/views/scroll-view'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const ImagePlaceholder = styled(View).attrs({
  justifyContent: 'center',
  cursor: 'pointer',
})`
  width: 100%;
  height: 100%;
  background-color: ${p => deriveTheme(p.theme).secondaryBackgroundColor};
`

const ImageWrapper = styled.div<{ imageFit: ImageFit }>`
  display: flex;
  height: 100%;
  width: 100%;
  transition: background-color 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  ${scrollViewStyles};

  img {
    margin: auto;
    min-width: 100px;
    width: 100%;
    ${p => p.imageFit === 'contain' && 'height: 100%;'}
    object-fit: ${p => p.imageFit};
    border-radius: 2px;
  }
`

type Image = ImageData['image']

const ImageDisplay: React.FC<{
  imageFile: ImageData['image']
  imageFit: ImageFit
  assetContext: AssetContext
}> = ({ imageFile, imageFit, assetContext }) => {
  const loadNamespaced = useLoadNamespacedCourseAssets()

  if (imageFile === undefined) return null

  const resolveImageUrl = (image: ImageData['image']): string => {
    if (image === undefined) return ''
    const isFile = image.type === 'file'
    const isUrl = image.type === 'url'
    const isUnsplash = image.type === 'unsplash'
    const isMediaLibraryImage = image.type === 'media-library-image'

    if (isFile && loadNamespaced) return getNamespacedImage(assetContext, image.file, 'image')
    if (isFile) return getFileContentImage(image.file)
    else if (isUrl) return image.url
    else if (isUnsplash) return image.url
    else if (isMediaLibraryImage) return getMediaLibraryImageUrl(image)

    assertNever(image)
  }

  // TODO: Integrate this with the smart image loader
  // const placeholder = getPlaceholderImageUrl(getFileContentImage(imageFile, { optimize: false }))
  // const fullUrl = getFileContentImage(imageFile)
  // const url = useSmartImageLoader(placeholder, fullUrl)

  return (
    <ImageWrapper imageFit={imageFit}>
      <img src={resolveImageUrl(imageFile)} loading='lazy' />
    </ImageWrapper>
  )
}

export const LearnerImageCard: React.FC<{
  data: ImageData
  assetContext: AssetContext
}> = ({ data, assetContext }) => {
  const image = data.image
  const defaultImageFitOption = useDefaultImageFitOption()
  if (image === undefined) return null

  return (
    <ImageDisplay
      imageFile={image}
      imageFit={data.imageFit ?? defaultImageFitOption.id}
      assetContext={assetContext}
    />
  )
}

export type CreateImageCardProps = {
  data: ImageData
  mode: EditorMode
  readOnly: boolean
  assetContext: AssetContext
  onUploadDone: (image: Image) => void
}

export const CreateImageCard: React.FC<CreateImageCardProps> = ({
  data,
  readOnly,
  onUploadDone,
  assetContext,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  if (data.image === undefined) {
    return (
      <ImagePlaceholder>
        {readOnly ? (
          <>
            <Icon iconId='image' color='grey35' />
            <Text size='regular' color='grey35'>
              No image set
            </Text>
          </>
        ) : (
          <>
            <ImageSelector
              onOpen={() => setOpenModal(true)}
              onUploadDone={onUploadDone}
              fullscreen
              assetContext={assetContext}
            />
            <UploadImageModal
              open={openModal}
              onUploadDone={onUploadDone}
              onClose={() => setOpenModal(false)}
              assetContext={assetContext}
            />
          </>
        )}
      </ImagePlaceholder>
    )
  } else {
    return <LearnerImageCard data={data} assetContext={assetContext} />
  }
}

export const ImageCardToolbar: React.FC<{
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
  file: File
  assetContext: AssetContext
}> = ({ previewThemes, file, assetContext }) => {
  const { dynamicT } = useTranslation()
  const { operationState } = useCreatePageContext()
  const data = file.data
  const imageFitOptions = useImageFitOptions()
  const defaultImageFitOption = useDefaultImageFitOption()
  const imageFitOption =
    (data.type === 'image' ? imageFitOptions.find(option => option.id === data.imageFit) : undefined) ??
    defaultImageFitOption

  const image = getFileImage(file)
  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />

      {image !== undefined && (
        <SingleSelectDropdown
          selectedItem={defaultImageFitOption}
          menuItems={imageFitOptions}
          onSelect={option => {
            const imageFit = ImageFit.parse(option.id)
            apply(operationState, {
              type: 'update-files',
              fileIds: [file.id],
              update: file => {
                if (file.data.type !== 'image') return null
                file.data.imageFit = imageFit
              },
            })
          }}
          renderTrigger={open => (
            <HeaderGroupButton>
              <View gap='2' alignItems='center'>
                <Text color='currentColor' bold>
                  {dynamicT(imageFitOption.label ?? '')}
                </Text>
                <Icon color='grey40' iconId={open ? 'chevron--up--small' : 'chevron--down--small'} />
              </View>
            </HeaderGroupButton>
          )}
        />
      )}

      <FileBackgroundPicker file={file} assetContext={assetContext} />
    </HeaderButtonGroupWrapper>
  )
}
